import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";

export const HeatMapFlex = function(props){
    const [series, setSeries] = useState(
        props.data
    );
    const [option, setOption] = useState(
        {
            plotOptions: {
                heatmap: {
                    colorScale: {
                        ranges: [{
                            from: 0,
                            to: 222,
                            color: '#859bb1',
                            name: 'low',
                        },
                        {
                            from: 223,
                            to: 747,
                            color: '#4f76a0',
                            name: 'medium',
                        },
                        {
                            from: 748,
                            to: 1488,
                            color: '#19457e',                            
                            name: 'high',
                        },
                        {
                            from: 1489,
                            to: 20000,                    
                            color: '#000000',
                            name: 'superhigh',
                        }
                        ]
                    }
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '10px', // Adjust the font size for x-axis labels
                },
              },            
            xaxis: {
                labels: {
                    show: false,
                    style: {
                        fontSize: '40px', // Adjust the font size for x-axis labels
                    },
                },
            },        
        },
    );

    const [chartResponse, setChartResponse] = useState({
        'width':window.innerWidth > 800 ? 800 : window.innerWidth,
        'fontSize':'10px'
    });

    useEffect(() => {
        const handleResize = () => {
            let width;
            if (window.innerWidth > 800) {
                width = 800;
            }
            else{
                width = window.innerWidth;
            }
            setChartResponse(
                {
                    'width':width,
                    'fontSize':'10px'
                }
            );
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const renderHeatMap = function(option){
        
        return (
            <Chart
                options={option}
                series={series}
                // width="70%"
                width='600'
                height='400'
                type='heatmap'
            />
        )
    }
    return (
        // <React.Fragment>
        <div className="overflow-auto">
            <h1 
            className='titleGraph'
            style={{
                height:"10%",
                textAlign:"initial"
            }}>
                {props?.title}
            </h1>
            <div style={{ width: `${chartResponse['width']}px`, margin: '0 auto', overflow: "scroll" }}>
                {renderHeatMap(option)}
            </div>
        </div>
        // </React.Fragment>
    );


}