import React, { useEffect, useState } from "react";
import { ElementSimulation } from "../../components/LocationSimulation/ElementSimulation";
import { dataPortfolioSimulationRestaurant } from "../../services/createStaticData/createStaticDataSimulation";
import { motion} from "framer-motion";
import {Footer} from '../Footer/Footer'

export const PortfolioSimulationRestaurant = function(){
    const [entityPosition,setEntityPosition] = useState([[-400,-200,-100,50,-50],200])
    const [clientEntities,setClientEntities] = useState()
    const [dataSimulation, setDataSimulatuion] = useState(dataPortfolioSimulationRestaurant)
    const factorSimulationSpeed = .1;

    const adaptElementSimulation = function(arrayPositions, element){
        return arrayPositions.map(([xOrigin,yOrigin],index)=>{
            return (
                <ElementSimulation
                    key={index.toString()}
                    xOrigin={xOrigin}
                    yOrigin={yOrigin}
                    text={xOrigin + ' , ' + yOrigin}
                    element={element}
                    
                />
            )
        });
    }
    const tablePositions = []
    for (let yId = 0; yId < 5; yId++) {
        for (let xId = 0; xId < 4; xId++) {
            tablePositions.push([xId*150, yId*150])
        }            
    }    

    const receptionPosition = [
        [-400,0],
    ];
    const kitchenPosition = [
        [-200,0],
    ];

    const locationPositions = {
        RECEPTION:[[-400,0]],
        TABLE_QUEUE:[[-200,200]],
        TABLE: tablePositions,
        EXIT:[[800,600]]
    }   

    const tableLocations = adaptElementSimulation(
        tablePositions,
        <motion.path fill="#2F2E2C" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/>)        

    // const receptionLocations = adaptElementSimulation(
    //     receptionPosition,
    //     <motion.path fill="red" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/>)
    const receptionLocations = adaptElementSimulation(
        receptionPosition,
        // <motion.path fill="red" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/>)
        <React.Fragment>
            <motion.path fill="black" d="M96 0C131.346 0 160 28.654 160 64C160 99.346 131.346 128 96 128C60.654 128 32 99.346 32 64C32 28.654 60.654 0 96 0ZM144 144H132.64C109.929 154.443 83.05 154.894 59.36 144H48C21.49 144 0 165.49 0 192V328C0 341.255 10.745 352 24 352H40V488C40 501.255 50.745 512 64 512H128C141.255 512 152 501.255 152 488V352H168C181.255 352 192 341.255 192 328V192C192 165.49 170.51 144 144 144Z"/>
            <motion.path fill="black" d="M352 0C387.346 0 416 28.654 416 64C416 99.346 387.346 128 352 128C316.654 128 288 99.346 288 64C288 28.654 316.654 0 352 0ZM400 144H388.64C365.929 154.443 339.05 154.894 315.36 144H304C277.49 144 256 165.49 256 192V328C256 341.255 266.745 352 280 352H296V488C296 501.255 306.745 512 320 512H384C397.255 512 408 501.255 408 488V352H424C437.255 352 448 341.255 448 328V192C448 165.49 426.51 144 400 144Z"/>
            <motion.path fill="black" d="M608 0C643.346 0 672 28.654 672 64C672 99.346 643.346 128 608 128C572.654 128 544 99.346 544 64C544 28.654 572.654 0 608 0ZM656 144H644.64C621.929 154.443 595.05 154.894 571.36 144H560C533.49 144 512 165.49 512 192V328C512 341.255 522.745 352 536 352H552V488C552 501.255 562.745 512 576 512H640C653.255 512 664 501.255 664 488V352H680C693.255 352 704 341.255 704 328V192C704 165.49 682.51 144 656 144Z"/>
        </React.Fragment>
        )

        
    const kitchenLocations = adaptElementSimulation(
        kitchenPosition,
        <motion.path fill="black" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"/>)
    

    // const clientGraph = <path fill="yellow" d="M96 0c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64S60.654 0 96 0m48 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H48c-26.51 0-48 21.49-48 48v136c0 13.255 10.745 24 24 24h16v136c0 13.255 10.745 24 24 24h64c13.255 0 24-10.745 24-24V352h16c13.255 0 24-10.745 24-24V192c0-26.51-21.49-48-48-48z"/>
    const clientGraph = <path d="M96 0c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64S60.654 0 96 0m48 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H48c-26.51 0-48 21.49-48 48v136c0 13.255 10.745 24 24 24h16v136c0 13.255 10.745 24 24 24h64c13.255 0 24-10.745 24-24V352h16c13.255 0 24-10.745 24-24V192c0-26.51-21.49-48-48-48z"/>
    // const clientGraph = (
    //     <React.Fragment>
    //         <motion.path d="M96 0C131.346 0 160 28.654 160 64C160 99.346 131.346 128 96 128C60.654 128 32 99.346 32 64C32 28.654 60.654 0 96 0ZM144 144H132.64C109.929 154.443 83.05 154.894 59.36 144H48C21.49 144 0 165.49 0 192V328C0 341.255 10.745 352 24 352H40V488C40 501.255 50.745 512 64 512H128C141.255 512 152 501.255 152 488V352H168C181.255 352 192 341.255 192 328V192C192 165.49 170.51 144 144 144Z"/>
    //         <motion.path d="M352 0C387.346 0 416 28.654 416 64C416 99.346 387.346 128 352 128C316.654 128 288 99.346 288 64C288 28.654 316.654 0 352 0ZM400 144H388.64C365.929 154.443 339.05 154.894 315.36 144H304C277.49 144 256 165.49 256 192V328C256 341.255 266.745 352 280 352H296V488C296 501.255 306.745 512 320 512H384C397.255 512 408 501.255 408 488V352H424C437.255 352 448 341.255 448 328V192C448 165.49 426.51 144 400 144Z"/>
    //         <motion.path d="M608 0C643.346 0 672 28.654 672 64C672 99.346 643.346 128 608 128C572.654 128 544 99.346 544 64C544 28.654 572.654 0 608 0ZM656 144H644.64C621.929 154.443 595.05 154.894 571.36 144H560C533.49 144 512 165.49 512 192V328C512 341.255 522.745 352 536 352H552V488C552 501.255 562.745 512 576 512H640C653.255 512 664 501.255 664 488V352H680C693.255 352 704 341.255 704 328V192C704 165.49 682.51 144 656 144Z"/>
    //     </React.Fragment>
    // );
    
    useEffect(()=>{
        
    },[])


    const runSimulation = function(){
        const allEntities = Object.values(dataSimulation)
            .filter((thread)=>{
                return thread['Element']==='ENTITY';
            })
            .map((thread)=>{
                return thread['Element id'];
            })


        const entities = [...new Set(allEntities)];

        const timeStampsEntities = entities
            .map((entityId)=>{
                const ts = Object.values(dataSimulation)
                    .filter((thread)=>{
                        return thread['Element id']===entityId;
                    })
                    .map((thread)=>{
                        return thread['Time Stamp Porcentage'];
                    });
                return ts;
            });
        
        const colorsState = {
            'ARRIVED':'rgb(0,0,255)',
            'WAITING':'rgb(255, 255, 0)',
            'GET':'rgb(0, 255, 0)',
            'FREE':'rgb(0, 0, 255)',
            'LEAVING':'rgb(0, 0, 255)',
            'MOVING':'rgb(0, 255, 0)',
            'EXIT':'rgb(0, 0, 255)'
        }

        const fig3Clients = ["M96 0C131.346 0 160 28.654 160 64C160 99.346 131.346 128 96 128C60.654 128 32 99.346 32 64C32 28.654 60.654 0 96 0ZM144 144H132.64C109.929 154.443 83.05 154.894 59.36 144H48C21.49 144 0 165.49 0 192V328C0 341.255 10.745 352 24 352H40V488C40 501.255 50.745 512 64 512H128C141.255 512 152 501.255 152 488V352H168C181.255 352 192 341.255 192 328V192C192 165.49 170.51 144 144 144Z",
        "M352 0C387.346 0 416 28.654 416 64C416 99.346 387.346 128 352 128C316.654 128 288 99.346 288 64C288 28.654 316.654 0 352 0ZM400 144H388.64C365.929 154.443 339.05 154.894 315.36 144H304C277.49 144 256 165.49 256 192V328C256 341.255 266.745 352 280 352H296V488C296 501.255 306.745 512 320 512H384C397.255 512 408 501.255 408 488V352H424C437.255 352 448 341.255 448 328V192C448 165.49 426.51 144 400 144Z",
        "M608 0C643.346 0 672 28.654 672 64C672 99.346 643.346 128 608 128C572.654 128 544 99.346 544 64C544 28.654 572.654 0 608 0ZM656 144H644.64C621.929 154.443 595.05 154.894 571.36 144H560C533.49 144 512 165.49 512 192V328C512 341.255 522.745 352 536 352H552V488C552 501.255 562.745 512 576 512H640C653.255 512 664 501.255 664 488V352H680C693.255 352 704 341.255 704 328V192C704 165.49 682.51 144 656 144Z"]

        // const fig1Client = "M96 0C131.346 0 160 28.654 160 64C160 99.346 131.346 128 96 128C60.654 128 32 99.346 32 64C32 28.654 60.654 0 96 0ZM144 144H132.64C109.929 154.443 83.05 154.894 59.36 144H48C21.49 144 0 165.49 0 192V328C0 341.255 10.745 352 24 352H40V488C40 501.255 50.745 512 64 512H128C141.255 512 152 501.255 152 488V352H168C181.255 352 192 341.255 192 328V192C192 165.49 170.51 144 144 144Z"
        const fig1Client = "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
        const figureState = {
            'ARRIVED':fig1Client,
            'WAITING':fig1Client,
            'GET':fig1Client,
            'FREE':fig1Client,
            'LEAVING':fig1Client,
            'MOVING':fig1Client,
            'EXIT':fig1Client
        }
        const colorsEntities = entities
            .map((entityId)=>{
                const ts = Object.values(dataSimulation)
                    .filter((thread)=>{
                        return thread['Element id']===entityId;
                    })
                    .map((thread, index)=>{
                        return colorsState[thread['Status']];
                    });
                return ts;
            });        
        const figureEntities = entities
            .map((entityId)=>{
                const ts = Object.values(dataSimulation)
                    .filter((thread)=>{
                        return thread['Element id']===entityId;
                    })
                    .map((thread, index)=>{
                        return figureState[thread['Status']];
                    });
                return ts;
            });        
        
        const arrivalTimeEntities = entities
            .map((entityId)=>{
                const ts = Object.values(dataSimulation)
                    .filter((thread)=>{
                        return thread['Element id']===entityId;
                    })
                    .map((thread)=>{
                        return thread['Time Stamp Initial'];
                    });
                return ts;
            });

        const TotalTimeEntities = entities
            .map((entityId)=>{
                const ts = Object.values(dataSimulation)
                    .filter((thread)=>{
                        return thread['Element id']===entityId;
                    })
                    .map((thread)=>{
                        return thread['Total Time'];
                    });
                return ts;
            });


        const locationsEntities = entities
            .map((entityId)=>{
                const ts = Object.values(dataSimulation)
                    .filter((thread)=>{
                        return thread['Element id']===entityId;
                    })
                    .map((thread)=>{
                        return locationPositions[thread['Location']][thread['Element Unit']];
                    });
                return ts;
            });            

        const lastTS = Math.max(...Object.values(dataSimulation).map((thread)=>thread['Time Stamp']));

        setTimeout(() => {
            setClientEntities('');
            }, 1000*lastTS*factorSimulationSpeed);

        console.log(entities);
        
        setClientEntities(
            entities
            .map((entityId,index)=>{

                return (
                <ElementSimulation                    
                    key={index}
                    xOrigin={locationsEntities[index].map(([x,y])=>x)}
                    yOrigin={locationsEntities[index].map(([x,y])=>y)}
                    text='Client'
                    element={clientGraph}
                    delay={arrivalTimeEntities[index][0]*factorSimulationSpeed}
                    times={timeStampsEntities[index]}                    
                    duration={TotalTimeEntities[index][0]*factorSimulationSpeed}
                    colors={colorsEntities[index]}
                    distances={figureEntities[index]}
                    // colors={index % 2 == 0 ? 'red' : 'yellow'}
                    // classEntities={classEntities[index]}
                />                    
                )
            })                
        )
    }
    

    return (
        <div>
            <div onClick={runSimulation} className='btn pressing'  style={{backgroundColor:'green',display:'inline-block',height:'5rem',fontSize:'2rem'}}>Run Simulation</div>
            <div className="simulationLayout" style={{width:'60%', height:'80rem', backgroundColor:'#E7E3E2'}}>
                {tableLocations}
                {receptionLocations}
                {kitchenLocations}
                {clientEntities}                
                
            </div>
            <Footer/>
        </div>
    )
}