import React from "react";
import { Parallax, Background } from 'react-parallax';
import header_montains from '../../static/images/header_montains_low.jpeg';


export const Header = function(){
    return (
        <React.Fragment>            
                <Parallax strength={300}>
                    <Background className="custom-bg" style={{width:"100%"}} bgImageStyle={{width:'100%'}}>
                        <img className="header__parallex" src={header_montains} alt="header_montains" style={{width:"100%", minHeight:390}}/>
                    </Background>
                    <header className="header" id="header">
                        <div className="header__name">                
                            <span className="header__name--row">FÁBIO</span>
                            <span className="header__name--row">YAMADA</span>
                            <span className='header__name--subname'>
                                data analyst ● data scientist ● full-stack programmer ● consultant
                            </span>
                        </div>
                    </header>
                </Parallax>
            {/* <header className="header">
                <div className="header__name">                
                    <span className="header__name--row">FÁBIO</span>
                    <span className="header__name--row">YAMADA</span>
                    <span className='header__name--subname'>
                        data analyst ● data scientist ● full-stack programmer ● consultant
                    </span>
                </div>
            </header> */}
        </React.Fragment>
    )
}