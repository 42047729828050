import React, { useEffect, useRef, useState } from "react";
import { GraphChart } from "../../components/Graphics/GraphChart/GraphChart";
import { importDataGraph } from "../../services/createStaticData/createStaticData";
import { SideBarContent } from "../../components/SideBar/SideBarContent";
import { BoxAnimation } from "../../components/BoxAnimation/BoxAnimation";
import { TextBlock } from "../../components/TextBlock/TextBlock";
import { Link } from "react-router-dom";
// import MLGNNvideo from "../../static/videos/MLGNN.mp4"; 
// import MLGNNvideo from "../../static/videos/"; 
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Reference } from "../../components/Reference/Reference";

export const PortfolioMLGNNaux = function(props:any){
    const testRef = useRef<HTMLElement>(null)
    const showElement = function(){
        testRef.current?.classList.toggle('opacity-100');        
    }

    const [ref, inView] = useInView();
    const [buffering, setBuffering] = useState(true);

    const control = useAnimation();
    const boxVariant = {
        visible: { opacity: 1, transition:{duration: 1} },
        hidden: { opacity: 0 },
      }

    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    
    useEffect(()=>{
        // if (inView){
        //     console.log('apareceu!');
        //     control.start("visible");
        // }
        // else{
        //     control.start("hidden");
        // }
        
    },[inView])


    const playbackConst = 200;
    const [frameNumber,setFrameNumber] = useState(0) // start video at frame 0


    // Use requestAnimationFrame for smooth playback
    function scrollPlay(){  
        // let frameNumber  = window.scrollY/playbackConst as number;
        let yPos = window.scrollY !== undefined ? window.scrollY : window.pageYOffset
        setFrameNumber(yPos/playbackConst);
        
        if (frameNumber){
            const vid = document.getElementById('v0') as HTMLVideoElement; 
            vid.currentTime  = yPos/playbackConst;

        }
        window.requestAnimationFrame(scrollPlay);
    }

    window.requestAnimationFrame(scrollPlay);

    const handleVideoLoad = function(e:any){
        const vid = document.querySelector('#v0') as HTMLVideoElement;

        vid.addEventListener('loadeddata', function() {
            let b:any;
            if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
                b = setInterval(()=>{
                    if(vid.readyState >= 3){
                        //This block of code is triggered when the video is loaded            
                        setBuffering(false); 
            
                        //stop checking every half second
                        clearInterval(b);
                    }
                },500);                            
            }
            else {
                b = setInterval(()=>{
                    if(vid.readyState == 4){
                        //This block of code is triggered when the video is loaded
            
                        setBuffering(false); 
            
                        //stop checking every half second
                        clearInterval(b);
            
                    }                   
                },500);            
            }

        });        
    }
    
    const videoRendering = function(){

        return (
            <div>
                <video onLoadedMetadata={handleVideoLoad} className=" fixed top-20 right-0 h-screen w-screen  pl-24 pb-36" id="v0" tabIndex={0} typeof="video/mp4" >
                    <source src={props.video_MLGNN} type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"' />
                </video>
                {buffering ? <svg className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="2.5" r="1.5" opacity=".14"/><circle cx="16.75" cy="3.77" r="1.5" opacity=".29"/><circle cx="20.23" cy="7.25" r="1.5" opacity=".43"/><circle cx="21.50" cy="12.00" r="1.5" opacity=".57"/><circle cx="20.23" cy="16.75" r="1.5" opacity=".71"/><circle cx="16.75" cy="20.23" r="1.5" opacity=".86"/><circle cx="12" cy="21.5" r="1.5"/><animateTransform attributeName="transform" type="rotate" calcMode="discrete" dur="0.75s" values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12" repeatCount="indefinite"/></g></svg> : ''}
            </div>
        )        
    }

    return (
        // <main className="pt-8 pb-16 lg:pt-16 lg:pb-24  dark:bg-gray-900 bg-gray-50">
        <div className="portfolio">
            <div className="z-10 relative">
                <SideBarContent
                    titles = {[
                        'Introduction',
                        'Bottom Level',
                        'Middle Level',
                        'Top Level',
                        'Readout Level',
                        'References',
                    ]}
                />
                <div className={'portfolio__content--empty lg:p-20 md:p-10 sm:p-5'} style={{width:'85vw'}}>

                {!buffering ? (
                    <React.StrictMode>
                        <BoxAnimation content={
                            <div style={{height:`${1010 - 80}px`}}></div>
                        } step={0}/>
                        <BoxAnimation content={
                            <div style={{height:`${9643-(1010-80)-80}px`}}></div>
                        } step={1}/>
                        <BoxAnimation content={
                            <div style={{height:`${33401-10080-1300}px`}}></div>
                        } step={2}/>
                        <BoxAnimation content={
                            <div style={{height:`${56649-33401-10080-1300 + 90}px`}}></div> //V
                        } step={3}/>
                        <BoxAnimation content={
                            <div style={{height:`${4350}px`}}></div>
                        } step={4}/>
                        <BoxAnimation content={
                            <div style={{height:`${3000}px`}}></div>
                        } step={5}/>
                    </React.StrictMode>
                ):''}
                </div>
            </div>
            <div className=" fixed top-5 left-36">                                
                <Reference name='Fábio Heiji Yamada' date='August 10, 2023'/>
            </div>
            {
                videoRendering()
            }
            
        
        </div>
    )
}