import React from "react";
import { motion, useAnimation } from "framer-motion";

import { useEffect } from "react";

export const ElementSimulation = function(props){
    
    const boxVariant = {

        destiny: {
            opacity: 1,
            transition:{
                delay: 3,
                duration: 3,
            },
            scale: 1,
            x: props.xDestiny,
            y: props.yDestiny,
        },
        origin: {
            opacity: 1,
            transition:{
                duration: props.duration,
                delay: props.delay,
                times: props.times,
            } ,
            scale: 1,
            fill: props.colors,
            // backgroundColor:props.colors,
            x: props.xOrigin,
            y: props.yOrigin,            
            // class:'teste'
            // class:props.classEntities,
            
            
        },
        noAnimation: {
            opacity: 1,
            transition:{                
                delay: props.delay,
                times: props.times,
            } ,
            scale: 1,
            fill: props.colors,
            d: props.distances,
            x: props.xOrigin,
            y: props.yOrigin,            
            
            
        },
        hidden: { 
            opacity: 1,
        },
    }

    const control = useAnimation();

    useEffect(()=>{
        control.start("origin");
        // control.start("noAnimation");
    });

    return (
        <motion.svg 
            animate={control}
            variants={boxVariant}
            initial="hidden"
            width={'10rem'} viewBox="0 0 1024 512"
            style={{color:'white', position:'absolute'}}
        >
            {props.element}
        </motion.svg>
    )
}