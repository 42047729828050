import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import { HashLink } from "react-router-hash-link";

export const SendEmail = function(props){

  const [buffering, setBuffering] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');

  const sendButton = function(){
    return (
        <button type="submit" className=" relative h-12 w-44 cursor-pointer hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full bg-tertiary">
            {buffering ? <svg className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><circle cx="12" cy="2.5" r="1.5" opacity=".14"/><circle cx="16.75" cy="3.77" r="1.5" opacity=".29"/><circle cx="20.23" cy="7.25" r="1.5" opacity=".43"/><circle cx="21.50" cy="12.00" r="1.5" opacity=".57"/><circle cx="20.23" cy="16.75" r="1.5" opacity=".71"/><circle cx="16.75" cy="20.23" r="1.5" opacity=".86"/><circle cx="12" cy="21.5" r="1.5"/><animateTransform attributeName="transform" type="rotate" calcMode="discrete" dur="0.75s" values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12" repeatCount="indefinite"/></g></svg>:""}
            <div className=" m-auto">Send</div>
            {/* <button className=" hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full bg-tertiary" type="submit">Send</button>                             */}
        </button>            
    )
  }  

  const alertMessage = function(){

    const message = () => {
        let text;
        switch (alertType) {
            case 'SUCCESSED':
                text = (
                    <div>
                        <span className="font-medium">Message sent!</span> I will get back to you as soon as possible!
                    </div>
                )
                break;
                case 'FAILED':
                    text = (
                        <div className=" w-full whitespace-normal break-words " >
                            <span className="font-medium">Failed to send the message!</span> Please try again later or reach out to me through <HashLink to={'https://www.linkedin.com/in/fabioyamada'} >Linkedin</HashLink>.
                        </div>
                    )
                    break;
                default:
                    text = (
                        <div>
                            <span className="font-medium">Message</span>
                        </div>
                    )
                    break;
        }

        return text
    }

    const alert = (
        <div data-alerttype={alertType} data-alert={showAlert} className=" transition-opacity flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg data-[alerttype=SUCCESSED]:bg-green-50 data-[alerttype=FAILED]:bg-red-100 data-[alert=true]:opacity-100 data-[alert=false]:opacity-0" role="alert">
            {/* <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5">
                <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                    clipRule="evenodd" />
            </svg>
            <span className="sr-only">Info</span>
            {/* <div>
                <span className="font-medium">Message sent!</span> I will get back to you as soon as possible!
            </div> */}
            {message()}
        </div>
    )
    return alert
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setBuffering(true);

    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_l69bdu9';
    const templateId = 'template_ti4qbhw';
    const publicKey = 'RZW7DDMaNZxx8pZvt';

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Web Wizard',
      message: message,
    };

    // Send the email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setBuffering(false);
        setShowAlert(true);
        setAlertType('SUCCESSED');
        setTimeout(() => {
          setShowAlert(false);
        }, 10000);        
        setName('');
        setEmail('');
        setMessage('');        
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setBuffering(false);
        setShowAlert(true);
        setAlertType('FAILED');
        setTimeout(() => {
          setShowAlert(false);
        }, 10000);        
      });
  }

  return (
    <React.Fragment>

        <div className="footer__get-touch">

            <form onSubmit={handleSubmit} className="footer__get-touch--form">
            <input
                className="footer__get-touch__input footer__get-touch__input--name" id="footer__get-touch__input--name"
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <input
                className="footer__get-touch__input footer__get-touch__input--email" id="footer__get-touch__input--email"
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
                className="footer__get-touch__input footer__get-touch__input--message" rows="20" cols="50" placeholder="How can I help?" pattern="\w{2,}" required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            >
            </textarea>
            
            {sendButton()}
            </form>
        </div>
        {alertMessage()}
    </React.Fragment>
  )
}