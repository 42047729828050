import React, { useState, useEffect, useCallback } from "react";
import { useSprings, animated, config, to, useSpringRef, useChain,useTransition } from 'react-spring';
import { dataPortfolioSimulationRestaurant } from "../../services/createStaticData/createStaticDataSimulation";


export const ElementSimulation2 = function(props){
    const [data,setData]= useState(Object.values(dataPortfolioSimulationRestaurant))

    const elementFramework = function(positionX, positionY,elementOpacity,simulationDelay,simulationDuration,elementColor){
        return ({
            x: positionX,
            y: positionY,
            opacity: elementOpacity,
            delay: simulationDelay,
            config:{duration:simulationDuration},
            backgroundColor:elementColor,
        });
    }

    const springs = useSprings(
      props.dataEntity.length,
      props.dataEntity.map((item)=>{
        let x,y;
        [x,y]=props.locationPositions[item['Location Name']][item['Location Id']];
        return ({
          from: { x: 0, y:0, opacity: 0 , backgroundColor:'yellow'},
          to: elementFramework(x,y,1,item['Time Stamp']*10,item['Duration']*10,props.colorsState[item['Status']])
        })
      })
    )
    
      useEffect(() => {
    
      }, []);

      // const playSimulation = function(){        
        // let x,y;
        // console.log(props.dataEntity);
        // api.start(index=>console.log(index));
        // props.dataEntity.map((thread, index)=>{
        //     [x,y]=props.locationPositions[thread['Location Name']][thread['Location Id']]
        //     api.start(
        //       elementFramework(
        //         x,
        //         y,
        //         1,
        //         thread['Time Stamp']*10,
        //         thread['Duration']*10,
        //         props.colorsState[thread['Status']]
        //       )
        //     );            
        // })

      // };

      // const pauseSimulation = function(){
      //   api.pause();
      // };
      // const resumeSimulation = function(){
      //   api.resume();
      // };
    
      return (       
        <React.Fragment>
            {/* <animated.button onClick={playSimulation}>Play</animated.button> 
            <animated.button onClick={pauseSimulation}>Pause</animated.button> 
            <animated.button onClick={resumeSimulation}>Resume</animated.button>  */}
            <div
              
            >
              {springs.map((styleAnimation, index) => (
                <animated.div
                  key={index}
                  style={{
                    position:'absolute',
                    width: 80,
                    height: 80,
                    backgroundColor: '#46e891',
                    borderRadius: 16,
                    ...styleAnimation,
                  }} 
                />
              ))}
            </div>

        </React.Fragment>
      )

    
}