import './App.css';
import React, { useEffect, useState } from 'react';
import { Header } from './pages/Header/Header';
import { Footer } from './pages/Footer/Footer';
// import { Footer } from './pages/Footer/FooterSimple';
import { Main } from './pages/Main/Main';
import { PortfolioSimulationRestaurant } from './pages/Portfolio/PortfolioSimulationRestaurant';
import { PortfolioSimulationRestaurant2 } from './pages/Portfolio/PortfolioSimulationRestaurant2';
import { PortfolioDTvsMLP } from './pages/Portfolio/PortfolioDTvsMLP';
import { ReactSpringTest } from './pages/Portfolio/ReactSpringTest';
import { DenseNet121 } from './pages/Portfolio/DenseNet121';
import { PortfolioMLGNN } from './pages/Portfolio/PortfolioMLGNN';
import { PortfolioMLGNNaux } from './pages/Portfolio/PortfolioMLGNNaux';
import { PortfolioLlama2 } from './pages/Portfolio/PortfolioLlama2';
import videoMLGNN from './static/videos/MLGNN.mp4';
// import {SalesForecastTable} from './pages/Portfolio/SalesForecast';


import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
// import {Route, Routes, HashRouter} from 'react-router-dom';

import { Parallax, Background } from 'react-parallax';


function App() {
  const [heightBody,setHeightBody] = useState('100vh');
  useEffect(()=>{
    setHeightBody(document.querySelector('body').clientHeight);
  },[]);
  // setHeightBody(document.querySelector('body').clientHeight);
  // console.log(document.querySelector('body').clientHeight);
  return (
    <Router basename=''>
      <div className="App">
        {/* <Navigation/> */}
        <Routes>
          <Route exa path={'/PortfolioDTvsMLP'} element={<PortfolioDTvsMLP />} />
          <Route exa path={'/PortfolioSimulationRestaurant'} element={<PortfolioSimulationRestaurant />} />
          <Route exa path={'/PortfolioSimulationRestaurant2'} element={<PortfolioSimulationRestaurant2 />} />
          <Route exa path={'/DenseNet121'} element={<DenseNet121 />} />
          <Route exa path={'/ReactSpringTest'} element={<ReactSpringTest />} />
          <Route exa path={'/PortfolioMLGNN'} element={<PortfolioMLGNN video_MLGNN={videoMLGNN}/>} />
          <Route exa path={'/PortfolioMLGNNaux'} element={<PortfolioMLGNNaux video_MLGNN={videoMLGNN}/>} />
          <Route exa path={'/PortfolioLlama2'} element={<PortfolioLlama2 />} />
          {/* <Route exa path={'/SalesForecastTable'} element={<SalesForecastTable />} /> */}
          <Route path={'/*'} element={
            <React.Fragment>
              <Parallax strength={900} className={'geometric__parallax'}>
                <Background className="custom-bg2">
                  <div className='testParallax' style={{height:heightBody}}></div>
                </Background>
                <Header/>
                <Main/>
                <Footer/>
              </Parallax>
            </React.Fragment>
          }/>        
        </Routes>
      </div>
    </Router>
  );
}

export default App;
