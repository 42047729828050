import React, {useState, useEffect} from "react";
import { SideBarContent } from "../../components/SideBar/SideBarContent";
import { BoxAnimation } from "../../components/BoxAnimation/BoxAnimation";
import { TextBlock } from "../../components/TextBlock/TextBlock";
import { ColumnChart } from "../../components/Graphics/ColumnChart/ColumnChart";
import { HeatMapFlex } from "../../components/Graphics/HeatMap/HeatMapFlex";
import { importDataHeatMapDiseases } from "../../services/createStaticData/createStaticData";
import { importDataColumnsBarDiseases } from "../../services/createStaticData/createStaticData";
import rayX from '../../static/images/00000001_002.png'
import adaptiveSampling from '../../static/images/adaptiveSampling.png'
import LegendAdaptiveSampling from '../../static/images/LegendAdaptiveSampling.png'
import adaptiveSamplingDiagram from '../../static/images/adaptiveSamplingDiagram.png'
import UncontrolledExample from "../../components/Carousel/CarouselFlex";
import { importDataHeatMapAUC } from "../../services/createStaticData/createStaticData";
import { HeatMapFlexAUC } from "../../components/Graphics/HeatMap/HeatMapFlexAUC";
import { importMaxAUC } from "../../services/createStaticData/createStaticData";
import denseNet121_structure from '../../static/images/denseNet121_structure.png'
import vgg16_structure from '../../static/images/vgg16_structure.png'
import resnet50 from '../../static/images/ResNet50.png'
import { Reference } from "../../components/Reference/Reference";

export const DenseNet121 = function(){
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
      
    const [disease, setDisease] = useState('Atelectasis');

    let [diseaseColumnBarLabels,setDiseaseColumnBarLabels] = useState(importDataColumnsBarDiseases('Atelectasis')['labels'])
    let [diseaseColumnBarValues,setDiseaseColumnBarValues] = useState(importDataColumnsBarDiseases('Atelectasis')['values'])

    const chooseDiseaseColumnBar = function(e){        
        // e.target.textContent
        setDiseaseColumnBarLabels(importDataColumnsBarDiseases(e.target.textContent)['labels']);
        setDiseaseColumnBarValues(importDataColumnsBarDiseases(e.target.textContent)['values']);
        
        document.querySelector("div.btn__pressed").classList.toggle("btn__pressed");
        e.target.classList.toggle("btn__pressed");
        setDisease(e.target.textContent);
    }

    // useEffect(()=>{

    // },[diseaseColumnBar])

    return (
        <div className="portfolio">          
            <SideBarContent
                titles = {[
                    'Objective',
                    'Dataset description',
                    'Adaptive Sampling',
                    'Models',
                    'Results',
                    'Conclusion',
                    'References',
                ]}
            />
            {/* <div className={'portfolio__content'} style={{padding: '5rem', width:'85vw'}}> */}
            <div className={'portfolio__content'}>
                <div style={{display:'flex', flexDirection: 'column'}}>
                <BoxAnimation
                    content={
                      <section id='step--0'>
                        <TextBlock title={true} text={'Detecting thoracic diseases via representation learning with adaptive sampling'} />
                        <TextBlock text={
                            <Reference name='Fábio Heiji Yamada' date='August 10, 2023'/>
                        } />
                        
                        <TextBlock subtitle={true} text={`Objective`}/>
                        <TextBlock text={`
                            Use of the DenseNet-121 network for a sample of the dataset (Chest X-rays) used in the article considering class imbalance and adaptation algorithm proposed in the article.
                        `} />
                      </section>
                    }
                    step={0}
                  />                    
                </div>
            
                <BoxAnimation
                    content={
                    <section id='step--1'>
                        <TextBlock subtitle={true} text={`Dataset`}/>
                        <TextBlock text={`This dataset has 112,120 images and 14 diseases. The bar chart below show how disbalanced is this dataset (click on the button below to see the number of diseases related to a specific one)`}/>
                        <TextBlock text={
                        //   <div style={{display:"grid", gridTemplateColumns:'1fr 1fr 1fr 1fr '}}>
                          <div className="flex flex-wrap gap-x-1">
                            <div className="btn pressing btn__pressed" onClick={chooseDiseaseColumnBar}>Atelectasis</div>
                            {["Cardiomegaly","Consolidation","Edema","Effusion","Emphysema","Fibrosis","Hernia","Infiltration","Mass","Nodule","Pleural_Thickening","Pneumonia","Pneumothorax"].map((d,idx)=>
                                <div key={idx} className="btn pressing" onClick={chooseDiseaseColumnBar}>{d}</div>
                            )}

                          </div>
                        }/>                        
                    </section>
                    }
                    step={1}
                />
            
                <ColumnChart 
                    title={`Number of Diseases when the image has the disease ${disease}`}
                    // labels = {['Atelectasis', 'Cardiomegaly', 'Consolidation', 'Edema', 'Effusion', 'Emphysema', 'Fibrosis', 'Hernia', 'Infiltration', 'Mass', 'Nodule', 'Pleural_Thickening', 'Pneumonia', 'Pneumothorax']}
                    // values = {[11559.0, 370.0, 1223.0, 221.0, 3275.0, 424.0, 220.0, 40.0, 3264.0, 739.0, 590.0, 496.0, 262.0, 774.0]}
                    labels = {diseaseColumnBarLabels}
                    values = {diseaseColumnBarValues}
                />

                <BoxAnimation
                    content={
                        <section id='step--1'>
                            <TextBlock text={`Each image could have more than one disease`}/>
                            <TextBlock text={
                                <div>
                                    This X-Ray image below contains two diseases: <strong>Cardiomegaly</strong> and <strong>Effusion</strong>
                                </div>
                            }/>
                            <TextBlock text={                                
                                <div style={{display:"block", textAlign:'left'}}>
                                    <img srcSet={rayX} alt="Ray X Image" style={{maxHeight:500, minHeight:100, maxWidth:'90%'}}></img>
                                </div>
                            }/>
                        </section>
                    }
                    step={1}
                />

                <HeatMapFlex
                    data={importDataHeatMapDiseases()}
                    title={"Number of diseases when the image contains a centain disease"}
                />

                <BoxAnimation
                    content={
                      <section id='step--2'>
                        <TextBlock subtitle={true} text={`Adaptive Sampling`}/>
                        <TextBlock text={`
                            The main idea was to try to reproduce the adaptive sampling technique discribed in the paper Wang (2020):
                        `} />
                        <TextBlock text={
                            <div style={{display:"block", textAlign:'left'}}>
                                <img srcSet={adaptiveSampling} alt="Adaptive Sampling" style={{maxHeight:500, minHeight:100, maxWidth:'100%'}}></img>
                            </div>                            
                        }/>
                        <TextBlock text={
                            <div style={{display:"block", textAlign:'left'}}>
                                <img srcSet={LegendAdaptiveSampling} alt="Legend Adaptive Sampling" style={{maxHeight:200, minHeight:50, maxWidth:'100%'}}></img>
                            </div>                            
                        }/>
                        <TextBlock text={
                            <div style={{display:"block", textAlign:'left'}}>
                                <img srcSet={adaptiveSamplingDiagram} alt="Adaptive Sampling Diagram" width='100%'></img>
                            </div>                            
                        }/>
                      </section>
                    }
                    step={2}
                  />   

                <BoxAnimation
                    content={
                      <section id='step--3'>
                        <TextBlock subtitle={true} text={`Models`}/>
                        <TextBlock text={`
                            The models were run using the adaptive sampling algorithm and the ROC curve was evaluated for each iteration in each model. Folow a example of 3 algorithm iterations of the DenseNet121 model in the ROC charts. The 2nd iteration produced the best results.
                        `} />
                        <TextBlock text={
                            <div>
                                <img srcSet={denseNet121_structure} alt="DenseNet121 structure" width='100%'></img>
                                <div>
                                    <strong>DenseNet-121</strong> Source:<a href="https://www.sciencedirect.com/science/article/abs/pii/S092523122030549X">ScienceDirect</a>
                                </div>
                            </div>
                        }/>
                        <TextBlock text={
                            <div>
                                <img srcSet={vgg16_structure} alt="VGG16 structure" width='100%'></img>
                                <div>
                                    <strong>VGG16</strong> Source:<a href="https://www.researchgate.net/profile/Timea-Bezdan/publication/333242381/figure/fig2/AS:760979981860866@1558443174380/VGGNet-architecture-19.ppm">ResearchGate</a>
                                </div>
                            </div>
                        }/>
                        <TextBlock text={
                            <div>
                                <img srcSet={resnet50} alt="ResNet50 structure" width='100%'></img>
                                <div>
                                    <strong>ResNet50</strong>Source:<a href="https://commons.wikimedia.org/wiki/File:ResNet50.png">Wikimedia Commons</a>
                                </div>
                            </div>
                        }/>
                      </section>
                    }
                    step={3}
                />

                <BoxAnimation
                    content={
                      <section id='step--4'>
                        <TextBlock subtitle={true} text={`Results`}/>
                        <TextBlock text={`
                            The models were run using the adaptive sampling algorithm and the ROC curve was evaluated for each iteration in each model. Folow a example of 3 algorithm iterations of the DenseNet121 model in the ROC charts. The 2nd iteration produced the best results.
                        `} />
                      </section>
                    }
                    step={4}
                />

                <div style={{width:'100%', maxWidth:600}}>
                    <UncontrolledExample/>
                </div>

                <BoxAnimation
                    content={
                      <section id='step--4'>
                        <TextBlock text={`
                            The models used were: DenseNet-121, VGG-16 and ResNet50. The results were measured in AUC:
                        `} />
                      </section>
                    }
                    step={4}
                />

                <HeatMapFlexAUC
                    data={importDataHeatMapAUC()}
                    dataMaxArg={importMaxAUC()}
                    title={"Table with the AUC of each model"}
                />

                <BoxAnimation
                    content={
                      <section id='step--5'>
                        <TextBlock subtitle={true} text={`Conclusion`}/>
                        <TextBlock text={`
                            Adaptive algorithm together with DenseNet-121 proved to be better than VGG16 and ResNet50;
                        `} />
                        <TextBlock text={`
                            DenseNet-121 is a model that requires high memory and processing capacity;
                        `} />
                        <TextBlock text={`
                            The adaptive sampling algorithm requires even more memory as it increases the sampling with each iteration.
                        `} />
                      </section>
                    }
                    step={5}
                />

                <BoxAnimation
                    content={
                      <section id='step--6'>
                        <TextBlock subtitle={true} text={`References`}/>
                        <TextBlock text={
                            <div>
                                <p>Fonte Imagens: https://nihcc.app.box.com/v/ChestXray-NIHCC</p>
                                <p>Glorot, X. & Bengio, Y.. (2010). Understanding the difficulty of training deep feedforward neural networks. Proceedings of the Thirteenth International Conference on Artificial Intelligence and Statistics, in PMLR 9:249-256</p>
                                <p>MICHELL, Tom M.. Machine Learning. [S.I.]: McGraw-Hill Science/engineering/math, 1997. 432 p.</p>
                                <p>LIU, Danqing. A Practical Guide to ReLU. 2017. Disponível em: https://medium.com/@danqing/a-practical-guide-to-relu-b83ca804f1f7. Acesso em: 12 out. 2020.</p>
                                <p>Kingma, D. P. and Ba, J. (2014). Adam: A method for stochastic optimization. arXiv preprint arXiv:1412.6980.</p>
                                <p>TENSORFLOW. 2018. Acessado em: 2020-10-12. Disponível em: https://www.tensorflow.org/</p>
                                <p>HUANG, G. et al. Densely connected convolutional networks. In: Proceedings of the IEEE conference on computer vision and pattern recognition. [S.I.: s.n.], 2017. p. 4700-4708.</p>
                                <p>WANG, Hao et al. Detecting thoracic diseases via representation learning with adaptive sampling. Neurocomputing, v. 406, p. 354-360, 2020.</p>
                                <p>Fonte Dados: https://nihcc.app.box.com/v/ChestXray-NIHCC</p>
                            </div>
                        }/>
                        
                      </section>
                    }
                    step={6}
                />


            </div>

        </div>
    )
}