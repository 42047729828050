import Carousel from 'react-bootstrap/Carousel';
import roc_densenet121_1 from '../../static/images/roc_densenet121_1.png'
import roc_densenet121_2 from '../../static/images/roc_densenet121_2.png'
import roc_densenet121_3 from '../../static/images/roc_densenet121_3.png'


function UncontrolledExample() {
  return (
    <Carousel controls indicators keyboard prevLabel variant='dark'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={roc_densenet121_1}
          alt="Iteration 1"
        />
        <Carousel.Caption>
          <h3 style={{color:'black', textAlign:'start'}}>1st Iteration of Adaptive Sampling</h3>
          <p style={{color:'black', textAlign:'start'}}>These results were not influenced by the Adaptive Sampling algorithm.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={roc_densenet121_2}
          alt="Iteration 2"
        />

        <Carousel.Caption>
          <h3 style={{color:'black', textAlign:'start'}}>2nd Iteration of Adaptive Sampling</h3>
          <p style={{color:'black', textAlign:'start'}}>This iteration produced the best results.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={roc_densenet121_3}
          alt="Iteration 3"
        />

        <Carousel.Caption>
          <h3 style={{color:'black', textAlign:'start'}}>3rd Iteration of Adaptive Sampling</h3>
          <p style={{color:'black', textAlign:'start'}}>The result of 3rd iteration was worse than the second one.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;