import React, { useEffect, useRef, useState } from "react";
import Graph, {} from "react-graph-vis";

export const GraphChart = function({data}){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [networkAux, setNetworkAux] = useState({})

    const ref = useRef(null);

    const [state, setState] = useState({
        graph: data,
        events: {
            resize: function(){
                if (Object.keys(networkAux).length > 0) { // Network will be set using getNetwork event from the Graph component
                    // network.setOptions({ physics: false }); // Disable physics after stabilization
                    // network.fit();
                    console.log('resize-network: ',networkAux);
                }
            },
            select: function(event) {
                const { nodes, edges } = event;
            },
            hoverNode: (nodes) => {
                // console.log(nodes['node']);
                // console.log(data['nodes'][nodes['node']]['label']);
                // console.log(nodes['pointer']['canvas']);
            }
        }
    });    

    const options = {
        layout: {
            hierarchical: false
        },
        edges: {
            color: "#000000",
            arrows: {
                to: {
                    enabled: false
                }   
            },
            length:400
        },
        // width: window.innerWidth ? "100%" : window.innerWidth,
        width: "70%",
        height: "500px",
        autoResize: true,
        interaction:{
            hover:true,
            tooltipDelay:1,
        },
        
    };

    useEffect(() => {  
        // ref.current.querySelector('canvas').classList.add('border-solid border-black m-4 p-4 border-2');
        ref.current.querySelector('canvas').classList.add('border-solid');
        ref.current.querySelector('canvas').classList.add('border-black');
        ref.current.querySelector('canvas').classList.add('m-4');
        ref.current.querySelector('canvas').classList.add('mr-10');
        ref.current.querySelector('canvas').classList.add('p-4');
        ref.current.querySelector('canvas').classList.add('border-2');
        ref.current.querySelector('.vis-network').classList.add('overflow-visible');
        ref.current.querySelector('.vis-network').classList.add('cursor-pointer');

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (Object.keys(networkAux).length > 0){

                networkAux['canvas']['body']['view']['scale']=.7
                networkAux['canvas']['body']['view']['translation']['x']=networkAux['canvas']['canvasViewCenter']['x']
                networkAux['canvas']['body']['view']['translation']['y']=networkAux['canvas']['canvasViewCenter']['y']
                                
                // console.log(networkAux);
                // console.log(networkAux['canvas']['canvasViewCenter']);
                // console.log(networkAux['canvas']['body']['view']['translation']);                
            }
        };
        
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };        

    }, [window.innerWidth]);

    const updateGraphPosition = function(){
        // console.log(networkAux);
        networkAux['canvas']['body']['view']['scale']=.7
        networkAux['canvas']['body']['view']['translation']['x']=networkAux['canvas']['canvasViewCenter']['x']
        networkAux['canvas']['body']['view']['translation']['y']=networkAux['canvas']['canvasViewCenter']['y']        
        
        console.log(networkAux.body.nodes[0]);
    }

    return (
        <div className="block w-full" ref={ref}>
            <Graph
                graph={state['graph']}
                options={options}
                events={state['events']}
                getNetwork={network => {
                    setNetworkAux(network);
                    network['canvas']['body']['view']['scale']=.7
                    network['canvas']['body']['view']['translation']['x']=network['canvas']['canvasViewCenter']['x']
                    network['canvas']['body']['view']['translation']['y']=network['canvas']['canvasViewCenter']['y']
                                    
                    // console.log(network);
                    // console.log(network['canvas']['canvasViewCenter']);
                    // console.log(network['canvas']['body']['view']['translation']);
                }}            
            />
        </div>
    );
}