import React, { useEffect, useState } from "react";
import { ElementSimulation2 } from "../../components/LocationSimulation/ElementSimulation2";
import { dataPortfolioSimulationRestaurant } from "../../services/createStaticData/createStaticDataSimulation";

export const PortfolioSimulationRestaurant2 = function(){
    const [data,setData]= useState(Object.values(dataPortfolioSimulationRestaurant));
    // const dataEntity = data.filter((d)=>d["Element id"]=='6013066960');
    // console.log(dataEntity);

    const tablePositions = []
    for (let yId = 0; yId < 5; yId++) {
        for (let xId = 0; xId < 4; xId++) {
            tablePositions.push([xId*150, yId*150])
        }            
    };
    const locationPositions = {
        RECEPTION:[[0,0]],
        TABLE_QUEUE:[[0,200]],
        TABLE: tablePositions,
        EXIT:[[800,600]]
    };
    
    const colorsState = {
        'ARRIVED':'rgb(0,0,255)',
        'WAITING':'rgb(255, 255, 0)',
        'GET':'rgb(0, 255, 0)',
        'FREE':'rgb(0, 0, 255)',
        'LEAVING':'rgb(0, 0, 255)',
        'MOVING':'rgb(0, 255, 0)',
        'EXIT':'rgb(255, 0, 0)'
    };

    return (
        <ElementSimulation2
            dataEntity={data.filter((d)=>d["Element id"]=='6013066960')}
            locationPositions ={locationPositions}
            colorsState={colorsState}
        />
    );
}