import React from "react";

export const TextBlock = function(props){
    const textContent = function(){
        return (            
            <div data-testid="TextBlock-test" className=
                {
                    "textBlock" + 
                    (props.title ? " textBlock__title" : '') +
                    (props.subtitle ? " textBlock__subtitle" : '') +
                    (props.subsubtitle ? " textBlock__subsubtitle" : '') +
                    (props.breakLine ? " textBlock__breakLine" : '') +
                    (props.center ? " textBlock__center" : '')
                }
            >
                {props.text}
            </div>
        );
    }
    
    return (
        <React.Fragment>
            {textContent()}
        </React.Fragment>
    );
}