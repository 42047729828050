import React, {createElement} from "react";
import { useEffect, useState } from "react";
import { Card } from "../../components/Card/Card";
import { ServiceBox } from "../../components/ServiceBox/ServiceBox";
import { Skills } from "../../components/Skills/Skills";
import { Link } from "react-router-dom";
import { Parallax, Background } from 'react-parallax';
import profile from "../../static/images/profile1.jpeg";
import PortfolioDTvsMLPImage from '../../static/images/PortfolioDTvsMLPImage.png';
import rayX from '../../static/images/00000001_002.png';
import MLGNN_image from '../../static/images/MLGNN_image.png';
import DataAnalyst2 from '../../static/images/Data Analyst2.png';
import Consultant from '../../static/images/Consultant.png';
import DataScientist from '../../static/images/DataScientist.png';
import FullStack from '../../static/images/FullStack.png';
import Llama2 from '../../static/images/llama2.jpeg';

// import { Navigation } from './pages/Navigation/Navigation';
import {NavigationMenu} from '../../pages/Navigation/NavigationMenu';
import { RenderImagePortfolio } from "../../components/RenderImagePortfolio/RenderImagePortfolio";



export const Main = function(){
    const [photoSize,setPhotoSize] = useState();
    // const [image_detailed,setPhotoSize] = useState();
    useEffect(()=>{
        let textoTamanho;
        textoTamanho = document.querySelector('.section-about__right').clientHeight;
        setPhotoSize(Math.min(textoTamanho,444));
        

    },[]);

    const toop_tip = {
        'PortfolioDTvsMLP':'This report aims to present the steps and criteria adopted in the comparison between two supervised machine learning models of car acceptability classification, that is, whether the car is unacceptable, acceptable, good or very good, given the influence of other variables. independent. Therefore, we have here a multiclass classification problem, whose variable of interest (or dependent variable) can assume only a single value out of four possible ones. And for the purposes of this experiment, the Multi Layer Perceptron (MLP) and Decision Tree (DT) models were used. This document will provide a brief description of the dataset that was used, as well as the reason for choosing the MLP and DT models. Next, the steps and assumptions made in the experiment will be described and, at the end, the conclusions and final considerations of the work will be exposed.',
        'DenseNet121':'text about DenseNet121'

    }

    const f_hexagon_tool_tip = function(e) {
        const section_portfolio = document.querySelector('.section-portfolio');
        section_portfolio.style.backgroundColor='rgb(106, 145, 186)';
        section_portfolio.style.position = 'relative';

        const new_text = document.createElement('div');
        new_text.setAttribute('class', 'hexagon_tool_tip');
        new_text.textContent = toop_tip[e.target.id];
        const rightPoint = e.target.getBoundingClientRect().right;
        new_text.style.left = `${rightPoint}px`;
        // new_text.style.left = `${rightPoint}rem`;
        console.log(new_text.style);

        document.querySelector('.section-portfolio').appendChild(
            new_text
        );

        
        

        const all_hexagon = document.querySelectorAll('.section-portfolio__hexagon');
        [...all_hexagon].map((hexagon)=>{
            if(hexagon.id != e.target.id){
                document?.querySelector(`#${hexagon.id}`).classList.toggle('section-portfolio__hexagon--decrease');
            }
        });
    };

    const f_exagon_reset = function(e) {
        document.querySelector('.section-portfolio').style.backgroundColor='';
        document.querySelector('.hexagon_tool_tip').remove();
        const all_hexagon = document.querySelectorAll('.section-portfolio__hexagon');
        [...all_hexagon].map((hexagon)=>{
            if(hexagon.id != e.target.id){
                document?.querySelector(`#${hexagon.id}`).classList.toggle('section-portfolio__hexagon--decrease');
            }
        });
    };

    const renderTextImg = function(e) {
        e.target.closest('.img_portfolio').children[1].classList.toggle('opacity-0');
        e.target.closest('.img_portfolio').children[0].classList.toggle('scale-125');
    }
    const hideTextImg = function(e) {
        e.target.closest('.img_portfolio').children[1].classList.toggle('opacity-0');
        e.target.closest('.img_portfolio').children[0].classList.toggle('scale-125');
    }    

    return (
        <main className="main">
            <NavigationMenu/>

            <section className="section-services" id="section-services">

                <div className="section-services__title">
                    <div className="section-services__title--text">
                        services
                    </div>
                </div>           

                <ServiceBox
                    title_back={"Data Analyst"} 
                    description={'Extraction, preparation, feature engineering, descriptive analysis, reporting, and more.'}
                    cover_text={'Data Analyst'}
                    image={DataAnalyst2}
                />             
                <ServiceBox
                    title_back={"Data Scientist"} 
                    description={'Statistics, math, problem solving, capturing data in ingenious ways, finding patterns, machine learning, and more.'}
                    cover_text={'Data Scientist'}
                    image={DataScientist}
                />

                <ServiceBox
                    title_back={"Full Stack"} 
                    description={
                        <ul className="list-ul">
                            <li className="list-ul__li_nomargin"> <strong>Front End</strong> (React, HTML, SCSS)</li>
                            <li className="list-ul__li_nomargin"><strong>Back End</strong> (Python, Django, Flask)</li>
                            <li className="list-ul__li_nomargin"><strong>Data Base</strong> (SQL, NoSQL, Neo4J)</li>
                        </ul>
                    }
                    cover_text={"Full Stack"}
                    image={FullStack}
                />   

                <ServiceBox
                    title_back={"Consultant"} 
                    description={'Logistics, Supply Chain, Demand Planning, Discrete Process Simulation, Machine Learning, Data Visualization, Programming (Python, JavaScript, SQL, etc)'}
                    cover_text={'Consultant'}
                    image={Consultant}
                />                                  
            </section>

            <section className="section-about" id="section-about">
                <div className="section-about__title">
                    <div className="section-about__title--text">
                        about me
                    </div>
                </div>                
                <div className="section-about__canvas">   
                <Parallax strength={300} className={'portfolio__parallax'}>
                    <Background className="custom-bg2" style={{width:"100%"}} bgImageStyle={{width:'100%'}} children={{}}>
                        {/* <img className="section-about__photo" srcSet={require("../../static/images/profile.jpeg")} alt="profile" style={{height:photoSize}}></img> */}
                        <img className="section-about__photo" srcSet={profile} alt="profile" style={{minHeight:photoSize}}></img>
                    </Background>
                </Parallax>                         
                </div>
                <div className="section-about__right">        
                    <div className="section-about__text">
                        <p>Hi, I'm Fábio, a <span className="section-about__text--highlight">data analyst</span>, <span className="section-about__text--highlight">data scientist</span> and <span className="section-about__text--highlight">full-stack programmer</span> passionate about solving problems through the use of data associated with technology and delivering solutions that are easy to use, elegant and that the user is able to understand clearly all the results.</p>
                        <p>I'm that geek where the hobby is programming and learning new technologies.</p>
                        <p>In my experience of more than 10 years of consulting in supply chain, logistics and planning, I learned to transform complex subjects and <span className="section-about__text--highlight">data</span> into rich, structured, understandable and necessary <span className="section-about__text--highlight">information</span> for companies to make the <span className="section-about__text--highlight">best decisions</span> in their business.</p>
                        <p>So, if you're interested in working together, hiring me, or just talking about <span className="section-about__text--highlight">machine learning</span>, <span className="section-about__text--highlight">artificial intelligence</span>, <span className="section-about__text--highlight">programming</span>, <span className="section-about__text--highlight">logistics</span>, <span className="section-about__text--highlight">supply chain</span>, <span className="section-about__text--highlight">simulation</span>, <span className="section-about__text--highlight">planning</span>... I'll be super happy to talk to you!</p>
                    </div>
                </div>
            </section>

            <section className="section-skills" id="section-skills">
                <div className="section-skills__title">
                    <div className="section-skills__title--text">
                        summary skills
                    </div>
                </div>    
                <div className="section-skills__columns">
                    <Skills
                        skills_header={'Machine Learning'}
                        skill_level = {
                            {
                                'Python':5,
                                'Pandas,Numpy':5,
                                'TensorFlow, SciPy':4,
                                'PostgresQL, MongoDB':3,
                            }
                        }
                    />                    
                    
                    <Skills
                        skills_header={'Web'}
                        skill_level = {
                            {
                                'JavaScript':5,
                                'HTML, CSS, SCSS':4,
                                'Django':4,
                                'React':3,
                            }
                        }
                    />
                    
                    <Skills
                        skills_header={'Data Visualization'}
                        skill_level = {
                            {
                                'Excel, Power Point':5,
                                'MatplotLib, Seaborn, Plotly':5,
                                'Tableau':4,
                                'D3.js':3,
                            }
                        }
                    />
                </div>            
            </section>
            
            <section className="section-portfolio" id="section-portfolio">
                <div className="row">
                    <div className="col-1-of-2">
                        <div className="section-portfolio__title">
                            <div className="section-portfolio__title--text">
                                portfolio
                            </div>
                        </div>
                    </div>
                </div>            
                <div style={{display:'flex', flexWrap:"wrap", justifyContent:"center"}}>
                    <RenderImagePortfolio
                        endPoint='PortfolioLlama2'
                        portfolioImage={Llama2}
                        textHover='How to use LLama 2 to summarize PDF: using to extract the key takeaways of academic articles'
                    />
                    <RenderImagePortfolio
                        endPoint='PortfolioMLGNN'
                        portfolioImage={MLGNN_image}
                        textHover='An animation to facilitate the understanding of my project on sentiment-classification in text using a multi-level neural network.'
                    />
                    <RenderImagePortfolio
                        endPoint='DenseNet121'
                        portfolioImage={rayX}
                        textHover='This report applies the DenseNet-121 network to a imbalanced Chest X-rays dataset, which was used by Wang et al. (2017) in their study on pneumonia detection. The report also considers the adaptation algorithm to deal with imbalaced datasets proposed by the authors.'
                    />
                    <RenderImagePortfolio
                        endPoint='PortfolioDTvsMLP'
                        portfolioImage={PortfolioDTvsMLPImage}
                        textHover='This report compares the performance of two supervised machine learning models for car acceptability classification: Multi Layer Perceptron (MLP) and Decision Tree (DT). The document conducts a statistical hypothesis test to evaluate the difference between the models.'
                    />
                </div>
            </section>

        </main>
        
    );
}