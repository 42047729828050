export const dataPortfolioSimulationRestaurant = {
    "0": {
        "Time Stamp": 250.96274273559374,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.49855076249553,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 0.0
    },
    "1": {
        "Time Stamp": 250.96274273559374,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.49855076249553,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 5.0
    },
    "2": {
        "Time Stamp": 255.96274273559374,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5084835269581177,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 0.0
    },
    "3": {
        "Time Stamp": 255.96274273559374,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5084835269581177,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 137.679647597025
    },
    "4": {
        "Time Stamp": 393.64239033261873,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7819914291327839,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 1.0
    },
    "5": {
        "Time Stamp": 394.64239033261873,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7839779820253013,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 0.5
    },
    "6": {
        "Time Stamp": 395.14239033261873,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7849712584715601,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 107.24214262723984
    },
    "7": {
        "Time Stamp": 502.3845329598586,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9980134471074824,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 1.0
    },
    "8": {
        "Time Stamp": 503.3845329598586,
        "Element id": "5935769216",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 250.96274273559374,
        "Total Time": 252.42179022426484,
        "Duration": 0.0
    },
    "9": {
        "Time Stamp": 232.8058872782442,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.538423254145072,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 2.089022919861037
    },
    "10": {
        "Time Stamp": 234.89491019810524,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5432546548095702,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 5.0
    },
    "11": {
        "Time Stamp": 239.89491019810524,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5548184357010209,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 0.0
    },
    "12": {
        "Time Stamp": 239.89491019810524,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5548184357010209,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 105.98962276175334
    },
    "13": {
        "Time Stamp": 345.8845329598586,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7999465905779048,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 1.0
    },
    "14": {
        "Time Stamp": 346.8845329598586,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8022593467561949,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 0.5
    },
    "15": {
        "Time Stamp": 347.3845329598586,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.80341572484534,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 84.0
    },
    "16": {
        "Time Stamp": 431.3845329598586,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9976872438217099,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 1.0
    },
    "17": {
        "Time Stamp": 432.3845329598586,
        "Element id": "5935774160",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 232.8058872782442,
        "Total Time": 199.57864568161438,
        "Duration": 0.0
    },
    "18": {
        "Time Stamp": 138.956612722062,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.609718842153428,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 0.5439301235986989
    },
    "19": {
        "Time Stamp": 139.5005428456607,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6121055183876589,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 5.0
    },
    "20": {
        "Time Stamp": 144.5005428456607,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6340446989062922,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 0.0
    },
    "21": {
        "Time Stamp": 144.5005428456607,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6340446989062922,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 0.0
    },
    "22": {
        "Time Stamp": 144.5005428456607,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6340446989062922,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 1.0
    },
    "23": {
        "Time Stamp": 145.5005428456607,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.638432535010019,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 0.5
    },
    "24": {
        "Time Stamp": 146.0005428456607,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6406264530618823,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 80.90222661983555
    },
    "25": {
        "Time Stamp": 226.90276946549625,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9956121638962734,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 1.0
    },
    "26": {
        "Time Stamp": 227.90276946549625,
        "Element id": "6001856656",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 138.956612722062,
        "Total Time": 88.94615674343424,
        "Duration": 0.0
    },
    "27": {
        "Time Stamp": 0.9595037714143184,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.0026366037512237425,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.742418135396945
    },
    "28": {
        "Time Stamp": 1.7019219068112634,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.004676681653032092,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 4.257581864603056
    },
    "29": {
        "Time Stamp": 5.959503771414319,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.016376016923812745,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7424181353969441
    },
    "30": {
        "Time Stamp": 6.701921906811263,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.01841609482562109,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 13.956676821436249
    },
    "31": {
        "Time Stamp": 20.65859872824751,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.05676740469882285,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.0
    },
    "32": {
        "Time Stamp": 25.65859872824751,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.07050681787141184,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.747484915447682
    },
    "33": {
        "Time Stamp": 31.406083643695194,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.08630023186272354,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.0000000000000036
    },
    "34": {
        "Time Stamp": 36.4060836436952,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.10003964503531254,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 11.706751079403276
    },
    "35": {
        "Time Stamp": 48.11283472309847,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.13220842303302732,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.0
    },
    "36": {
        "Time Stamp": 53.11283472309847,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.14594783620561633,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.2989748639096987
    },
    "37": {
        "Time Stamp": 55.41180958700817,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.15226514931134671,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 4.972723372850439
    },
    "38": {
        "Time Stamp": 60.38453295985861,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.1659296095138632,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.02727662714956125
    },
    "39": {
        "Time Stamp": 60.41180958700817,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.1660045624839357,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.156039509012139
    },
    "40": {
        "Time Stamp": 63.56784909602031,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.17467698864460224,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.8166838638382927
    },
    "41": {
        "Time Stamp": 65.3845329598586,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.17966902268645218,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.1833161361617073
    },
    "42": {
        "Time Stamp": 68.56784909602031,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.18841640181719124,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 16.35519314625317
    },
    "43": {
        "Time Stamp": 84.92304224227348,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.23335855304796488,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.8948816745965189
    },
    "44": {
        "Time Stamp": 85.81792391687,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.23581758286153687,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.5130488931378636
    },
    "45": {
        "Time Stamp": 89.33097281000786,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.24547102890920236,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5920694322656175
    },
    "46": {
        "Time Stamp": 89.92304224227348,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.24709796622055388,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7193480903452354
    },
    "47": {
        "Time Stamp": 90.64239033261872,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2490746503461871,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.17553358425128351
    },
    "48": {
        "Time Stamp": 90.81792391687,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.24955699603412587,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.8661221361433746
    },
    "49": {
        "Time Stamp": 93.68404605301338,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2574327632804413,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.646926756994489
    },
    "50": {
        "Time Stamp": 94.33097281000786,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2592104420817914,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.3114175226108529
    },
    "51": {
        "Time Stamp": 95.64239033261872,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.26281406351877606,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.041655720394658
    },
    "52": {
        "Time Stamp": 98.68404605301338,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2711721764530303,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.4728285271849302
    },
    "53": {
        "Time Stamp": 99.1568745801983,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2724714537519864,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.0
    },
    "54": {
        "Time Stamp": 104.1568745801983,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2862108669245754,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 4.425771974568349
    },
    "55": {
        "Time Stamp": 108.58264655476665,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2983723688778273,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.0
    },
    "56": {
        "Time Stamp": 113.58264655476665,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.31211178205041634,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 9.001366759325592
    },
    "57": {
        "Time Stamp": 122.58401331409225,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3368464814552929,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.4173872119338427
    },
    "58": {
        "Time Stamp": 124.00140052602609,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3407412951613535,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.5826127880661573
    },
    "59": {
        "Time Stamp": 127.58401331409225,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.35058589462788187,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.1443989770996694
    },
    "60": {
        "Time Stamp": 128.72841229119192,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.353730568704014,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.2729882348341732
    },
    "61": {
        "Time Stamp": 129.0014005260261,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.35448070833394246,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 4.727011765165827
    },
    "62": {
        "Time Stamp": 133.72841229119192,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.367469981876603,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.21396288716630352
    },
    "63": {
        "Time Stamp": 133.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.36805792677867855,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5581676673024845
    },
    "64": {
        "Time Stamp": 134.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.36959170601880836,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.6896642843873053
    },
    "65": {
        "Time Stamp": 136.190207130048,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.37423470516404117,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.7521680483102102
    },
    "66": {
        "Time Stamp": 138.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3817973399512676,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "67": {
        "Time Stamp": 138.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3817973399512676,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5581676673024845
    },
    "68": {
        "Time Stamp": 139.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3833311191913974,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "69": {
        "Time Stamp": 139.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3833311191913974,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.6896642843873053
    },
    "70": {
        "Time Stamp": 141.190207130048,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3879741183366302,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.71256233544824
    },
    "71": {
        "Time Stamp": 141.90276946549625,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3899321560042199,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.0396057128619702
    },
    "72": {
        "Time Stamp": 143.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.39553675312385655,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "73": {
        "Time Stamp": 143.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.39553675312385655,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5581676673024845
    },
    "74": {
        "Time Stamp": 144.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3970705323639864,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "75": {
        "Time Stamp": 144.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3970705323639864,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.4022266198355453
    },
    "76": {
        "Time Stamp": 146.90276946549625,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.40367156917680885,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "77": {
        "Time Stamp": 146.90276946549625,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.40367156917680885,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.0396057128619702
    },
    "78": {
        "Time Stamp": 148.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4092761662964456,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "79": {
        "Time Stamp": 148.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4092761662964456,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5581676673024845
    },
    "80": {
        "Time Stamp": 149.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.41080994553657535,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "81": {
        "Time Stamp": 149.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.41080994553657535,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.4022266198355453
    },
    "82": {
        "Time Stamp": 151.90276946549625,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4174109823493979,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "83": {
        "Time Stamp": 151.90276946549625,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4174109823493979,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.0396057128619702
    },
    "84": {
        "Time Stamp": 153.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.42301557946903456,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "85": {
        "Time Stamp": 153.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.42301557946903456,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5581676673024845
    },
    "86": {
        "Time Stamp": 154.5005428456607,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4245493587091644,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.1349092511293861
    },
    "87": {
        "Time Stamp": 155.6354520967901,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4276679561320964,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.2673173687061592
    },
    "88": {
        "Time Stamp": 156.90276946549625,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.43115039552198686,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.0396057128619702
    },
    "89": {
        "Time Stamp": 158.94237517835822,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4367549926416236,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.6930769184318706
    },
    "90": {
        "Time Stamp": 160.6354520967901,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.44140736930468544,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.5236721427970963
    },
    "91": {
        "Time Stamp": 162.1591242395872,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.44559424152657606,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7179879596979788
    },
    "92": {
        "Time Stamp": 162.87711219928516,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.44756718817282304,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.6643590868245326
    },
    "93": {
        "Time Stamp": 166.5414712861097,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.45763641687414564,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.6176529534774886
    },
    "94": {
        "Time Stamp": 167.1591242395872,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4593336546991651,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7179879596979788
    },
    "95": {
        "Time Stamp": 167.87711219928516,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.461306601345412,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.8630116873900704
    },
    "96": {
        "Time Stamp": 168.74012388667524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4636780561745771,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.738912890067752
    },
    "97": {
        "Time Stamp": 170.479036776743,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.46845638470813344,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0624345093667102
    },
    "98": {
        "Time Stamp": 171.5414712861097,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.47137583004673467,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.3452264379367591
    },
    "99": {
        "Time Stamp": 171.88669772404646,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4723244717805175,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.8534261626287787
    },
    "100": {
        "Time Stamp": 173.74012388667524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4774174693471661,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "101": {
        "Time Stamp": 173.74012388667524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4774174693471661,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.738912890067752
    },
    "102": {
        "Time Stamp": 175.479036776743,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4821957978807224,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.4076609473034694
    },
    "103": {
        "Time Stamp": 176.88669772404646,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.48606388495310654,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.8534261626287787
    },
    "104": {
        "Time Stamp": 178.74012388667524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4911568825197551,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.6905187846308536
    },
    "105": {
        "Time Stamp": 182.4306426713061,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.501297995000404,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.5343071321383945
    },
    "106": {
        "Time Stamp": 183.96494980344448,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5055140909248239,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.4656928678616055
    },
    "107": {
        "Time Stamp": 187.4306426713061,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5150374081729929,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.04263967881092867
    },
    "108": {
        "Time Stamp": 187.47328235011702,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5151545770059389,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0015110639448892
    },
    "109": {
        "Time Stamp": 188.4747934140619,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5179066118668305,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.49015638938257666
    },
    "110": {
        "Time Stamp": 188.96494980344448,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5192535040974129,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0582259234918467
    },
    "111": {
        "Time Stamp": 190.02317572693633,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5221613847359726,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.4501066231806874
    },
    "112": {
        "Time Stamp": 192.47328235011702,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5288939901785279,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0015110639448892
    },
    "113": {
        "Time Stamp": 193.4747934140619,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5316460250394195,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.3540110052645389
    },
    "114": {
        "Time Stamp": 194.82880441932645,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5353666883677319,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.19437130760988452
    },
    "115": {
        "Time Stamp": 195.02317572693633,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5359007979085617,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.508699863319606
    },
    "116": {
        "Time Stamp": 195.53187559025594,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5372986454291592,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.767969830198723
    },
    "117": {
        "Time Stamp": 197.29984542045466,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5421568190239137,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.5289589988717864
    },
    "118": {
        "Time Stamp": 199.82880441932645,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5491061015403209,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "119": {
        "Time Stamp": 199.82880441932645,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5491061015403209,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7030711709294906
    },
    "120": {
        "Time Stamp": 200.53187559025594,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5510380586017481,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "121": {
        "Time Stamp": 200.53187559025594,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5510380586017481,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.767969830198723
    },
    "122": {
        "Time Stamp": 202.29984542045466,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5558962321965026,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "123": {
        "Time Stamp": 202.29984542045466,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5558962321965026,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.5289589988717864
    },
    "124": {
        "Time Stamp": 204.82880441932645,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.56284551471291,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "125": {
        "Time Stamp": 204.82880441932645,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.56284551471291,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7030711709294906
    },
    "126": {
        "Time Stamp": 205.53187559025594,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5647774717743372,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0332941482159583
    },
    "127": {
        "Time Stamp": 206.5651697384719,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5676168428205687,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7346756819827647
    },
    "128": {
        "Time Stamp": 207.29984542045466,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5696356453690916,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.4994300080152811
    },
    "129": {
        "Time Stamp": 208.79927542846994,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5737559030497917,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0295289908565053
    },
    "130": {
        "Time Stamp": 209.82880441932645,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.576584927885499,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.736365319145449
    },
    "131": {
        "Time Stamp": 211.5651697384719,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5813562559931577,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.5915208928053346
    },
    "132": {
        "Time Stamp": 213.15669063127723,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5857295686169698,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.4718664890939124
    },
    "133": {
        "Time Stamp": 213.62855712037114,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5870262023481618,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.17071830809879884
    },
    "134": {
        "Time Stamp": 213.79927542846994,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5874953162223807,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "135": {
        "Time Stamp": 213.79927542846994,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5874953162223807,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 4.357415202807289
    },
    "136": {
        "Time Stamp": 218.15669063127723,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5994689817895588,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "137": {
        "Time Stamp": 218.15669063127723,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5994689817895588,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.4718664890939124
    },
    "138": {
        "Time Stamp": 218.62855712037114,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6007656155207508,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "139": {
        "Time Stamp": 218.62855712037114,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6007656155207508,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.17071830809879884
    },
    "140": {
        "Time Stamp": 218.79927542846994,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6012347293949697,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.2740889058111406
    },
    "141": {
        "Time Stamp": 220.07336433428108,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6047357761740799,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.083326296996148
    },
    "142": {
        "Time Stamp": 223.15669063127723,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6132083949621477,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.4718664890939124
    },
    "143": {
        "Time Stamp": 223.62855712037114,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6145050286933398,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.2663530777340952
    },
    "144": {
        "Time Stamp": 224.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6179848183248134,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.17845413617584427
    },
    "145": {
        "Time Stamp": 225.07336433428108,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6184751893466689,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.4036958071046115
    },
    "146": {
        "Time Stamp": 225.4770601413857,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6195844980446393,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.610198116868929
    },
    "147": {
        "Time Stamp": 229.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6295048987571523,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.807651939850615
    },
    "148": {
        "Time Stamp": 229.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6317242314974024,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "149": {
        "Time Stamp": 229.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6317242314974024,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5821499432804558
    },
    "150": {
        "Time Stamp": 230.4770601413857,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6333239112172283,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.42041235511791797
    },
    "151": {
        "Time Stamp": 230.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6344791550271937,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.1897857617510113
    },
    "152": {
        "Time Stamp": 234.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6432443119297413,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "153": {
        "Time Stamp": 234.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6432443119297413,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.807651939850615
    },
    "154": {
        "Time Stamp": 234.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6454636446699915,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "155": {
        "Time Stamp": 234.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6454636446699915,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0025622983983737
    },
    "156": {
        "Time Stamp": 235.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6482185681997826,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "157": {
        "Time Stamp": 235.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6482185681997826,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.1897857617510113
    },
    "158": {
        "Time Stamp": 239.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6569837251023304,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "159": {
        "Time Stamp": 239.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6569837251023304,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.807651939850615
    },
    "160": {
        "Time Stamp": 239.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6592030578425805,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "161": {
        "Time Stamp": 239.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6592030578425805,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0025622983983737
    },
    "162": {
        "Time Stamp": 240.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6619579813723716,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "163": {
        "Time Stamp": 240.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6619579813723716,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.1897857617510113
    },
    "164": {
        "Time Stamp": 244.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6707231382749194,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "165": {
        "Time Stamp": 244.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6707231382749194,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.807651939850615
    },
    "166": {
        "Time Stamp": 244.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6729424710151695,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "167": {
        "Time Stamp": 244.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6729424710151695,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0025622983983737
    },
    "168": {
        "Time Stamp": 245.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6756973945449606,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "169": {
        "Time Stamp": 245.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6756973945449606,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.1897857617510113
    },
    "170": {
        "Time Stamp": 249.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6844625514475083,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "171": {
        "Time Stamp": 249.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6844625514475083,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.807651939850615
    },
    "172": {
        "Time Stamp": 249.89491019810524,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6866818841877584,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.0025622983983737
    },
    "173": {
        "Time Stamp": 250.8974724965036,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6894368077175496,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.06527023909012541
    },
    "174": {
        "Time Stamp": 250.96274273559374,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6896161626740962,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.6850436378538802
    },
    "175": {
        "Time Stamp": 251.64778637344762,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6914985821904418,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.4394718848070056
    },
    "176": {
        "Time Stamp": 254.08725825825462,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6982019646200973,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.8754844773391142
    },
    "177": {
        "Time Stamp": 255.96274273559374,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7033555758466852,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.005073601943820449
    },
    "178": {
        "Time Stamp": 255.96781633753756,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.703369517509361,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.6799700359100598
    },
    "179": {
        "Time Stamp": 256.6477863734476,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7052379953630308,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 4.32002996408994
    },
    "180": {
        "Time Stamp": 260.96781633753756,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.71710893068195,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 10.59351857953493
    },
    "181": {
        "Time Stamp": 271.5613349170725,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7462186764250958,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7747295402587042
    },
    "182": {
        "Time Stamp": 272.3360644573312,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7483475422752206,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.9476715700152454
    },
    "183": {
        "Time Stamp": 274.28373602734644,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7536995151602095,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.2775988897260504
    },
    "184": {
        "Time Stamp": 276.5613349170725,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7599580895976847,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "185": {
        "Time Stamp": 276.5613349170725,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7599580895976847,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7747295402587042
    },
    "186": {
        "Time Stamp": 277.3360644573312,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7620869554478097,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "187": {
        "Time Stamp": 277.3360644573312,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7620869554478097,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.9476715700152454
    },
    "188": {
        "Time Stamp": 279.28373602734644,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7674389283327986,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "189": {
        "Time Stamp": 279.28373602734644,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7674389283327986,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.2775988897260504
    },
    "190": {
        "Time Stamp": 281.5613349170725,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7736975027702738,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "191": {
        "Time Stamp": 281.5613349170725,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7736975027702738,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7747295402587042
    },
    "192": {
        "Time Stamp": 282.3360644573312,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7758263686203987,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.8171219122732509
    },
    "193": {
        "Time Stamp": 283.15318636960444,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7780717237334183,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.1305496577419945
    },
    "194": {
        "Time Stamp": 284.28373602734644,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7811783415053876,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "195": {
        "Time Stamp": 284.28373602734644,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7811783415053876,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.2775988897260504
    },
    "196": {
        "Time Stamp": 286.5613349170725,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7874369159428628,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.5918514525319551
    },
    "197": {
        "Time Stamp": 288.15318636960444,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7918111369060072,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.1305496577419945
    },
    "198": {
        "Time Stamp": 289.28373602734644,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7949177546779765,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.5045030893397211
    },
    "199": {
        "Time Stamp": 289.78823911668616,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7963040699562337,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.9989731346728945
    },
    "200": {
        "Time Stamp": 291.78721225135905,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.801797013519869,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.8103254483787623
    },
    "201": {
        "Time Stamp": 294.5975376997378,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8095194580168125,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.1907014169483432
    },
    "202": {
        "Time Stamp": 294.78823911668616,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8100434831288228,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "203": {
        "Time Stamp": 294.78823911668616,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8100434831288228,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.9989731346728945
    },
    "204": {
        "Time Stamp": 296.78721225135905,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.815536426692458,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "205": {
        "Time Stamp": 296.78721225135905,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.815536426692458,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.8103254483787623
    },
    "206": {
        "Time Stamp": 299.5975376997378,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8232588711894014,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "207": {
        "Time Stamp": 299.5975376997378,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8232588711894014,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.1907014169483432
    },
    "208": {
        "Time Stamp": 299.78823911668616,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8237828963014118,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "209": {
        "Time Stamp": 299.78823911668616,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8237828963014118,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.9989731346728945
    },
    "210": {
        "Time Stamp": 301.78721225135905,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.829275839865047,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "211": {
        "Time Stamp": 301.78721225135905,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.829275839865047,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.8103254483787623
    },
    "212": {
        "Time Stamp": 304.5975376997378,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8369982843619904,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "213": {
        "Time Stamp": 304.5975376997378,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8369982843619904,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.1907014169483432
    },
    "214": {
        "Time Stamp": 304.78823911668616,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8375223094740007,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.9989731346728945
    },
    "215": {
        "Time Stamp": 306.78721225135905,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.843015253037636,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 2.8103254483787623
    },
    "216": {
        "Time Stamp": 309.5975376997378,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8507376975345795,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.954628005301629
    },
    "217": {
        "Time Stamp": 313.55216570503944,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8616045511563256,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.0
    },
    "218": {
        "Time Stamp": 318.55216570503944,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8753439643289146,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 27.80678636848387
    },
    "219": {
        "Time Stamp": 346.3589520735233,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9517537497126177,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 5.0
    },
    "220": {
        "Time Stamp": 351.3589520735233,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9654931628852067,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.928147166027486
    },
    "221": {
        "Time Stamp": 353.2870992395508,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9707914849995283,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 4.217718667126292
    },
    "222": {
        "Time Stamp": 357.5048179066771,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9823812808822062,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.7822813328737084
    },
    "223": {
        "Time Stamp": 358.2870992395508,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9845308981721174,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.6294623480515611
    },
    "224": {
        "Time Stamp": 358.91656158760236,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "IDLE",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.986260586827411,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 3.5882563190747305
    },
    "225": {
        "Time Stamp": 362.5048179066771,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9961206940547952,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 1.4117436809252695
    },
    "226": {
        "Time Stamp": 363.91656158760236,
        "Element id": "6010400400",
        "Element": "RESOURCE",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 362.95705781618807,
        "Duration": 0.0
    },
    "227": {
        "Time Stamp": 297.5446585618465,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5248770098000113,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 2.243580554839639
    },
    "228": {
        "Time Stamp": 299.78823911668616,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5288347479713551,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 5.0
    },
    "229": {
        "Time Stamp": 304.78823911668616,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5376548863051595,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 0.0
    },
    "230": {
        "Time Stamp": 304.78823911668616,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5376548863051595,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 176.85415121593257
    },
    "231": {
        "Time Stamp": 481.64239033261873,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8496305020315735,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 1.0
    },
    "232": {
        "Time Stamp": 482.64239033261873,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8513945296983344,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 0.5
    },
    "233": {
        "Time Stamp": 483.14239033261873,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8522765435317148,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 82.74214262723984
    },
    "234": {
        "Time Stamp": 565.8845329598586,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9982359723332391,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 1.0
    },
    "235": {
        "Time Stamp": 566.8845329598586,
        "Element id": "6011240944",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 297.5446585618465,
        "Total Time": 269.33987439801206,
        "Duration": 0.0
    },
    "236": {
        "Time Stamp": 142.16426466550087,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5459781464339789,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 1.7781105128573529
    },
    "237": {
        "Time Stamp": 143.94237517835822,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5528069334308297,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 5.0
    },
    "238": {
        "Time Stamp": 148.94237517835822,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5720093028771394,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 0.0
    },
    "239": {
        "Time Stamp": 148.94237517835822,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5720093028771394,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 0.0
    },
    "240": {
        "Time Stamp": 148.94237517835822,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5720093028771394,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 1.0
    },
    "241": {
        "Time Stamp": 149.94237517835822,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5758497767664013,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 0.5
    },
    "242": {
        "Time Stamp": 150.44237517835822,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5777700137110322,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 108.94215778150036
    },
    "243": {
        "Time Stamp": 259.3845329598586,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9961595261107381,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 1.0
    },
    "244": {
        "Time Stamp": 260.3845329598586,
        "Element id": "6011241424",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 142.16426466550087,
        "Total Time": 118.22026829435771,
        "Duration": 0.0
    },
    "245": {
        "Time Stamp": 213.15669063127723,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5235433491883194,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 0.0
    },
    "246": {
        "Time Stamp": 213.15669063127723,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5235433491883194,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 5.0
    },
    "247": {
        "Time Stamp": 218.15669063127723,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.535824065023178,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 0.0
    },
    "248": {
        "Time Stamp": 218.15669063127723,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.535824065023178,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 78.22784232858135
    },
    "249": {
        "Time Stamp": 296.3845329598586,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7279628454254653,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 1.0
    },
    "250": {
        "Time Stamp": 297.3845329598586,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.730418988592437,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 0.5
    },
    "251": {
        "Time Stamp": 297.8845329598586,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7316470601759228,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 108.25785737276016
    },
    "252": {
        "Time Stamp": 406.14239033261873,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9975438568330283,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 1.0
    },
    "253": {
        "Time Stamp": 407.14239033261873,
        "Element id": "6011241856",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 213.15669063127723,
        "Total Time": 193.9856997013415,
        "Duration": 0.0
    },
    "254": {
        "Time Stamp": 230.8974724965036,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5408897598035385,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 0.0
    },
    "255": {
        "Time Stamp": 230.8974724965036,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5408897598035385,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 5.0
    },
    "256": {
        "Time Stamp": 235.8974724965036,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5526025289810298,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 0.0
    },
    "257": {
        "Time Stamp": 235.8974724965036,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5526025289810298,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 103.74491783611512
    },
    "258": {
        "Time Stamp": 339.64239033261873,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.795630584171473,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 1.0
    },
    "259": {
        "Time Stamp": 340.64239033261873,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7979731380069713,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 0.5
    },
    "260": {
        "Time Stamp": 341.14239033261873,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7991444149247204,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 84.74214262723984
    },
    "261": {
        "Time Stamp": 425.8845329598586,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9976574461645017,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 1.0
    },
    "262": {
        "Time Stamp": 426.8845329598586,
        "Element id": "6011242192",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 230.8974724965036,
        "Total Time": 195.98706046335496,
        "Duration": 0.0
    },
    "263": {
        "Time Stamp": 155.6354520967901,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5798972481028479,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 0.0
    },
    "264": {
        "Time Stamp": 155.6354520967901,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5798972481028479,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 5.0
    },
    "265": {
        "Time Stamp": 160.6354520967901,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5985272337613279,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 0.0
    },
    "266": {
        "Time Stamp": 160.6354520967901,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5985272337613279,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 22.27635749021809
    },
    "267": {
        "Time Stamp": 182.91180958700818,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6815288778745149,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 1.0
    },
    "268": {
        "Time Stamp": 183.91180958700818,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6852548750062109,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 0.5
    },
    "269": {
        "Time Stamp": 184.41180958700818,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6871178735720589,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 82.9727233728504
    },
    "270": {
        "Time Stamp": 267.3845329598586,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.996274002868304,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 1.0
    },
    "271": {
        "Time Stamp": 268.3845329598586,
        "Element id": "6011242240",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 155.6354520967901,
        "Total Time": 112.74908086306849,
        "Duration": 0.0
    },
    "272": {
        "Time Stamp": 128.72841229119192,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5894083330821898,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 0.0
    },
    "273": {
        "Time Stamp": 128.72841229119192,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5894083330821898,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 5.0
    },
    "274": {
        "Time Stamp": 133.72841229119192,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6123018156705134,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 0.0
    },
    "275": {
        "Time Stamp": 133.72841229119192,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6123018156705134,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 0.0
    },
    "276": {
        "Time Stamp": 133.72841229119192,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6123018156705134,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 1.0
    },
    "277": {
        "Time Stamp": 134.72841229119192,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6168805121881781,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 0.5
    },
    "278": {
        "Time Stamp": 135.22841229119192,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6191698604470105,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 82.17435717430433
    },
    "279": {
        "Time Stamp": 217.40276946549625,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9954213034823353,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 1.0
    },
    "280": {
        "Time Stamp": 218.40276946549625,
        "Element id": "6011243392",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 128.72841229119192,
        "Total Time": 89.67435717430433,
        "Duration": 0.0
    },
    "281": {
        "Time Stamp": 153.24543013039607,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.526359043612025,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 0.6969450479621457
    },
    "282": {
        "Time Stamp": 153.94237517835822,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5287528724432231,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 5.0
    },
    "283": {
        "Time Stamp": 158.94237517835822,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5459265996846863,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 0.0
    },
    "284": {
        "Time Stamp": 158.94237517835822,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5459265996846863,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 18.200015154260512
    },
    "285": {
        "Time Stamp": 177.14239033261873,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6084390188946396,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 1.0
    },
    "286": {
        "Time Stamp": 178.14239033261873,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6118737643429322,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 0.5
    },
    "287": {
        "Time Stamp": 178.64239033261873,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6135911370670785,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 111.5
    },
    "288": {
        "Time Stamp": 290.14239033261873,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9965652545517074,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 1.0
    },
    "289": {
        "Time Stamp": 291.14239033261873,
        "Element id": "6011244304",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 153.24543013039607,
        "Total Time": 137.89696020222266,
        "Duration": 0.0
    },
    "290": {
        "Time Stamp": 215.16738388876396,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5452211651855575,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 3.4611732316071766
    },
    "291": {
        "Time Stamp": 218.62855712037114,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5539915692688339,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 5.0
    },
    "292": {
        "Time Stamp": 223.62855712037114,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5666612675133277,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 0.0
    },
    "293": {
        "Time Stamp": 223.62855712037114,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5666612675133277,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 86.75597583948743
    },
    "294": {
        "Time Stamp": 310.3845329598586,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.786495674471907,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 1.0
    },
    "295": {
        "Time Stamp": 311.3845329598586,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7890296141208057,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 0.5
    },
    "296": {
        "Time Stamp": 311.8845329598586,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7902965839452551,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 81.75785737276016
    },
    "297": {
        "Time Stamp": 393.64239033261873,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9974660603511013,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 1.0
    },
    "298": {
        "Time Stamp": 394.64239033261873,
        "Element id": "6011244736",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 215.16738388876396,
        "Total Time": 179.47500644385477,
        "Duration": 0.0
    },
    "299": {
        "Time Stamp": 187.47328235011702,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5404486638549877,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 0.0
    },
    "300": {
        "Time Stamp": 187.47328235011702,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5404486638549877,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 5.0
    },
    "301": {
        "Time Stamp": 192.47328235011702,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5548626821374881,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 0.0
    },
    "302": {
        "Time Stamp": 192.47328235011702,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5548626821374881,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 42.411250609741586
    },
    "303": {
        "Time Stamp": 234.8845329598586,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6771259904719921,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 1.0
    },
    "304": {
        "Time Stamp": 235.8845329598586,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6800087941284921,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 0.5
    },
    "305": {
        "Time Stamp": 236.3845329598586,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6814501959567422,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 109.49999999999997
    },
    "306": {
        "Time Stamp": 345.8845329598586,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9971171963434999,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 1.0
    },
    "307": {
        "Time Stamp": 346.8845329598586,
        "Element id": "6011245072",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 187.47328235011702,
        "Total Time": 159.41125060974156,
        "Duration": 0.0
    },
    "308": {
        "Time Stamp": 197.29984542045466,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5796381043075249,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 0.0
    },
    "309": {
        "Time Stamp": 197.29984542045466,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5796381043075249,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 5.0
    },
    "310": {
        "Time Stamp": 202.29984542045466,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5943273734012815,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 0.0
    },
    "311": {
        "Time Stamp": 202.29984542045466,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5943273734012815,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 51.34254491216407
    },
    "312": {
        "Time Stamp": 253.64239033261873,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7451642650358931,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 1.0
    },
    "313": {
        "Time Stamp": 254.64239033261873,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7481021188546444,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 0.5
    },
    "314": {
        "Time Stamp": 255.14239033261873,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.74957104576402,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 84.24214262723984
    },
    "315": {
        "Time Stamp": 339.3845329598586,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9970621461812487,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 1.0
    },
    "316": {
        "Time Stamp": 340.3845329598586,
        "Element id": "6011245120",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 197.29984542045466,
        "Total Time": 143.08468753940392,
        "Duration": 0.0
    },
    "317": {
        "Time Stamp": 313.55216570503944,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5338107289360092,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 0.0
    },
    "318": {
        "Time Stamp": 313.55216570503944,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5338107289360092,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 5.0
    },
    "319": {
        "Time Stamp": 318.55216570503944,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5423230402405047,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 0.0
    },
    "320": {
        "Time Stamp": 318.55216570503944,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5423230402405047,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 183.83236725481913
    },
    "321": {
        "Time Stamp": 502.3845329598586,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8552907078235769,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 1.0
    },
    "322": {
        "Time Stamp": 503.3845329598586,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.856993170084476,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 0.5
    },
    "323": {
        "Time Stamp": 503.8845329598586,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8578444012149256,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 82.5
    },
    "324": {
        "Time Stamp": 586.3845329598586,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9982975377391009,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 1.0
    },
    "325": {
        "Time Stamp": 587.3845329598586,
        "Element id": "6011246416",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 313.55216570503944,
        "Total Time": 273.83236725481913,
        "Duration": 0.0
    },
    "326": {
        "Time Stamp": 353.2870992395508,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5680911495870163,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 0.0
    },
    "327": {
        "Time Stamp": 353.2870992395508,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5680911495870163,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 5.0
    },
    "328": {
        "Time Stamp": 358.2870992395508,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5761312273426127,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 0.0
    },
    "329": {
        "Time Stamp": 358.2870992395508,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5761312273426127,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 153.09743372030778
    },
    "330": {
        "Time Stamp": 511.3845329598586,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8223142816013201,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 1.0
    },
    "331": {
        "Time Stamp": 512.3845329598586,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8239222971524394,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 0.5
    },
    "332": {
        "Time Stamp": 512.8845329598586,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.824726304927999,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 108.0
    },
    "333": {
        "Time Stamp": 620.8845329598586,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9983919844488808,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 1.0
    },
    "334": {
        "Time Stamp": 621.8845329598586,
        "Element id": "6011246896",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 353.2870992395508,
        "Total Time": 268.5974337203078,
        "Duration": 0.0
    },
    "335": {
        "Time Stamp": 134.5005428456607,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5329191185699673,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 0.0
    },
    "336": {
        "Time Stamp": 134.5005428456607,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5329191185699673,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 5.0
    },
    "337": {
        "Time Stamp": 139.5005428456607,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5527301582615131,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 0.0
    },
    "338": {
        "Time Stamp": 139.5005428456607,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5527301582615131,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 0.0
    },
    "339": {
        "Time Stamp": 139.5005428456607,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5527301582615131,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 1.0
    },
    "340": {
        "Time Stamp": 140.5005428456607,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5566923661998222,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 0.5
    },
    "341": {
        "Time Stamp": 141.0005428456607,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5586734701689768,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 110.3839901141979
    },
    "342": {
        "Time Stamp": 251.3845329598586,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9960377920616909,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 1.0
    },
    "343": {
        "Time Stamp": 252.3845329598586,
        "Element id": "6011247328",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 134.5005428456607,
        "Total Time": 117.8839901141979,
        "Duration": 0.0
    },
    "344": {
        "Time Stamp": 213.73391053370742,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5549477697043979,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 0.06536489476252427
    },
    "345": {
        "Time Stamp": 213.79927542846994,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5551174858831495,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 5.0
    },
    "346": {
        "Time Stamp": 218.79927542846994,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5680996974638636,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 0.0
    },
    "347": {
        "Time Stamp": 218.79927542846994,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5680996974638636,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 83.08525753138863
    },
    "348": {
        "Time Stamp": 301.8845329598586,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7838257759659835,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 1.0
    },
    "349": {
        "Time Stamp": 302.8845329598586,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7864222182821263,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 0.5
    },
    "350": {
        "Time Stamp": 303.3845329598586,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7877204394401977,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 80.75785737276016
    },
    "351": {
        "Time Stamp": 384.14239033261873,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9974035576838572,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 1.0
    },
    "352": {
        "Time Stamp": 385.14239033261873,
        "Element id": "6011247664",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 213.73391053370742,
        "Total Time": 171.40847979891132,
        "Duration": 0.0
    },
    "353": {
        "Time Stamp": 195.53187559025594,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5773992895520319,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 0.0
    },
    "354": {
        "Time Stamp": 195.53187559025594,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5773992895520319,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 5.0
    },
    "355": {
        "Time Stamp": 200.53187559025594,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5921641274540115,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 0.0
    },
    "356": {
        "Time Stamp": 200.53187559025594,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5921641274540115,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 52.35265736960267
    },
    "357": {
        "Time Stamp": 252.8845329598586,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7467598274140231,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 1.0
    },
    "358": {
        "Time Stamp": 253.8845329598586,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.749712794994419,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 0.5
    },
    "359": {
        "Time Stamp": 254.3845329598586,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7511892787846169,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 83.25785737276013
    },
    "360": {
        "Time Stamp": 337.64239033261873,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9970470324196041,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 1.0
    },
    "361": {
        "Time Stamp": 338.64239033261873,
        "Element id": "6011247712",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 195.53187559025594,
        "Total Time": 143.1105147423628,
        "Duration": 0.0
    },
    "362": {
        "Time Stamp": 237.9637248470442,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5354905654840592,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 1.931185351061032
    },
    "363": {
        "Time Stamp": 239.89491019810524,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5398363183350826,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 5.0
    },
    "364": {
        "Time Stamp": 244.89491019810524,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5510878350489905,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 0.0
    },
    "365": {
        "Time Stamp": 244.89491019810524,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5510878350489905,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 116.7474801345135
    },
    "366": {
        "Time Stamp": 361.64239033261873,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8138050798570121,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 1.0
    },
    "367": {
        "Time Stamp": 362.64239033261873,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8160553831997936,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 0.5
    },
    "368": {
        "Time Stamp": 363.14239033261873,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8171805348711845,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 80.24214262723984
    },
    "369": {
        "Time Stamp": 443.3845329598586,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9977496966572185,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 1.0
    },
    "370": {
        "Time Stamp": 444.3845329598586,
        "Element id": "6011249008",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 237.9637248470442,
        "Total Time": 206.42080811281437,
        "Duration": 0.0
    },
    "371": {
        "Time Stamp": 85.81792391687,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4303552738155439,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 0.0
    },
    "372": {
        "Time Stamp": 85.81792391687,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4303552738155439,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 5.0
    },
    "373": {
        "Time Stamp": 90.81792391687,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4554290144849418,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 0.0
    },
    "374": {
        "Time Stamp": 90.81792391687,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4554290144849418,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 0.0
    },
    "375": {
        "Time Stamp": 90.81792391687,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4554290144849418,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 1.0
    },
    "376": {
        "Time Stamp": 91.81792391687,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.46044376261882136,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 0.5
    },
    "377": {
        "Time Stamp": 92.31792391687,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.46295113668576116,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 106.09388567013818
    },
    "378": {
        "Time Stamp": 198.41180958700818,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9949852518661204,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 1.0
    },
    "379": {
        "Time Stamp": 199.41180958700818,
        "Element id": "6011249488",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 85.81792391687,
        "Total Time": 113.59388567013818,
        "Duration": 0.0
    },
    "380": {
        "Time Stamp": 20.65859872824751,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.1894105265618675,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 0.0
    },
    "381": {
        "Time Stamp": 20.65859872824751,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.1894105265618675,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 5.0
    },
    "382": {
        "Time Stamp": 25.65859872824751,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.23525355034423015,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 0.0
    },
    "383": {
        "Time Stamp": 25.65859872824751,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.23525355034423015,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 0.0
    },
    "384": {
        "Time Stamp": 25.65859872824751,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.23525355034423015,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 1.0
    },
    "385": {
        "Time Stamp": 26.65859872824751,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2444221551007027,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 0.5
    },
    "386": {
        "Time Stamp": 27.15859872824751,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.24900645747893896,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 80.9092503677728
    },
    "387": {
        "Time Stamp": 108.06784909602031,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9908313952435275,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 1.0
    },
    "388": {
        "Time Stamp": 109.06784909602031,
        "Element id": "6011249920",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 20.65859872824751,
        "Total Time": 88.4092503677728,
        "Duration": 0.0
    },
    "389": {
        "Time Stamp": 124.00140052602609,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5168819051247362,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 0.0
    },
    "390": {
        "Time Stamp": 124.00140052602609,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5168819051247362,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 5.0
    },
    "391": {
        "Time Stamp": 129.0014005260261,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5377236820293547,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 0.0
    },
    "392": {
        "Time Stamp": 129.0014005260261,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5377236820293547,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 0.0
    },
    "393": {
        "Time Stamp": 129.0014005260261,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5377236820293547,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 1.0
    },
    "394": {
        "Time Stamp": 130.0014005260261,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5418920374102784,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 0.5
    },
    "395": {
        "Time Stamp": 130.5014005260261,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5439762151007402,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 108.40136893947016
    },
    "396": {
        "Time Stamp": 238.90276946549625,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9958316446190763,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 1.0
    },
    "397": {
        "Time Stamp": 239.90276946549625,
        "Element id": "6011250304",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 124.00140052602609,
        "Total Time": 115.90136893947016,
        "Duration": 0.0
    },
    "398": {
        "Time Stamp": 144.3906202048876,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6121241541066159,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 2.512149260608652
    },
    "399": {
        "Time Stamp": 146.90276946549625,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6227740650146624,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 5.0
    },
    "400": {
        "Time Stamp": 151.90276946549625,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6439708765955678,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 0.0
    },
    "401": {
        "Time Stamp": 151.90276946549625,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6439708765955678,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 0.0
    },
    "402": {
        "Time Stamp": 151.90276946549625,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6439708765955678,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 1.0
    },
    "403": {
        "Time Stamp": 152.90276946549625,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6482102389117489,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 0.5
    },
    "404": {
        "Time Stamp": 153.40276946549625,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6503299200698395,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 81.48176349436235
    },
    "405": {
        "Time Stamp": 234.8845329598586,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.995760637683819,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 1.0
    },
    "406": {
        "Time Stamp": 235.8845329598586,
        "Element id": "6011250928",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 144.3906202048876,
        "Total Time": 91.493912754971,
        "Duration": 0.0
    },
    "407": {
        "Time Stamp": 294.5975376997378,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.551283803196952,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 0.0
    },
    "408": {
        "Time Stamp": 294.5975376997378,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.551283803196952,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 5.0
    },
    "409": {
        "Time Stamp": 299.5975376997378,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5606403614272323,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 0.0
    },
    "410": {
        "Time Stamp": 299.5975376997378,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5606403614272323,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 149.78699526012076
    },
    "411": {
        "Time Stamp": 449.3845329598586,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8409385100852368,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 1.0
    },
    "412": {
        "Time Stamp": 450.3845329598586,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8428098217312928,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 0.5
    },
    "413": {
        "Time Stamp": 450.8845329598586,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8437454775543208,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 82.5
    },
    "414": {
        "Time Stamp": 533.3845329598586,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.998128688353944,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 1.0
    },
    "415": {
        "Time Stamp": 534.3845329598586,
        "Element id": "6011251360",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 294.5975376997378,
        "Total Time": 239.78699526012076,
        "Duration": 0.0
    },
    "416": {
        "Time Stamp": 229.08725825825462,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5410855627075413,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 0.0
    },
    "417": {
        "Time Stamp": 229.08725825825462,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5410855627075413,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 5.0
    },
    "418": {
        "Time Stamp": 234.08725825825462,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5528951580298958,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 0.0
    },
    "419": {
        "Time Stamp": 234.08725825825462,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5528951580298958,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 104.05513207436411
    },
    "420": {
        "Time Stamp": 338.14239033261873,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7986649582323743,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 1.0
    },
    "421": {
        "Time Stamp": 339.14239033261873,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8010268772968452,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 0.5
    },
    "422": {
        "Time Stamp": 339.64239033261873,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8022078368290806,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 82.74214262723984
    },
    "423": {
        "Time Stamp": 422.3845329598586,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9976380809355291,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 1.0
    },
    "424": {
        "Time Stamp": 423.3845329598586,
        "Element id": "6011254288",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 229.08725825825462,
        "Total Time": 194.29727470160395,
        "Duration": 0.0
    },
    "425": {
        "Time Stamp": 277.59038774518524,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5110821631417679,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 1.693348282161196
    },
    "426": {
        "Time Stamp": 279.28373602734644,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5141998507174406,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 5.0
    },
    "427": {
        "Time Stamp": 284.28373602734644,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.523405539849784,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 0.0
    },
    "428": {
        "Time Stamp": 284.28373602734644,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.523405539849784,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 147.10079693251214
    },
    "429": {
        "Time Stamp": 431.3845329598586,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7942383813859197,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 1.0
    },
    "430": {
        "Time Stamp": 432.3845329598586,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7960795192123884,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 0.5
    },
    "431": {
        "Time Stamp": 432.8845329598586,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7970000881256227,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 109.25785737276021
    },
    "432": {
        "Time Stamp": 542.1423903326188,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981588621735313,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 1.0
    },
    "433": {
        "Time Stamp": 543.1423903326188,
        "Element id": "6011254336",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 277.59038774518524,
        "Total Time": 265.55200258743355,
        "Duration": 0.0
    },
    "434": {
        "Time Stamp": 144.77378056085357,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.568537627893562,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 4.168594617504652
    },
    "435": {
        "Time Stamp": 148.94237517835822,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.584908015447887,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 5.0
    },
    "436": {
        "Time Stamp": 153.94237517835822,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6045433950618975,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 0.0
    },
    "437": {
        "Time Stamp": 153.94237517835822,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6045433950618975,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 17.480667063915263
    },
    "438": {
        "Time Stamp": 171.42304224227348,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6731913018031187,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 1.0
    },
    "439": {
        "Time Stamp": 172.42304224227348,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6771183777259208,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 0.5
    },
    "440": {
        "Time Stamp": 172.92304224227348,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6790819156873218,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 80.71934809034525
    },
    "441": {
        "Time Stamp": 253.64239033261873,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9960729240771979,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 1.0
    },
    "442": {
        "Time Stamp": 254.64239033261873,
        "Element id": "6011255104",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 144.77378056085357,
        "Total Time": 109.86860977176516,
        "Duration": 0.0
    },
    "443": {
        "Time Stamp": 197.30527286587264,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5334239614914772,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 2.5235315534538074
    },
    "444": {
        "Time Stamp": 199.82880441932645,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5402464461551646,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 5.0
    },
    "445": {
        "Time Stamp": 204.82880441932645,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5537641781889683,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 0.0
    },
    "446": {
        "Time Stamp": 204.82880441932645,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5537641781889683,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 53.05572854053213
    },
    "447": {
        "Time Stamp": 257.8845329598586,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6972028024427972,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 1.0
    },
    "448": {
        "Time Stamp": 258.8845329598586,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6999063488495579,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 0.5
    },
    "449": {
        "Time Stamp": 259.3845329598586,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7012581220529382,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 109.5
    },
    "450": {
        "Time Stamp": 368.8845329598586,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9972964535932393,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 1.0
    },
    "451": {
        "Time Stamp": 369.8845329598586,
        "Element id": "6011255632",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 197.30527286587264,
        "Total Time": 172.57926009398594,
        "Duration": 0.0
    },
    "452": {
        "Time Stamp": 213.62855712037114,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5568429415090307,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 0.0
    },
    "453": {
        "Time Stamp": 213.62855712037114,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5568429415090307,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 5.0
    },
    "454": {
        "Time Stamp": 218.62855712037114,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5698759121243607,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 0.0
    },
    "455": {
        "Time Stamp": 218.62855712037114,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5698759121243607,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 79.25597583948743
    },
    "456": {
        "Time Stamp": 297.8845329598586,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7764640729654303,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 1.0
    },
    "457": {
        "Time Stamp": 298.8845329598586,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7790706670884964,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 0.5
    },
    "458": {
        "Time Stamp": 299.3845329598586,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7803739641500294,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 83.25785737276016
    },
    "459": {
        "Time Stamp": 382.64239033261873,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.997393405876934,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 1.0
    },
    "460": {
        "Time Stamp": 383.64239033261873,
        "Element id": "6011256112",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 213.62855712037114,
        "Total Time": 170.0138332122476,
        "Duration": 0.0
    },
    "461": {
        "Time Stamp": 108.58264655476665,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5515041985390737,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 0.0
    },
    "462": {
        "Time Stamp": 108.58264655476665,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5515041985390737,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 5.0
    },
    "463": {
        "Time Stamp": 113.58264655476665,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5768997942460224,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 0.0
    },
    "464": {
        "Time Stamp": 113.58264655476665,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5768997942460224,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 0.0
    },
    "465": {
        "Time Stamp": 113.58264655476665,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5768997942460224,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 1.0
    },
    "466": {
        "Time Stamp": 114.58264655476665,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5819789133874123,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 0.5
    },
    "467": {
        "Time Stamp": 115.08264655476665,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5845184729581071,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 80.80188640509195
    },
    "468": {
        "Time Stamp": 195.8845329598586,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9949208808586102,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 1.0
    },
    "469": {
        "Time Stamp": 196.8845329598586,
        "Element id": "6011256640",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 108.58264655476665,
        "Total Time": 88.30188640509195,
        "Duration": 0.0
    },
    "470": {
        "Time Stamp": 133.94237517835822,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5365369839629238,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 0.0
    },
    "471": {
        "Time Stamp": 133.94237517835822,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5365369839629238,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 5.0
    },
    "472": {
        "Time Stamp": 138.94237517835822,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5565656337180319,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 0.0
    },
    "473": {
        "Time Stamp": 138.94237517835822,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5565656337180319,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 0.0
    },
    "474": {
        "Time Stamp": 138.94237517835822,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5565656337180319,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 1.0
    },
    "475": {
        "Time Stamp": 139.94237517835822,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5605713636690535,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 0.5
    },
    "476": {
        "Time Stamp": 140.44237517835822,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5625742286445643,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 108.20001515426051
    },
    "477": {
        "Time Stamp": 248.64239033261873,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9959942700489783,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 1.0
    },
    "478": {
        "Time Stamp": 249.64239033261873,
        "Element id": "6011289808",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 133.94237517835822,
        "Total Time": 115.70001515426051,
        "Duration": 0.0
    },
    "479": {
        "Time Stamp": 48.11283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.35478083487700723,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "480": {
        "Time Stamp": 48.11283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.35478083487700723,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 5.0
    },
    "481": {
        "Time Stamp": 53.11283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.391650501455464,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "482": {
        "Time Stamp": 53.11283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.391650501455464,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "483": {
        "Time Stamp": 53.11283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.391650501455464,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "484": {
        "Time Stamp": 54.11283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3990244347711553,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 0.5
    },
    "485": {
        "Time Stamp": 54.61283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.40271140142900097,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 80.0
    },
    "486": {
        "Time Stamp": 134.61283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9926260666843086,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "487": {
        "Time Stamp": 135.61283472309847,
        "Element id": "6011289952",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 48.11283472309847,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "488": {
        "Time Stamp": 55.41180958700817,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.38773429394770986,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "489": {
        "Time Stamp": 55.41180958700817,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.38773429394770986,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 5.0
    },
    "490": {
        "Time Stamp": 60.41180958700817,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.42272090572212645,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "491": {
        "Time Stamp": 60.41180958700817,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.42272090572212645,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "492": {
        "Time Stamp": 60.41180958700817,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.42272090572212645,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "493": {
        "Time Stamp": 61.41180958700817,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.42971822807700977,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 0.5
    },
    "494": {
        "Time Stamp": 61.91180958700817,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4332168892544514,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 80.0
    },
    "495": {
        "Time Stamp": 141.91180958700818,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9930026776451167,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "496": {
        "Time Stamp": 142.91180958700818,
        "Element id": "6011290576",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 55.41180958700817,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "497": {
        "Time Stamp": 255.96781633753756,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4954448592047263,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 0.0
    },
    "498": {
        "Time Stamp": 255.96781633753756,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4954448592047263,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 5.0
    },
    "499": {
        "Time Stamp": 260.96781633753756,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5051227332885407,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 0.0
    },
    "500": {
        "Time Stamp": 260.96781633753756,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5051227332885407,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 145.17457399508118
    },
    "501": {
        "Time Stamp": 406.14239033261873,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7861189827477005,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 1.0
    },
    "502": {
        "Time Stamp": 407.14239033261873,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7880545575644634,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 0.5
    },
    "503": {
        "Time Stamp": 407.64239033261873,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7890223449728448,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 108.00000000000006
    },
    "504": {
        "Time Stamp": 515.6423903326188,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9980644251832371,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 1.0
    },
    "505": {
        "Time Stamp": 516.6423903326188,
        "Element id": "6011291344",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 255.96781633753756,
        "Total Time": 260.67457399508123,
        "Duration": 0.0
    },
    "506": {
        "Time Stamp": 272.3360644573312,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.510581105428917,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 0.0
    },
    "507": {
        "Time Stamp": 272.3360644573312,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.510581105428917,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 5.0
    },
    "508": {
        "Time Stamp": 277.3360644573312,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5199552055218724,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 0.0
    },
    "509": {
        "Time Stamp": 277.3360644573312,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5199552055218724,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 145.04846850252738
    },
    "510": {
        "Time Stamp": 422.3845329598586,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7918949779363894,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 1.0
    },
    "511": {
        "Time Stamp": 423.3845329598586,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7937697979549805,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 0.5
    },
    "512": {
        "Time Stamp": 423.8845329598586,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7947072079642761,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 108.5
    },
    "513": {
        "Time Stamp": 532.3845329598586,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981251799814089,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 1.0
    },
    "514": {
        "Time Stamp": 533.3845329598586,
        "Element id": "6011291776",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 272.3360644573312,
        "Total Time": 261.0484685025274,
        "Duration": 0.0
    },
    "515": {
        "Time Stamp": 206.5651697384719,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5696112071978362,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 0.0
    },
    "516": {
        "Time Stamp": 206.5651697384719,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5696112071978362,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 5.0
    },
    "517": {
        "Time Stamp": 211.5651697384719,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5833988948297592,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 0.0
    },
    "518": {
        "Time Stamp": 211.5651697384719,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5833988948297592,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 67.31936322138668
    },
    "519": {
        "Time Stamp": 278.8845329598586,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7690345651650466,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 1.0
    },
    "520": {
        "Time Stamp": 279.8845329598586,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7717921026914313,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 0.5
    },
    "521": {
        "Time Stamp": 280.3845329598586,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7731708714546236,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 81.25785737276016
    },
    "522": {
        "Time Stamp": 361.64239033261873,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9972424624736155,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 1.0
    },
    "523": {
        "Time Stamp": 362.64239033261873,
        "Element id": "6011292400",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 206.5651697384719,
        "Total Time": 156.07722059414684,
        "Duration": 0.0
    },
    "524": {
        "Time Stamp": 235.8610389588693,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5442304028434616,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 0.03643353763430923
    },
    "525": {
        "Time Stamp": 235.8974724965036,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5443144703051809,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 5.0
    },
    "526": {
        "Time Stamp": 240.8974724965036,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5558515687010369,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 0.0
    },
    "527": {
        "Time Stamp": 240.8974724965036,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5558515687010369,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 106.74491783611512
    },
    "528": {
        "Time Stamp": 347.64239033261873,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8021568927676024,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 1.0
    },
    "529": {
        "Time Stamp": 348.64239033261873,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8044643124467736,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 0.5
    },
    "530": {
        "Time Stamp": 349.14239033261873,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8056180222863593,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 83.24214262723984
    },
    "531": {
        "Time Stamp": 432.3845329598586,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9976925803208287,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 1.0
    },
    "532": {
        "Time Stamp": 433.3845329598586,
        "Element id": "6011292544",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 235.8610389588693,
        "Total Time": 197.52349400098927,
        "Duration": 0.0
    },
    "533": {
        "Time Stamp": 182.4306426713061,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.535548856656309,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 0.0
    },
    "534": {
        "Time Stamp": 182.4306426713061,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.535548856656309,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 5.0
    },
    "535": {
        "Time Stamp": 187.4306426713061,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5502270063578707,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 0.0
    },
    "536": {
        "Time Stamp": 187.4306426713061,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5502270063578707,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 43.453890288552515
    },
    "537": {
        "Time Stamp": 230.8845329598586,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6777915477119933,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 1.0
    },
    "538": {
        "Time Stamp": 231.8845329598586,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6807271776523057,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 0.5
    },
    "539": {
        "Time Stamp": 232.3845329598586,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6821949926224619,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 107.25785737276013
    },
    "540": {
        "Time Stamp": 339.64239033261873,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9970643700596876,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 1.0
    },
    "541": {
        "Time Stamp": 340.64239033261873,
        "Element id": "6011293168",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 182.4306426713061,
        "Total Time": 158.21174766131264,
        "Duration": 0.0
    },
    "542": {
        "Time Stamp": 283.92037173939366,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5149226261667071,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 0.363364287952777
    },
    "543": {
        "Time Stamp": 284.28373602734644,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5155816295776339,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 5.0
    },
    "544": {
        "Time Stamp": 289.28373602734644,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5246497112903358,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 0.0
    },
    "545": {
        "Time Stamp": 289.28373602734644,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5246497112903358,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 152.10079693251214
    },
    "546": {
        "Time Stamp": 441.3845329598586,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.800502202320557,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 1.0
    },
    "547": {
        "Time Stamp": 442.3845329598586,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8023158186630974,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 0.5
    },
    "548": {
        "Time Stamp": 442.8845329598586,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8032226268343676,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 107.5
    },
    "549": {
        "Time Stamp": 550.3845329598586,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981863836574596,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 1.0
    },
    "550": {
        "Time Stamp": 551.3845329598586,
        "Element id": "6011293936",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 283.92037173939366,
        "Total Time": 267.4641612204649,
        "Duration": 0.0
    },
    "551": {
        "Time Stamp": 276.8623948169398,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5099839431922281,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 0.4736696403913925
    },
    "552": {
        "Time Stamp": 277.3360644573312,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5108564485071408,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 5.0
    },
    "553": {
        "Time Stamp": 282.3360644573312,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.520066510125105,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 0.0
    },
    "554": {
        "Time Stamp": 282.3360644573312,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.520066510125105,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 145.04846850252738
    },
    "555": {
        "Time Stamp": 427.3845329598586,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7872475766250275,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 1.0
    },
    "556": {
        "Time Stamp": 428.3845329598586,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7890895889486205,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 0.5
    },
    "557": {
        "Time Stamp": 428.8845329598586,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7900105951104168,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 113.0
    },
    "558": {
        "Time Stamp": 541.8845329598586,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981579876764072,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 1.0
    },
    "559": {
        "Time Stamp": 542.8845329598586,
        "Element id": "6011294368",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 276.8623948169398,
        "Total Time": 266.0221381429188,
        "Duration": 0.0
    },
    "560": {
        "Time Stamp": 1.7019219068112634,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.019079430918419578,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "561": {
        "Time Stamp": 1.7019219068112634,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.019079430918419578,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 5.0
    },
    "562": {
        "Time Stamp": 6.701921906811263,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.07513203486593845,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "563": {
        "Time Stamp": 6.701921906811263,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.07513203486593845,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "564": {
        "Time Stamp": 6.701921906811263,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.07513203486593845,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "565": {
        "Time Stamp": 7.701921906811263,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.08634255565544223,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 0.5
    },
    "566": {
        "Time Stamp": 8.201921906811263,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.09194781605019411,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 80.0
    },
    "567": {
        "Time Stamp": 88.20192190681126,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9887894792104962,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "568": {
        "Time Stamp": 89.20192190681126,
        "Element id": "6011294992",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 1.7019219068112634,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "569": {
        "Time Stamp": 283.15318636960444,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5154007245964701,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 0.0
    },
    "570": {
        "Time Stamp": 283.15318636960444,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5154007245964701,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 5.0
    },
    "571": {
        "Time Stamp": 288.15318636960444,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5245018180929726,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 0.0
    },
    "572": {
        "Time Stamp": 288.15318636960444,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5245018180929726,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 148.23134659025413
    },
    "573": {
        "Time Stamp": 436.3845329598586,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7943152869790449,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 1.0
    },
    "574": {
        "Time Stamp": 437.3845329598586,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7961355056783453,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 0.5
    },
    "575": {
        "Time Stamp": 437.8845329598586,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7970456150279955,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 110.5
    },
    "576": {
        "Time Stamp": 548.3845329598586,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981797813006995,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 1.0
    },
    "577": {
        "Time Stamp": 549.3845329598586,
        "Element id": "6011295136",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 283.15318636960444,
        "Total Time": 266.23134659025413,
        "Duration": 0.0
    },
    "578": {
        "Time Stamp": 0.9595037714143184,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.010846813858392703,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "579": {
        "Time Stamp": 0.9595037714143184,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.010846813858392703,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 5.0
    },
    "580": {
        "Time Stamp": 5.959503771414319,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.06736985306648455,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "581": {
        "Time Stamp": 5.959503771414319,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.06736985306648455,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "582": {
        "Time Stamp": 5.959503771414319,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.06736985306648455,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "583": {
        "Time Stamp": 6.959503771414319,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.07867446090810293,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 0.5
    },
    "584": {
        "Time Stamp": 7.459503771414319,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.0843267648289121,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 80.0
    },
    "585": {
        "Time Stamp": 87.45950377141432,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9886953921583816,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "586": {
        "Time Stamp": 88.45950377141432,
        "Element id": "6011295760",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 0.9595037714143184,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "587": {
        "Time Stamp": 225.4770601413857,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5006323344622534,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 0.0
    },
    "588": {
        "Time Stamp": 225.4770601413857,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5006323344622534,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 5.0
    },
    "589": {
        "Time Stamp": 230.4770601413857,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5117339590387919,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 0.0
    },
    "590": {
        "Time Stamp": 230.4770601413857,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5117339590387919,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 107.16533019123304
    },
    "591": {
        "Time Stamp": 337.64239033261873,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7496758117195641,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 1.0
    },
    "592": {
        "Time Stamp": 338.64239033261873,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7518961366348718,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 0.5
    },
    "593": {
        "Time Stamp": 339.14239033261873,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7530062990925256,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 110.24214262723984
    },
    "594": {
        "Time Stamp": 449.3845329598586,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9977796750846923,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 1.0
    },
    "595": {
        "Time Stamp": 450.3845329598586,
        "Element id": "6011296528",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 225.4770601413857,
        "Total Time": 224.90747281847288,
        "Duration": 0.0
    },
    "596": {
        "Time Stamp": 150.7655233957247,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5817864194360932,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 1.1372460697715496
    },
    "597": {
        "Time Stamp": 151.90276946549625,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5861749182390558,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 5.0
    },
    "598": {
        "Time Stamp": 156.90276946549625,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6054693300625413,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 0.0
    },
    "599": {
        "Time Stamp": 156.90276946549625,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6054693300625413,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 18.981763494362355
    },
    "600": {
        "Time Stamp": 175.8845329598586,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6787177224617879,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 1.0
    },
    "601": {
        "Time Stamp": 176.8845329598586,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.682576604826485,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 0.5
    },
    "602": {
        "Time Stamp": 177.3845329598586,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6845060460088335,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 80.75785737276013
    },
    "603": {
        "Time Stamp": 258.14239033261873,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9961411176353029,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 1.0
    },
    "604": {
        "Time Stamp": 259.14239033261873,
        "Element id": "6011296960",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 150.7655233957247,
        "Total Time": 108.37686693689403,
        "Duration": 0.0
    },
    "605": {
        "Time Stamp": 170.479036776743,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5628515761791529,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 0.0
    },
    "606": {
        "Time Stamp": 170.479036776743,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5628515761791529,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 5.0
    },
    "607": {
        "Time Stamp": 175.479036776743,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5793595171794371,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 0.0
    },
    "608": {
        "Time Stamp": 175.479036776743,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5793595171794371,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 41.92373268875326
    },
    "609": {
        "Time Stamp": 217.40276946549625,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7177744183269626,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 1.0
    },
    "610": {
        "Time Stamp": 218.40276946549625,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7210760065270195,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 0.5
    },
    "611": {
        "Time Stamp": 218.90276946549625,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.722726800627048,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 82.98176349436233
    },
    "612": {
        "Time Stamp": 301.8845329598586,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9966984117999431,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 1.0
    },
    "613": {
        "Time Stamp": 302.8845329598586,
        "Element id": "6011297536",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 170.479036776743,
        "Total Time": 132.4054961831156,
        "Duration": 0.0
    },
    "614": {
        "Time Stamp": 183.96494980344448,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5778074333360864,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 0.0
    },
    "615": {
        "Time Stamp": 183.96494980344448,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5778074333360864,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 5.0
    },
    "616": {
        "Time Stamp": 188.96494980344448,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5935117137969416,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 0.0
    },
    "617": {
        "Time Stamp": 188.96494980344448,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5935117137969416,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 43.41958315641412
    },
    "618": {
        "Time Stamp": 232.3845329598586,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7298863760732914,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 1.0
    },
    "619": {
        "Time Stamp": 233.3845329598586,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7330272321654625,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 0.5
    },
    "620": {
        "Time Stamp": 233.8845329598586,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.734597660211548,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 83.49999999999997
    },
    "621": {
        "Time Stamp": 317.3845329598586,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9968591439078289,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 1.0
    },
    "622": {
        "Time Stamp": 318.3845329598586,
        "Element id": "6011298592",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 183.96494980344448,
        "Total Time": 134.4195831564141,
        "Duration": 0.0
    },
    "623": {
        "Time Stamp": 295.7462532478204,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5240155177478174,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 3.8512844519174223
    },
    "624": {
        "Time Stamp": 299.5975376997378,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5308393837948187,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 5.0
    },
    "625": {
        "Time Stamp": 304.5975376997378,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5396985918489055,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 0.0
    },
    "626": {
        "Time Stamp": 304.5975376997378,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5396985918489055,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 175.54485263288092
    },
    "627": {
        "Time Stamp": 480.14239033261873,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8507362663086454,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 1.0
    },
    "628": {
        "Time Stamp": 481.14239033261873,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8525081079194629,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 0.5
    },
    "629": {
        "Time Stamp": 481.64239033261873,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8533940287248715,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 81.74214262723984
    },
    "630": {
        "Time Stamp": 563.3845329598586,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9982281583891827,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 1.0
    },
    "631": {
        "Time Stamp": 564.3845329598586,
        "Element id": "6011298832",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 295.7462532478204,
        "Total Time": 268.6382797120382,
        "Duration": 0.0
    },
    "632": {
        "Time Stamp": 208.79927542846994,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.522148916045797,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 0.0
    },
    "633": {
        "Time Stamp": 208.79927542846994,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.522148916045797,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 5.0
    },
    "634": {
        "Time Stamp": 213.79927542846994,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5346525254327145,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 0.0
    },
    "635": {
        "Time Stamp": 213.79927542846994,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5346525254327145,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 76.34311490414879
    },
    "636": {
        "Time Stamp": 290.14239033261873,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7255654230611214,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 1.0
    },
    "637": {
        "Time Stamp": 291.14239033261873,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7280661449385049,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 0.5
    },
    "638": {
        "Time Stamp": 291.64239033261873,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7293165058771967,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 107.24214262723984
    },
    "639": {
        "Time Stamp": 398.8845329598586,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9974992781226165,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 1.0
    },
    "640": {
        "Time Stamp": 399.8845329598586,
        "Element id": "6011299456",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 208.79927542846994,
        "Total Time": 191.08525753138863,
        "Duration": 0.0
    },
    "641": {
        "Time Stamp": 198.53144176315567,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5768874958161129,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 2.0004338271002666
    },
    "642": {
        "Time Stamp": 200.53187559025594,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5827003043607586,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 5.0
    },
    "643": {
        "Time Stamp": 205.53187559025594,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5972291742136339,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 0.0
    },
    "644": {
        "Time Stamp": 205.53187559025594,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5972291742136339,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 52.610514742362795
    },
    "645": {
        "Time Stamp": 258.14239033261873,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7501034385305463,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 1.0
    },
    "646": {
        "Time Stamp": 259.14239033261873,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7530092125011213,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 0.5
    },
    "647": {
        "Time Stamp": 259.64239033261873,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7544620994864089,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 83.5
    },
    "648": {
        "Time Stamp": 343.14239033261873,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.997094226029425,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 1.0
    },
    "649": {
        "Time Stamp": 344.14239033261873,
        "Element id": "6011421408",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 198.53144176315567,
        "Total Time": 145.61094856946306,
        "Duration": 0.0
    },
    "650": {
        "Time Stamp": 136.190207130048,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5364257741198472,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 0.0
    },
    "651": {
        "Time Stamp": 136.190207130048,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5364257741198472,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 5.0
    },
    "652": {
        "Time Stamp": 141.190207130048,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5561197662733217,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 0.0
    },
    "653": {
        "Time Stamp": 141.190207130048,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5561197662733217,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 0.0
    },
    "654": {
        "Time Stamp": 141.190207130048,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5561197662733217,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 1.0
    },
    "655": {
        "Time Stamp": 142.190207130048,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5600585647040166,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 0.5
    },
    "656": {
        "Time Stamp": 142.690207130048,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.562027963919364,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 110.1943258298106
    },
    "657": {
        "Time Stamp": 252.8845329598586,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 15,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9960612015693051,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 1.0
    },
    "658": {
        "Time Stamp": 253.8845329598586,
        "Element id": "6011421936",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 136.190207130048,
        "Total Time": 117.6943258298106,
        "Duration": 0.0
    },
    "659": {
        "Time Stamp": 291.78721225135905,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.522556043421667,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 0.0
    },
    "660": {
        "Time Stamp": 291.78721225135905,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.522556043421667,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 5.0
    },
    "661": {
        "Time Stamp": 296.78721225135905,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5315104461761563,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 0.0
    },
    "662": {
        "Time Stamp": 296.78721225135905,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5315104461761563,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 151.09732070849952
    },
    "663": {
        "Time Stamp": 447.8845329598586,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8021076991257856,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 1.0
    },
    "664": {
        "Time Stamp": 448.8845329598586,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8038985796766835,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 0.5
    },
    "665": {
        "Time Stamp": 449.3845329598586,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8047940199521324,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 108.0
    },
    "666": {
        "Time Stamp": 557.3845329598586,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9982091194491022,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 1.0
    },
    "667": {
        "Time Stamp": 558.3845329598586,
        "Element id": "6011422416",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 291.78721225135905,
        "Total Time": 266.5973207084995,
        "Duration": 0.0
    },
    "668": {
        "Time Stamp": 90.64239033261872,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5088198837086203,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 0.0
    },
    "669": {
        "Time Stamp": 90.64239033261872,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5088198837086203,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 5.0
    },
    "670": {
        "Time Stamp": 95.64239033261872,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5368873189252706,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 0.0
    },
    "671": {
        "Time Stamp": 95.64239033261872,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5368873189252706,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 0.0
    },
    "672": {
        "Time Stamp": 95.64239033261872,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5368873189252706,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 1.0
    },
    "673": {
        "Time Stamp": 96.64239033261872,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5425008059686006,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 0.5
    },
    "674": {
        "Time Stamp": 97.14239033261872,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5453075494902657,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 80.00000000000001
    },
    "675": {
        "Time Stamp": 177.14239033261873,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9943865129566699,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 1.0
    },
    "676": {
        "Time Stamp": 178.14239033261873,
        "Element id": "6011423184",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 90.64239033261872,
        "Total Time": 87.50000000000001,
        "Duration": 0.0
    },
    "677": {
        "Time Stamp": 172.8784303642614,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5551927345940064,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 0.8616935224138444
    },
    "678": {
        "Time Stamp": 173.74012388667524,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5579600317176722,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 5.0
    },
    "679": {
        "Time Stamp": 178.74012388667524,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5740173482210728,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 0.0
    },
    "680": {
        "Time Stamp": 178.74012388667524,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5740173482210728,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 48.162645578821014
    },
    "681": {
        "Time Stamp": 226.90276946549625,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7286899169611193,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 1.0
    },
    "682": {
        "Time Stamp": 227.90276946549625,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7319013802617994,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 0.5
    },
    "683": {
        "Time Stamp": 228.40276946549625,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7335071119121395,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 81.98176349436233
    },
    "684": {
        "Time Stamp": 310.3845329598586,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 16,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9967885366993199,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 1.0
    },
    "685": {
        "Time Stamp": 311.3845329598586,
        "Element id": "6011423232",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 172.8784303642614,
        "Total Time": 138.50610259559718,
        "Duration": 0.0
    },
    "686": {
        "Time Stamp": 200.65774385773952,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5413782910197265,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 1.6421015627151405
    },
    "687": {
        "Time Stamp": 202.29984542045466,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5458087112996127,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 5.0
    },
    "688": {
        "Time Stamp": 207.29984542045466,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5592988034488484,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 0.0
    },
    "689": {
        "Time Stamp": 207.29984542045466,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5592988034488484,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 52.084687539403916
    },
    "690": {
        "Time Stamp": 259.3845329598586,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6998242503429895,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 1.0
    },
    "691": {
        "Time Stamp": 260.3845329598586,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7025222687728365,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 0.5
    },
    "692": {
        "Time Stamp": 260.8845329598586,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7038712779877602,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 108.75785737276016
    },
    "693": {
        "Time Stamp": 369.64239033261873,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9973019815701528,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 1.0
    },
    "694": {
        "Time Stamp": 370.64239033261873,
        "Element id": "6011424000",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 200.65774385773952,
        "Total Time": 169.9846464748792,
        "Duration": 0.0
    },
    "695": {
        "Time Stamp": 299.69504088965505,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5119626912148121,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 4.902496810082766
    },
    "696": {
        "Time Stamp": 304.5975376997378,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5203375226872025,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 5.0
    },
    "697": {
        "Time Stamp": 309.5975376997378,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5288789167939423,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 0.0
    },
    "698": {
        "Time Stamp": 309.5975376997378,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5288789167939423,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 190.54485263288092
    },
    "699": {
        "Time Stamp": 500.14239033261873,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8543826530635631,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 1.0
    },
    "700": {
        "Time Stamp": 501.14239033261873,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.856090931884911,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 0.5
    },
    "701": {
        "Time Stamp": 501.64239033261873,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.856945071295585,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 82.74214262723984
    },
    "702": {
        "Time Stamp": 584.3845329598586,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9982917211786521,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 1.0
    },
    "703": {
        "Time Stamp": 585.3845329598586,
        "Element id": "6011424528",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 299.69504088965505,
        "Total Time": 285.6894920702035,
        "Duration": 0.0
    },
    "704": {
        "Time Stamp": 275.39220169925704,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5374717306715265,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 1.1691332178154425
    },
    "705": {
        "Time Stamp": 276.5613349170725,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5397534803001928,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 5.0
    },
    "706": {
        "Time Stamp": 281.5613349170725,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.549511776420329,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 0.0
    },
    "707": {
        "Time Stamp": 281.5613349170725,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.549511776420329,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 145.8231980427861
    },
    "708": {
        "Time Stamp": 427.3845329598586,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8341089659576841,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 1.0
    },
    "709": {
        "Time Stamp": 428.3845329598586,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8360606251817114,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 0.5
    },
    "710": {
        "Time Stamp": 428.8845329598586,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.837036454793725,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 82.5
    },
    "711": {
        "Time Stamp": 511.3845329598586,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9980483407759727,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 1.0
    },
    "712": {
        "Time Stamp": 512.3845329598586,
        "Element id": "6011454128",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 275.39220169925704,
        "Total Time": 236.99233126060153,
        "Duration": 0.0
    },
    "713": {
        "Time Stamp": 298.69594283040874,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5193425973308378,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 3.0912694209503115
    },
    "714": {
        "Time Stamp": 301.78721225135905,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5247173870749262,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 5.0
    },
    "715": {
        "Time Stamp": 306.78721225135905,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5334108864309872,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 0.0
    },
    "716": {
        "Time Stamp": 306.78721225135905,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5334108864309872,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 185.59732070849952
    },
    "717": {
        "Time Stamp": 492.3845329598586,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8561089240441844,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 1.0
    },
    "718": {
        "Time Stamp": 493.3845329598586,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8578476239153966,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 0.5
    },
    "719": {
        "Time Stamp": 493.8845329598586,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8587169738510027,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 80.25785737276021
    },
    "720": {
        "Time Stamp": 574.1423903326188,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9982613001287878,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 1.0
    },
    "721": {
        "Time Stamp": 575.1423903326188,
        "Element id": "6011456096",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 298.69594283040874,
        "Total Time": 276.44644750221005,
        "Duration": 0.0
    },
    "722": {
        "Time Stamp": 346.3589520735233,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5856746884129841,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 0.0
    },
    "723": {
        "Time Stamp": 346.3589520735233,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5856746884129841,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 5.0
    },
    "724": {
        "Time Stamp": 351.3589520735233,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5941294242427753,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 0.0
    },
    "725": {
        "Time Stamp": 351.3589520735233,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5941294242427753,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 156.02558088633526
    },
    "726": {
        "Time Stamp": 507.3845329598586,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8579604380595093,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 1.0
    },
    "727": {
        "Time Stamp": 508.3845329598586,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8596513852254675,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 0.5
    },
    "728": {
        "Time Stamp": 508.8845329598586,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8604968588084466,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 81.5
    },
    "729": {
        "Time Stamp": 590.3845329598586,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9983090528340418,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 1.0
    },
    "730": {
        "Time Stamp": 591.3845329598586,
        "Element id": "6011456288",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 346.3589520735233,
        "Total Time": 245.02558088633526,
        "Duration": 0.0
    },
    "731": {
        "Time Stamp": 60.38453295985861,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3434492434821921,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 0.0
    },
    "732": {
        "Time Stamp": 60.38453295985861,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3434492434821921,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 4.999999999999993
    },
    "733": {
        "Time Stamp": 65.3845329598586,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3718877546908905,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 0.0
    },
    "734": {
        "Time Stamp": 65.3845329598586,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3718877546908905,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 0.0
    },
    "735": {
        "Time Stamp": 65.3845329598586,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3718877546908905,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 1.0
    },
    "736": {
        "Time Stamp": 66.3845329598586,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3775754569326302,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 0.5
    },
    "737": {
        "Time Stamp": 66.8845329598586,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.38041930805350005,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 107.9333909570114
    },
    "738": {
        "Time Stamp": 174.81792391687,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9943122977582604,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 1.0
    },
    "739": {
        "Time Stamp": 175.81792391687,
        "Element id": "6011457104",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 60.38453295985861,
        "Total Time": 115.4333909570114,
        "Duration": 0.0
    },
    "740": {
        "Time Stamp": 274.28373602734644,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.511356534674487,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 0.0
    },
    "741": {
        "Time Stamp": 274.28373602734644,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.511356534674487,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 5.0
    },
    "742": {
        "Time Stamp": 279.28373602734644,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5206782054027781,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 0.0
    },
    "743": {
        "Time Stamp": 279.28373602734644,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5206782054027781,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 146.60079693251214
    },
    "744": {
        "Time Stamp": 425.8845329598586,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.793991076904767,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 1.0
    },
    "745": {
        "Time Stamp": 426.8845329598586,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7958554110504251,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 0.5
    },
    "746": {
        "Time Stamp": 427.3845329598586,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7967875781232543,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 108.0
    },
    "747": {
        "Time Stamp": 535.3845329598586,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981356658543418,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 1.0
    },
    "748": {
        "Time Stamp": 536.3845329598586,
        "Element id": "6011457440",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 274.28373602734644,
        "Total Time": 262.10079693251214,
        "Duration": 0.0
    },
    "749": {
        "Time Stamp": 358.91656158760236,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5725401453007055,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 0.0
    },
    "750": {
        "Time Stamp": 358.91656158760236,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5725401453007055,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 5.0
    },
    "751": {
        "Time Stamp": 363.91656158760236,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5805160957941597,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 0.0
    },
    "752": {
        "Time Stamp": 363.91656158760236,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5805160957941597,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 152.46797137225622
    },
    "753": {
        "Time Stamp": 516.3845329598586,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8237314940946618,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 1.0
    },
    "754": {
        "Time Stamp": 517.3845329598586,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8253266841933526,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 0.5
    },
    "755": {
        "Time Stamp": 517.8845329598586,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.826124279242698,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 108.0
    },
    "756": {
        "Time Stamp": 625.8845329598586,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9984048099013092,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 1.0
    },
    "757": {
        "Time Stamp": 626.8845329598586,
        "Element id": "6011457872",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 358.91656158760236,
        "Total Time": 267.9679713722562,
        "Duration": 0.0
    },
    "758": {
        "Time Stamp": 245.33613841477631,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5271686552503833,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 0.5613340817272956
    },
    "759": {
        "Time Stamp": 245.8974724965036,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5283748278710271,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 5.0
    },
    "760": {
        "Time Stamp": 250.8974724965036,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.539118631426766,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 0.0
    },
    "761": {
        "Time Stamp": 250.8974724965036,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.539118631426766,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 131.74491783611512
    },
    "762": {
        "Time Stamp": 382.64239033261873,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8222069347664016,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 1.0
    },
    "763": {
        "Time Stamp": 383.64239033261873,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8243556954775494,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 0.5
    },
    "764": {
        "Time Stamp": 384.14239033261873,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8254300758331233,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 80.24214262723984
    },
    "765": {
        "Time Stamp": 464.3845329598586,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9978512392888522,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 1.0
    },
    "766": {
        "Time Stamp": 465.3845329598586,
        "Element id": "6011458256",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 245.33613841477631,
        "Total Time": 220.04839454508226,
        "Duration": 0.0
    },
    "767": {
        "Time Stamp": 31.406083643695194,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2641251202739487,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "768": {
        "Time Stamp": 31.406083643695194,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.2641251202739487,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 5.0000000000000036
    },
    "769": {
        "Time Stamp": 36.4060836436952,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3061751134011516,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "770": {
        "Time Stamp": 36.4060836436952,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3061751134011516,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "771": {
        "Time Stamp": 36.4060836436952,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3061751134011516,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "772": {
        "Time Stamp": 37.4060836436952,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.31458511202659223,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 0.5
    },
    "773": {
        "Time Stamp": 37.9060836436952,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.3187901113393125,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 80.0
    },
    "774": {
        "Time Stamp": 117.9060836436952,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9915900013745594,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "775": {
        "Time Stamp": 118.9060836436952,
        "Element id": "6011458448",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 31.406083643695194,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "776": {
        "Time Stamp": 271.5613349170725,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5418845824174473,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 0.0
    },
    "777": {
        "Time Stamp": 271.5613349170725,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5418845824174473,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 5.0
    },
    "778": {
        "Time Stamp": 276.5613349170725,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5518617866940231,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 0.0
    },
    "779": {
        "Time Stamp": 276.5613349170725,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5518617866940231,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 140.3231980427861
    },
    "780": {
        "Time Stamp": 416.8845329598586,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8318684290170775,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 1.0
    },
    "781": {
        "Time Stamp": 417.8845329598586,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8338638698723926,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 0.5
    },
    "782": {
        "Time Stamp": 418.3845329598586,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8348615903000502,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 81.75785737276016
    },
    "783": {
        "Time Stamp": 500.14239033261873,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9980045591446849,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 1.0
    },
    "784": {
        "Time Stamp": 501.14239033261873,
        "Element id": "6011459264",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 271.5613349170725,
        "Total Time": 229.58105541554625,
        "Duration": 0.0
    },
    "785": {
        "Time Stamp": 251.64778637344762,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4949949694738597,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 0.0
    },
    "786": {
        "Time Stamp": 251.64778637344762,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4949949694738597,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 5.0
    },
    "787": {
        "Time Stamp": 256.6477863734476,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5048300444532057,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 0.0
    },
    "788": {
        "Time Stamp": 256.6477863734476,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5048300444532057,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 142.23674658641096
    },
    "789": {
        "Time Stamp": 398.8845329598586,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7846118579523229,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 1.0
    },
    "790": {
        "Time Stamp": 399.8845329598586,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7865788729481921,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 0.5
    },
    "791": {
        "Time Stamp": 400.3845329598586,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7875623804461267,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 107.0
    },
    "792": {
        "Time Stamp": 507.3845329598586,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 9,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9980329850041308,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 1.0
    },
    "793": {
        "Time Stamp": 508.3845329598586,
        "Element id": "6011459600",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 251.64778637344762,
        "Total Time": 256.73674658641096,
        "Duration": 0.0
    },
    "794": {
        "Time Stamp": 214.70015489557636,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5137786588435427,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 3.456535735700868
    },
    "795": {
        "Time Stamp": 218.15669063127723,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5220501679879908,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 5.0
    },
    "796": {
        "Time Stamp": 223.15669063127723,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5340151956586375,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 0.0
    },
    "797": {
        "Time Stamp": 223.15669063127723,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5340151956586375,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 85.22784232858135
    },
    "798": {
        "Time Stamp": 308.3845329598586,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7379658940128363,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 1.0
    },
    "799": {
        "Time Stamp": 309.3845329598586,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7403588995469657,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 0.5
    },
    "800": {
        "Time Stamp": 309.8845329598586,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7415554023140303,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 107.0
    },
    "801": {
        "Time Stamp": 416.8845329598586,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9976069944658706,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 1.0
    },
    "802": {
        "Time Stamp": 417.8845329598586,
        "Element id": "6011460032",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 214.70015489557636,
        "Total Time": 203.1843780642822,
        "Duration": 0.0
    },
    "803": {
        "Time Stamp": 93.68404605301338,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5093965758011407,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 0.0
    },
    "804": {
        "Time Stamp": 93.68404605301338,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5093965758011407,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 5.0
    },
    "805": {
        "Time Stamp": 98.68404605301338,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.536583519430416,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 0.0
    },
    "806": {
        "Time Stamp": 98.68404605301338,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.536583519430416,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 0.0
    },
    "807": {
        "Time Stamp": 98.68404605301338,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.536583519430416,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 1.0
    },
    "808": {
        "Time Stamp": 99.68404605301338,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5420209081562711,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 0.5
    },
    "809": {
        "Time Stamp": 100.18404605301338,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5447396025191986,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 82.7277635339948
    },
    "810": {
        "Time Stamp": 182.91180958700818,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9945626112741449,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 1.0
    },
    "811": {
        "Time Stamp": 183.91180958700818,
        "Element id": "6011460416",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 93.68404605301338,
        "Total Time": 90.2277635339948,
        "Duration": 0.0
    },
    "812": {
        "Time Stamp": 294.61534597296327,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5487499227231246,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 0.172893143722888
    },
    "813": {
        "Time Stamp": 294.78823911668616,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5490719531283772,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 5.0
    },
    "814": {
        "Time Stamp": 299.78823911668616,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5583849425945383,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 0.0
    },
    "815": {
        "Time Stamp": 299.78823911668616,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5583849425945383,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 152.35415121593257
    },
    "816": {
        "Time Stamp": 452.14239033261873,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8421594636745183,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 1.0
    },
    "817": {
        "Time Stamp": 453.14239033261873,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8440220615677505,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 0.5
    },
    "818": {
        "Time Stamp": 453.64239033261873,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8449533605143666,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 82.24214262723984
    },
    "819": {
        "Time Stamp": 535.8845329598586,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981374021067678,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 1.0
    },
    "820": {
        "Time Stamp": 536.8845329598586,
        "Element id": "6011460608",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 294.61534597296327,
        "Total Time": 242.2691869868953,
        "Duration": 0.0
    },
    "821": {
        "Time Stamp": 203.73343638629257,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.574083729958829,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 1.0953680330338784
    },
    "822": {
        "Time Stamp": 204.82880441932645,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.577170277642094,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 5.0
    },
    "823": {
        "Time Stamp": 209.82880441932645,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5912593672913337,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 0.0
    },
    "824": {
        "Time Stamp": 209.82880441932645,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5912593672913337,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 57.55572854053213
    },
    "825": {
        "Time Stamp": 267.3845329598586,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7534409311383057,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 1.0
    },
    "826": {
        "Time Stamp": 268.3845329598586,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7562587490681536,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 0.5
    },
    "827": {
        "Time Stamp": 268.8845329598586,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7576676580330776,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 85.0
    },
    "828": {
        "Time Stamp": 353.8845329598586,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9971821820701521,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 1.0
    },
    "829": {
        "Time Stamp": 354.8845329598586,
        "Element id": "6011461424",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 203.73343638629257,
        "Total Time": 151.151096573566,
        "Duration": 0.0
    },
    "830": {
        "Time Stamp": 190.02317572693633,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5703962667050922,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 0.0
    },
    "831": {
        "Time Stamp": 190.02317572693633,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5703962667050922,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 5.0
    },
    "832": {
        "Time Stamp": 195.02317572693633,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5854048640649414,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 0.0
    },
    "833": {
        "Time Stamp": 195.02317572693633,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5854048640649414,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 53.6192146056824
    },
    "834": {
        "Time Stamp": 248.64239033261873,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7463547046185481,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 1.0
    },
    "835": {
        "Time Stamp": 249.64239033261873,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7493564240905181,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 0.5
    },
    "836": {
        "Time Stamp": 250.14239033261873,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.750857283826503,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 82.0
    },
    "837": {
        "Time Stamp": 332.14239033261873,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9969982805280302,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 1.0
    },
    "838": {
        "Time Stamp": 333.14239033261873,
        "Element id": "6011461760",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 190.02317572693633,
        "Total Time": 143.1192146056824,
        "Duration": 0.0
    },
    "839": {
        "Time Stamp": 162.87711219928516,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5264552517899072,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 0.0
    },
    "840": {
        "Time Stamp": 162.87711219928516,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5264552517899072,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 5.0
    },
    "841": {
        "Time Stamp": 167.87711219928516,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.542616369969169,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 0.0
    },
    "842": {
        "Time Stamp": 167.87711219928516,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.542616369969169,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 30.534697387723014
    },
    "843": {
        "Time Stamp": 198.41180958700818,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6413113405793668,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 1.0
    },
    "844": {
        "Time Stamp": 199.41180958700818,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6445435642152191,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 0.5
    },
    "845": {
        "Time Stamp": 199.91180958700818,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6461596760331453,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 108.4727233728504
    },
    "846": {
        "Time Stamp": 308.3845329598586,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 1,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9967677763641476,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 1.0
    },
    "847": {
        "Time Stamp": 309.3845329598586,
        "Element id": "6011462192",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 162.87711219928516,
        "Total Time": 146.5074207605734,
        "Duration": 0.0
    },
    "848": {
        "Time Stamp": 280.2037109588962,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5415772855750133,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 1.3576239581763048
    },
    "849": {
        "Time Stamp": 281.5613349170725,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5442012989957655,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 5.0
    },
    "850": {
        "Time Stamp": 286.5613349170725,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5538652910200264,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 0.0
    },
    "851": {
        "Time Stamp": 286.5613349170725,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5538652910200264,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 145.8231980427861
    },
    "852": {
        "Time Stamp": 432.3845329598586,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8357121355875655,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 1.0
    },
    "853": {
        "Time Stamp": 433.3845329598586,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8376449339924177,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 0.5
    },
    "854": {
        "Time Stamp": 433.8845329598586,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8386113331948437,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 82.5
    },
    "855": {
        "Time Stamp": 516.3845329598586,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9980672015951478,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 1.0
    },
    "856": {
        "Time Stamp": 517.3845329598586,
        "Element id": "6011462576",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 280.2037109588962,
        "Total Time": 237.1808220009624,
        "Duration": 0.0
    },
    "857": {
        "Time Stamp": 188.4747934140619,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5405963205858285,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 0.0
    },
    "858": {
        "Time Stamp": 188.4747934140619,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5405963205858285,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 5.0
    },
    "859": {
        "Time Stamp": 193.4747934140619,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5549376632872418,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 0.0
    },
    "860": {
        "Time Stamp": 193.4747934140619,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5549376632872418,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 45.42797605143434
    },
    "861": {
        "Time Stamp": 238.90276946549625,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6852372978442853,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 1.0
    },
    "862": {
        "Time Stamp": 239.90276946549625,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.688105566384568,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 0.5
    },
    "863": {
        "Time Stamp": 240.40276946549625,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6895397006547094,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 107.23962086712248
    },
    "864": {
        "Time Stamp": 347.64239033261873,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 12,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9971317314597173,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 1.0
    },
    "865": {
        "Time Stamp": 348.64239033261873,
        "Element id": "6011462768",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 188.4747934140619,
        "Total Time": 160.16759691855682,
        "Duration": 0.0
    },
    "866": {
        "Time Stamp": 194.82880441932645,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5361505148069918,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 0.0
    },
    "867": {
        "Time Stamp": 194.82880441932645,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5361505148069918,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 5.0
    },
    "868": {
        "Time Stamp": 199.82880441932645,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.54991004375357,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 0.0
    },
    "869": {
        "Time Stamp": 199.82880441932645,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.54991004375357,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 51.55572854053216
    },
    "870": {
        "Time Stamp": 251.3845329598586,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6917865515966468,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 1.0
    },
    "871": {
        "Time Stamp": 252.3845329598586,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6945384573859624,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 0.5
    },
    "872": {
        "Time Stamp": 252.8845329598586,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6959144102806203,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 109.49999999999997
    },
    "873": {
        "Time Stamp": 362.3845329598586,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9972480942106844,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 1.0
    },
    "874": {
        "Time Stamp": 363.3845329598586,
        "Element id": "6011463584",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 194.82880441932645,
        "Total Time": 168.55572854053213,
        "Duration": 0.0
    },
    "875": {
        "Time Stamp": 231.64208457286065,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.54073400589971,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 2.445173685393968
    },
    "876": {
        "Time Stamp": 234.08725825825462,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5464419003199389,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 5.0
    },
    "877": {
        "Time Stamp": 239.08725825825462,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.558113656920144,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 0.0
    },
    "878": {
        "Time Stamp": 239.08725825825462,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.558113656920144,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 104.05513207436411
    },
    "879": {
        "Time Stamp": 343.14239033261873,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8010148918349781,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 1.0
    },
    "880": {
        "Time Stamp": 344.14239033261873,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8033492431550191,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 0.5
    },
    "881": {
        "Time Stamp": 344.64239033261873,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8045164188150397,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 82.74214262723984
    },
    "882": {
        "Time Stamp": 427.3845329598586,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.997665648679959,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 1.0
    },
    "883": {
        "Time Stamp": 428.3845329598586,
        "Element id": "6011463920",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 231.64208457286065,
        "Total Time": 196.74244838699792,
        "Duration": 0.0
    },
    "884": {
        "Time Stamp": 224.89491019810524,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.508369740445948,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 0.0
    },
    "885": {
        "Time Stamp": 224.89491019810524,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.508369740445948,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 5.0
    },
    "886": {
        "Time Stamp": 229.89491019810524,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5196721247462004,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 0.0
    },
    "887": {
        "Time Stamp": 229.89491019810524,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5196721247462004,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 102.2474801345135
    },
    "888": {
        "Time Stamp": 332.14239033261873,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7508001875887395,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 1.0
    },
    "889": {
        "Time Stamp": 333.14239033261873,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7530606644487899,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 0.5
    },
    "890": {
        "Time Stamp": 333.64239033261873,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7541909028788152,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 107.74214262723984
    },
    "891": {
        "Time Stamp": 441.3845329598586,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 4,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9977395231399495,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 1.0
    },
    "892": {
        "Time Stamp": 442.3845329598586,
        "Element id": "6011464352",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 224.89491019810524,
        "Total Time": 217.48962276175334,
        "Duration": 0.0
    },
    "893": {
        "Time Stamp": 295.5428746328651,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5391717185994801,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 1.2443376184939439
    },
    "894": {
        "Time Stamp": 296.78721225135905,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5414418178299718,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 5.0
    },
    "895": {
        "Time Stamp": 301.78721225135905,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5505635352672347,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 0.0
    },
    "896": {
        "Time Stamp": 301.78721225135905,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5505635352672347,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 162.59732070849952
    },
    "897": {
        "Time Stamp": 464.3845329598586,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8471968983790233,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 1.0
    },
    "898": {
        "Time Stamp": 465.3845329598586,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8490212418664759,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 0.5
    },
    "899": {
        "Time Stamp": 465.8845329598586,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8499334136102021,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 81.25785737276021
    },
    "900": {
        "Time Stamp": 547.1423903326188,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981756565125475,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 1.0
    },
    "901": {
        "Time Stamp": 548.1423903326188,
        "Element id": "6011464736",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 295.5428746328651,
        "Total Time": 252.59951569975368,
        "Duration": 0.0
    },
    "902": {
        "Time Stamp": 357.5048179066771,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5954597400172861,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 0.0
    },
    "903": {
        "Time Stamp": 357.5048179066771,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5954597400172861,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 5.0
    },
    "904": {
        "Time Stamp": 362.5048179066771,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6037877360356882,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 0.0
    },
    "905": {
        "Time Stamp": 362.5048179066771,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6037877360356882,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 153.1375724259417
    },
    "906": {
        "Time Stamp": 515.6423903326188,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8588535547218942,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 1.0
    },
    "907": {
        "Time Stamp": 516.6423903326188,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8605191539255747,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 0.5
    },
    "908": {
        "Time Stamp": 517.1423903326188,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8613519535274149,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 82.24214262723979
    },
    "909": {
        "Time Stamp": 599.3845329598586,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9983344007963195,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 1.0
    },
    "910": {
        "Time Stamp": 600.3845329598586,
        "Element id": "6011464928",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 357.5048179066771,
        "Total Time": 242.87971505318149,
        "Duration": 0.0
    },
    "911": {
        "Time Stamp": 220.07336433428108,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5137285485395965,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 0.0
    },
    "912": {
        "Time Stamp": 220.07336433428108,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5137285485395965,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 5.0
    },
    "913": {
        "Time Stamp": 225.07336433428108,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5254003051398016,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 0.0
    },
    "914": {
        "Time Stamp": 225.07336433428108,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5254003051398016,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 92.3111686255775
    },
    "915": {
        "Time Stamp": 317.3845329598586,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7408870034754472,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 1.0
    },
    "916": {
        "Time Stamp": 318.3845329598586,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7432213547954882,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 0.5
    },
    "917": {
        "Time Stamp": 318.8845329598586,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7443885304555088,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 108.5
    },
    "918": {
        "Time Stamp": 427.3845329598586,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.997665648679959,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 1.0
    },
    "919": {
        "Time Stamp": 428.3845329598586,
        "Element id": "6011465744",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 220.07336433428108,
        "Total Time": 208.3111686255775,
        "Duration": 0.0
    },
    "920": {
        "Time Stamp": 168.74012388667524,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.564566263150652,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 0.0
    },
    "921": {
        "Time Stamp": 168.74012388667524,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.564566263150652,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 5.0
    },
    "922": {
        "Time Stamp": 173.74012388667524,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5812951314881498,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 0.0
    },
    "923": {
        "Time Stamp": 173.74012388667524,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5812951314881498,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 39.162645578821014
    },
    "924": {
        "Time Stamp": 212.90276946549625,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7123244798153874,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 1.0
    },
    "925": {
        "Time Stamp": 213.90276946549625,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7156702534828869,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 0.5
    },
    "926": {
        "Time Stamp": 214.40276946549625,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7173431403166367,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 83.48176349436233
    },
    "927": {
        "Time Stamp": 297.8845329598586,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9966542263325004,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 1.0
    },
    "928": {
        "Time Stamp": 298.8845329598586,
        "Element id": "6011466080",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 168.74012388667524,
        "Total Time": 130.14440907318334,
        "Duration": 0.0
    },
    "929": {
        "Time Stamp": 166.5414712861097,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5600206225539972,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 0.0
    },
    "930": {
        "Time Stamp": 166.5414712861097,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5600206225539972,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 5.0
    },
    "931": {
        "Time Stamp": 171.5414712861097,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5768338708767501,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 0.0
    },
    "932": {
        "Time Stamp": 171.5414712861097,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5768338708767501,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 39.343061673748906
    },
    "933": {
        "Time Stamp": 210.8845329598586,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7091308040163747,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 1.0
    },
    "934": {
        "Time Stamp": 211.8845329598586,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7124934536809253,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 0.5
    },
    "935": {
        "Time Stamp": 212.3845329598586,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7141747785132007,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 83.99999999999997
    },
    "936": {
        "Time Stamp": 296.3845329598586,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9966373503354494,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 1.0
    },
    "937": {
        "Time Stamp": 297.3845329598586,
        "Element id": "6013059184",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 166.5414712861097,
        "Total Time": 130.84306167374888,
        "Duration": 0.0
    },
    "938": {
        "Time Stamp": 238.58773931616267,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5315124977529541,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 2.3097331803409418
    },
    "939": {
        "Time Stamp": 240.8974724965036,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5366579928875513,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 5.0
    },
    "940": {
        "Time Stamp": 245.8974724965036,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5477967148368932,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 0.0
    },
    "941": {
        "Time Stamp": 245.8974724965036,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5477967148368932,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 116.48706046335496
    },
    "942": {
        "Time Stamp": 362.3845329598586,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8073001102763876,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 1.0
    },
    "943": {
        "Time Stamp": 363.3845329598586,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.809527854666256,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 0.5
    },
    "944": {
        "Time Stamp": 363.8845329598586,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8106417268611902,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 84.0
    },
    "945": {
        "Time Stamp": 447.8845329598586,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 14,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9977722556101316,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 1.0
    },
    "946": {
        "Time Stamp": 448.8845329598586,
        "Element id": "6013059616",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 238.58773931616267,
        "Total Time": 210.2967936436959,
        "Duration": 0.0
    },
    "947": {
        "Time Stamp": 89.33097281000786,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5050242172970558,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 0.0
    },
    "948": {
        "Time Stamp": 89.33097281000786,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5050242172970558,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 5.0
    },
    "949": {
        "Time Stamp": 94.33097281000786,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5332912450373201,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 0.0
    },
    "950": {
        "Time Stamp": 94.33097281000786,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5332912450373201,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 0.0
    },
    "951": {
        "Time Stamp": 94.33097281000786,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5332912450373201,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 1.0
    },
    "952": {
        "Time Stamp": 95.33097281000786,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5389446505853729,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 0.5
    },
    "953": {
        "Time Stamp": 95.83097281000786,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5417713533593993,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 80.05356014985074
    },
    "954": {
        "Time Stamp": 175.8845329598586,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 8,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9943465944519472,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 1.0
    },
    "955": {
        "Time Stamp": 176.8845329598586,
        "Element id": "6013060048",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 89.33097281000786,
        "Total Time": 87.55356014985074,
        "Duration": 0.0
    },
    "956": {
        "Time Stamp": 145.7864436707146,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5631330771441628,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 3.7140991749461136
    },
    "957": {
        "Time Stamp": 149.5005428456607,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5774796243576342,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 5.0
    },
    "958": {
        "Time Stamp": 154.5005428456607,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5967932540396951,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 0.0
    },
    "959": {
        "Time Stamp": 154.5005428456607,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5967932540396951,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 20.317381071209297
    },
    "960": {
        "Time Stamp": 174.81792391687,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6752737288634252,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 1.0
    },
    "961": {
        "Time Stamp": 175.81792391687,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6791364547998374,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 0.5
    },
    "962": {
        "Time Stamp": 176.31792391687,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6810678177680435,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 81.56660904298857
    },
    "963": {
        "Time Stamp": 257.8845329598586,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9961372740635878,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 1.0
    },
    "964": {
        "Time Stamp": 258.8845329598586,
        "Element id": "6013060480",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 145.7864436707146,
        "Total Time": 113.09808928914399,
        "Duration": 0.0
    },
    "965": {
        "Time Stamp": 142.84623049865337,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6120638273969187,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 1.654312347007334
    },
    "966": {
        "Time Stamp": 144.5005428456607,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6191521820793254,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 5.0
    },
    "967": {
        "Time Stamp": 149.5005428456607,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6405760525329,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 0.0
    },
    "968": {
        "Time Stamp": 149.5005428456607,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6405760525329,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 0.0
    },
    "969": {
        "Time Stamp": 149.5005428456607,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6405760525329,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 1.0
    },
    "970": {
        "Time Stamp": 150.5005428456607,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6448608266236149,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 0.5
    },
    "971": {
        "Time Stamp": 151.0005428456607,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6470032136689723,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 81.3839901141979
    },
    "972": {
        "Time Stamp": 232.3845329598586,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 19,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9957152259092851,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 1.0
    },
    "973": {
        "Time Stamp": 233.3845329598586,
        "Element id": "6013060912",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 142.84623049865337,
        "Total Time": 90.53830246120523,
        "Duration": 0.0
    },
    "974": {
        "Time Stamp": 63.56784909602031,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4185274685791778,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 0.0
    },
    "975": {
        "Time Stamp": 63.56784909602031,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4185274685791778,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 5.0
    },
    "976": {
        "Time Stamp": 68.56784909602031,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4514472129571089,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 0.0
    },
    "977": {
        "Time Stamp": 68.56784909602031,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4514472129571089,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 0.0
    },
    "978": {
        "Time Stamp": 68.56784909602031,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4514472129571089,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 1.0
    },
    "979": {
        "Time Stamp": 69.56784909602031,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4580311618326951,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 0.5
    },
    "980": {
        "Time Stamp": 70.06784909602031,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4613231362704882,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 80.81668386383829
    },
    "981": {
        "Time Stamp": 150.8845329598586,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 7,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9934160511244138,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 1.0
    },
    "982": {
        "Time Stamp": 151.8845329598586,
        "Element id": "6013061344",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 63.56784909602031,
        "Total Time": 88.31668386383829,
        "Duration": 0.0
    },
    "983": {
        "Time Stamp": 171.88669772404646,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5068275232579039,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 0.0
    },
    "984": {
        "Time Stamp": 171.88669772404646,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5068275232579039,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 5.0
    },
    "985": {
        "Time Stamp": 176.88669772404646,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5215705932560135,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 0.0
    },
    "986": {
        "Time Stamp": 176.88669772404646,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5215705932560135,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 49.99783523581215
    },
    "987": {
        "Time Stamp": 226.8845329598586,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6689949101831192,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 1.0
    },
    "988": {
        "Time Stamp": 227.8845329598586,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6719435241827411,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 0.5
    },
    "989": {
        "Time Stamp": 228.3845329598586,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.673417831182552,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 109.75785737276013
    },
    "990": {
        "Time Stamp": 338.14239033261873,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9970513860003781,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 1.0
    },
    "991": {
        "Time Stamp": 339.14239033261873,
        "Element id": "6013061776",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 171.88669772404646,
        "Total Time": 167.25569260857228,
        "Duration": 0.0
    },
    "992": {
        "Time Stamp": 247.60902613567487,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5018581037598521,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 1.4782321225797546
    },
    "993": {
        "Time Stamp": 249.08725825825462,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5048542092796415,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 5.0
    },
    "994": {
        "Time Stamp": 254.08725825825462,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5149882926689292,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 0.0
    },
    "995": {
        "Time Stamp": 254.08725825825462,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5149882926689292,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 130.0551320743641
    },
    "996": {
        "Time Stamp": 384.14239033261873,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7785862033982169,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 1.0
    },
    "997": {
        "Time Stamp": 385.14239033261873,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7806130200760745,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 0.5
    },
    "998": {
        "Time Stamp": 385.64239033261873,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7816264284150033,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 106.74214262723984
    },
    "999": {
        "Time Stamp": 492.3845329598586,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 13,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9979731833221425,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 1.0
    },
    "1000": {
        "Time Stamp": 493.3845329598586,
        "Element id": "6013062208",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 247.60902613567487,
        "Total Time": 245.7755068241837,
        "Duration": 0.0
    },
    "1001": {
        "Time Stamp": 236.62808561652545,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5410069807800959,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 2.459172641729168
    },
    "1002": {
        "Time Stamp": 239.08725825825462,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5466294307215411,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 5.0
    },
    "1003": {
        "Time Stamp": 244.08725825825462,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5580610192282588,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 0.0
    },
    "1004": {
        "Time Stamp": 244.08725825825462,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5580610192282588,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 109.79727470160395
    },
    "1005": {
        "Time Stamp": 353.8845329598586,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8090924719378148,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 1.0
    },
    "1006": {
        "Time Stamp": 354.8845329598586,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8113787896391582,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 0.5
    },
    "1007": {
        "Time Stamp": 355.3845329598586,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8125219484898301,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 81.0
    },
    "1008": {
        "Time Stamp": 436.3845329598586,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 10,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9977136822986564,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 1.0
    },
    "1009": {
        "Time Stamp": 437.3845329598586,
        "Element id": "6013062640",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 236.62808561652545,
        "Total Time": 200.75644734333312,
        "Duration": 0.0
    },
    "1010": {
        "Time Stamp": 162.1591242395872,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5793786549214003,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 0.0
    },
    "1011": {
        "Time Stamp": 162.1591242395872,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5793786549214003,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 5.0
    },
    "1012": {
        "Time Stamp": 167.1591242395872,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5972431647859633,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 0.0
    },
    "1013": {
        "Time Stamp": 167.1591242395872,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5972431647859633,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 28.725408720271417
    },
    "1014": {
        "Time Stamp": 195.8845329598586,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6998762342753418,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 1.0
    },
    "1015": {
        "Time Stamp": 196.8845329598586,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7034491362482544,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 0.5
    },
    "1016": {
        "Time Stamp": 197.3845329598586,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7052355872347107,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 81.49999999999997
    },
    "1017": {
        "Time Stamp": 278.8845329598586,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9964270980270874,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 1.0
    },
    "1018": {
        "Time Stamp": 279.8845329598586,
        "Element id": "6013063072",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 162.1591242395872,
        "Total Time": 117.72540872027139,
        "Duration": 0.0
    },
    "1019": {
        "Time Stamp": 136.43094591597344,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5986845361725601,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 2.5114292623847803
    },
    "1020": {
        "Time Stamp": 138.94237517835822,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6097051580189193,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 5.0
    },
    "1021": {
        "Time Stamp": 143.94237517835822,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6316460942248914,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 0.0
    },
    "1022": {
        "Time Stamp": 143.94237517835822,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6316460942248914,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 0.0
    },
    "1023": {
        "Time Stamp": 143.94237517835822,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6316460942248914,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 1.0
    },
    "1024": {
        "Time Stamp": 144.94237517835822,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6360342814660859,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 0.5
    },
    "1025": {
        "Time Stamp": 145.44237517835822,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6382283750866831,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 81.44215778150038
    },
    "1026": {
        "Time Stamp": 226.8845329598586,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 5,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9956118127588056,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 1.0
    },
    "1027": {
        "Time Stamp": 227.8845329598586,
        "Element id": "6013063504",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 136.43094591597344,
        "Total Time": 91.45358704388516,
        "Duration": 0.0
    },
    "1028": {
        "Time Stamp": 99.1568745801983,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4635604991369356,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 0.0
    },
    "1029": {
        "Time Stamp": 99.1568745801983,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4635604991369356,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 5.0
    },
    "1030": {
        "Time Stamp": 104.1568745801983,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4869356055579234,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 0.0
    },
    "1031": {
        "Time Stamp": 104.1568745801983,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4869356055579234,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 0.0
    },
    "1032": {
        "Time Stamp": 104.1568745801983,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4869356055579234,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 1.0
    },
    "1033": {
        "Time Stamp": 105.1568745801983,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.491610626842121,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 0.5
    },
    "1034": {
        "Time Stamp": 105.6568745801983,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.4939481374842198,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 107.24589488529794
    },
    "1035": {
        "Time Stamp": 212.90276946549625,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 11,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9953249787158024,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 1.0
    },
    "1036": {
        "Time Stamp": 213.90276946549625,
        "Element id": "6013063936",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 99.1568745801983,
        "Total Time": 114.74589488529794,
        "Duration": 0.0
    },
    "1037": {
        "Time Stamp": 244.7178022299384,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5070375232919102,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 0.17710796816683683
    },
    "1038": {
        "Time Stamp": 244.89491019810524,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5074044781465072,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 5.0
    },
    "1039": {
        "Time Stamp": 249.89491019810524,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5177641152197328,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 0.0
    },
    "1040": {
        "Time Stamp": 249.89491019810524,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5177641152197328,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 119.7474801345135
    },
    "1041": {
        "Time Stamp": 369.64239033261873,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7658722021451023,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 1.0
    },
    "1042": {
        "Time Stamp": 370.64239033261873,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7679441295597474,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 0.5
    },
    "1043": {
        "Time Stamp": 371.14239033261873,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.76898009326707,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 110.5
    },
    "1044": {
        "Time Stamp": 481.64239033261873,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 18,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9979280725853549,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 1.0
    },
    "1045": {
        "Time Stamp": 482.64239033261873,
        "Element id": "6013064368",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 244.7178022299384,
        "Total Time": 237.92458810268033,
        "Duration": 0.0
    },
    "1046": {
        "Time Stamp": 141.90276946549625,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6119544397989708,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 0.0
    },
    "1047": {
        "Time Stamp": 141.90276946549625,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6119544397989708,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 5.0
    },
    "1048": {
        "Time Stamp": 146.90276946549625,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.633516895630665,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 0.0
    },
    "1049": {
        "Time Stamp": 146.90276946549625,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.633516895630665,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 0.0
    },
    "1050": {
        "Time Stamp": 146.90276946549625,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.633516895630665,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 1.0
    },
    "1051": {
        "Time Stamp": 147.90276946549625,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6378293867970038,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 0.5
    },
    "1052": {
        "Time Stamp": 148.40276946549625,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6399856323801733,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 82.48176349436235
    },
    "1053": {
        "Time Stamp": 230.8845329598586,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 17,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9956875088336612,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 1.0
    },
    "1054": {
        "Time Stamp": 231.8845329598586,
        "Element id": "6013064800",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 141.90276946549625,
        "Total Time": 89.98176349436235,
        "Duration": 0.0
    },
    "1055": {
        "Time Stamp": 240.59727364843752,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5000542011733993,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 3.4899846098170997
    },
    "1056": {
        "Time Stamp": 244.08725825825462,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5073077391695098,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 5.0
    },
    "1057": {
        "Time Stamp": 249.08725825825462,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5176996732423805,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 0.0
    },
    "1058": {
        "Time Stamp": 249.08725825825462,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5176996732423805,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 119.79727470160395
    },
    "1059": {
        "Time Stamp": 368.8845329598586,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7666847494041107,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 1.0
    },
    "1060": {
        "Time Stamp": 369.8845329598586,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7687631362186848,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 0.5
    },
    "1061": {
        "Time Stamp": 370.3845329598586,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7698023296259718,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 109.75785737276016
    },
    "1062": {
        "Time Stamp": 480.14239033261873,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 6,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9979216131854258,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 1.0
    },
    "1063": {
        "Time Stamp": 481.14239033261873,
        "Element id": "6013065232",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 240.59727364843752,
        "Total Time": 240.5451166841812,
        "Duration": 0.0
    },
    "1064": {
        "Time Stamp": 289.78823911668616,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5468894090906388,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 0.0
    },
    "1065": {
        "Time Stamp": 289.78823911668616,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5468894090906388,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 5.0
    },
    "1066": {
        "Time Stamp": 294.78823911668616,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5563254271077541,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 0.0
    },
    "1067": {
        "Time Stamp": 294.78823911668616,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5563254271077541,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 148.59629384317242
    },
    "1068": {
        "Time Stamp": 443.3845329598586,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8367568883039037,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 1.0
    },
    "1069": {
        "Time Stamp": 444.3845329598586,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8386440919073268,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 0.5
    },
    "1070": {
        "Time Stamp": 444.8845329598586,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.8395876937090383,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 84.0
    },
    "1071": {
        "Time Stamp": 528.8845329598586,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 2,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9981127963965769,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 1.0
    },
    "1072": {
        "Time Stamp": 529.8845329598586,
        "Element id": "6013065664",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 289.78823911668616,
        "Total Time": 240.09629384317242,
        "Duration": 0.0
    },
    "1073": {
        "Time Stamp": 84.92304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.49252722337973365,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "1074": {
        "Time Stamp": 84.92304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.49252722337973365,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 5.0
    },
    "1075": {
        "Time Stamp": 89.92304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5215256677580346,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "1076": {
        "Time Stamp": 89.92304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5215256677580346,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "1077": {
        "Time Stamp": 89.92304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5215256677580346,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "1078": {
        "Time Stamp": 90.92304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5273253566336947,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 0.5
    },
    "1079": {
        "Time Stamp": 91.42304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5302252010715248,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 80.0
    },
    "1080": {
        "Time Stamp": 171.42304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9942003111243398,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 1.0
    },
    "1081": {
        "Time Stamp": 172.42304224227348,
        "Element id": "6013066096",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 84.92304224227348,
        "Total Time": 87.5,
        "Duration": 0.0
    },
    "1082": {
        "Time Stamp": 229.3623768108901,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5061596127489462,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 0.5325333872151248
    },
    "1083": {
        "Time Stamp": 229.89491019810524,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5073348137422239,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 5.0
    },
    "1084": {
        "Time Stamp": 234.89491019810524,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5183688730283786,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 0.0
    },
    "1085": {
        "Time Stamp": 234.89491019810524,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5183688730283786,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 104.48962276175334
    },
    "1086": {
        "Time Stamp": 339.3845329598586,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7489578114966052,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 1.0
    },
    "1087": {
        "Time Stamp": 340.3845329598586,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7511646233538362,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 0.5
    },
    "1088": {
        "Time Stamp": 340.8845329598586,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.7522680292824516,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 111.25785737276016
    },
    "1089": {
        "Time Stamp": 452.14239033261873,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.9977931881427691,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 1.0
    },
    "1090": {
        "Time Stamp": 453.14239033261873,
        "Element id": "6013066528",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 229.3623768108901,
        "Total Time": 223.78001352172862,
        "Duration": 0.0
    },
    "1091": {
        "Time Stamp": 122.58401331409225,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "ARRIVED",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5785415839546708,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 0.0
    },
    "1092": {
        "Time Stamp": 122.58401331409225,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.5785415839546708,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 5.0
    },
    "1093": {
        "Time Stamp": 127.58401331409225,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "OPERATING",
        "Location Name": "RECEPTION",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6021393422721562,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 0.0
    },
    "1094": {
        "Time Stamp": 127.58401331409225,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "WAITING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6021393422721562,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 0.0
    },
    "1095": {
        "Time Stamp": 127.58401331409225,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "LEAVING",
        "Location Name": "TABLE_QUEUE",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6021393422721562,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 1.0000000000000142
    },
    "1096": {
        "Time Stamp": 128.58401331409226,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "MOVING",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6068588939356533,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 0.5
    },
    "1097": {
        "Time Stamp": 129.08401331409226,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "GET",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.6092186697674018,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 81.80051964576634
    },
    "1098": {
        "Time Stamp": 210.8845329598586,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "FREE",
        "Location Name": "TABLE",
        "Location Id": 3,
        "Resource Unit": "",
        "Time Stamp Porcentage": 0.995280448336503,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 1.0
    },
    "1099": {
        "Time Stamp": 211.8845329598586,
        "Element id": "6013066960",
        "Element": "ENTITY",
        "Status": "EXIT",
        "Location Name": "EXIT",
        "Location Id": 0,
        "Resource Unit": "",
        "Time Stamp Porcentage": 1.0,
        "Time Stamp Initial": 122.58401331409225,
        "Total Time": 89.30051964576636,
        "Duration": 0.0
    }
}