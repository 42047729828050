export const dataPortfolioDTvsMLP = function(targetValue){
    const values = [
        [{'vhigh': 432, 'high': 432, 'med': 432, 'low': 432}, {'vhigh': 432, 'high': 432, 'med': 432, 'low': 432}, {'2': 432, '3': 432, '4': 432, '5more': 432}, {'2': 576, '4': 576, 'more': 576, 'fake0': 0}, {'small': 576, 'med': 576, 'big': 576, 'fake0': 0}, {'low': 576, 'med': 576, 'high': 576, 'fake0': 0}, {'unacc': 1210, 'acc': 384, 'good': 69, 'vgood': 65}],
        [{'vhigh': 360, 'high': 324, 'med': 268, 'low': 258}, {'vhigh': 360, 'high': 314, 'med': 268, 'low': 268}, {'2': 326, '3': 300, '4': 292, '5more': 292}, {'2': 576, 'more': 322, '4': 312, 'fake0': 0}, {'small': 450, 'med': 392, 'big': 368, 'fake0': 0}, {'low': 576, 'med': 357, 'high': 277, 'fake0': 0}, {'unacc': 1210, 'fake0': 0, 'fake1': 0, 'fake2': 0}],
        [{'med': 115, 'high': 108, 'low': 89, 'vhigh': 72}, {'med': 115, 'high': 105, 'low': 92, 'vhigh': 72}, {'4': 102, '5more': 102, '3': 99, '2': 81}, {'4': 198, 'more': 186, 'fake0': 0, 'fake1': 0}, {'big': 144, 'med': 135, 'small': 105, 'fake0': 0}, {'high': 204, 'med': 180, 'fake0': 0, 'fake1': 0}, {'acc': 384, 'fake0': 0, 'fake1': 0, 'fake2': 0}],
        [{'low': 46, 'med': 23, 'fake0': 0, 'fake1': 0}, {'low': 46, 'med': 23, 'fake0': 0, 'fake1': 0}, {'3': 18, '4': 18, '5more': 18, '2': 15}, {'4': 36, 'more': 33, 'fake0': 0, 'fake1': 0}, {'med': 24, 'big': 24, 'small': 21, 'fake0': 0}, {'med': 39, 'high': 30, 'fake0': 0, 'fake1': 0}, {'good': 69, 'fake0': 0, 'fake1': 0, 'fake2': 0}],
        [{'low': 39, 'med': 26, 'fake0': 0, 'fake1': 0}, {'med': 26, 'low': 26, 'high': 13, 'fake0': 0}, {'4': 20, '5more': 20, '3': 15, '2': 10}, {'more': 35, '4': 30, 'fake0': 0, 'fake1': 0}, {'big': 40, 'med': 25, 'fake0': 0, 'fake1': 0}, {'high': 65, 'fake0': 0, 'fake1': 0, 'fake2': 0}, {'vgood': 65, 'fake0': 0, 'fake1': 0, 'fake2': 0}]
    ];
    

    const array1 = values[targetValue].map((x,number)=>{
        return Object.values(x);
        });
    const arrayKeys = values[targetValue].map((x,number)=>{
        return Object.keys(x);
        });
    
    const size = array1[0].length;
    const arrayFinal = [];
    let arrayAux = [];
    // loop of 4 times
    for (let index = 0; index < size; index++) {
        arrayAux = [];
        array1.forEach((array4)=>{
            arrayAux.push(array4[index]);
        });
        arrayFinal.push(
            {
                name:`Value ${index}`,
                data:arrayAux
            }
        );
        
    };
    return [arrayFinal, arrayKeys];
}

export const importDataArcDiagram2 = function(){
    const data = {
        "nodes": [
          {
            "id": 1,
            "name": "A"
          },
          {
            "id": 2,
            "name": "B"
          },
          {
            "id": 3,
            "name": "C"
          },
          {
            "id": 4,
            "name": "D"
          },
          {
            "id": 5,
            "name": "E"
          },
          {
            "id": 6,
            "name": "F"
          },
          {
            "id": 7,
            "name": "G"
          },
          {
            "id": 8,
            "name": "H"
          },
          {
            "id": 9,
            "name": "I"
          },
          {
            "id": 10,
            "name": "J"
          }
        ],
        "links": [
      
          {
            "source": 1,
            "target": 2
          },
          {
            "source": 1,
            "target": 5
          },
          {
            "source": 1,
            "target": 6
          },
      
          {
            "source": 2,
            "target": 3
          },
                  {
            "source": 2,
            "target": 7
          }
          ,
      
          {
            "source": 3,
            "target": 4
          },
           {
            "source": 8,
            "target": 3
          }
          ,
          {
            "source": 4,
            "target": 5
          }
          ,
      
          {
            "source": 4,
            "target": 9
          },
          {
            "source": 5,
            "target": 10
          }
        ]
      };
    return data;
}

export const importDataArcDiagram = function(parametersId){
    const dataAll = [
        {
            'class_weight': ['balanced', 'None'],
            'criterion': ['gini', 'entropy'],
            'max_depth': [3, 6, 9, 12, 15, 18, 21]
        },
        {'activation': ['tanh', 'relu'],
        'alpha': [0.0001, 0.05],
        'hidden_layer_sizes': ['(100)', '(50, 50)', '(50, 100)', '(100, 50)', '(100, 100)', '(50, 100, 50)'],
        'learning_rate': ['constant', 'adaptive'],
        'solver': ['sgd', 'adam']}
    ];
    const data = dataAll[parametersId];
    let dataFinal = []

    let index = 0;
    let linksIndex = []
    let linksIndexAux = []
    Object.keys(data).forEach((attr)=>{
        data[attr].forEach((d)=>{
            linksIndexAux.push(index);
            dataFinal.push({id:index++,name:`${attr} : ${d}`});
        });
        linksIndex.push(linksIndexAux);
        linksIndexAux = [];
    })

    let linksFinal = []
    for (let index = 0; index < linksIndex.length; index++) {
        for (let index2 = 0; index2 < linksIndex.length; index2++) {
            if (index !== index2){                                
                linksIndex[index].forEach((d1)=>{
                    linksIndex[index2].forEach((d2)=>{
                        linksFinal.push({source:d1,target:d2});
                    });
                });                
            }            
        }
        
    }

    // console.log(dataFinal);
    // console.log(linksFinal);
    const dataToRender = {
        "nodes":dataFinal,
        'links':linksFinal
    }

    return dataToRender;
}

export const importDataBoxPlot = function(criterion){
  const stats = {
    'Acurracy':{
      'DT':{'mean': 0.9674434365551038, 'iqr': 0.018115942028985477, 'cilo': 0.9583971450607529, 'cihi': 0.9763854636348992, 'whishi': 0.9855072463768116, 'whislo': 0.9496402877697842, 'q1': 0.9583333333333334, 'med': 0.967391304347826, 'q3': 0.9764492753623188},
      'MLP':{'mean': 0.9956573871337712, 'iqr': 0.007233343759774802, 'cilo': 0.9964088069033629, 'cihi': 1.003591193096637, 'whishi': 1.0, 'whislo': 0.9927536231884058, 'q1': 0.9927666562402252, 'med': 1.0, 'q3': 1.0}
    },
    'Precision':{
      'DT':{'mean': 0.9466442279975349, 'iqr': 0.03110545370423068, 'cilo': 0.942885964899974, 'cihi': 0.9737722864464772, 'whishi': 0.9852941176470589, 'whislo': 0.8992726122707148, 'q1': 0.9416778003935793, 'med': 0.9583291256732256, 'q3': 0.97278325409781},
      'MLP':{'mean': 0.9874065263194254, 'iqr': 0.006332859848484862, 'cilo': 0.9968558769878569, 'cihi': 1.003144123012143, 'whishi': 1.0, 'whislo': 0.9924242424242424, 'q1': 0.9936671401515151, 'med': 1.0, 'q3': 1.0}
    },
    'Recall':{
      'DT':{'mean': 0.9386263955342903, 'iqr': 0.04355188397129184, 'cilo': 0.9193264288081006, 'cihi': 0.9625714978266683, 'whishi': 0.9822169059011164, 'whislo': 0.8978892543859649, 'q1': 0.916745788476874, 'med': 0.9409489633173844, 'q3': 0.9602976724481659},
      'MLP':{'mean': 0.996914124800638, 'iqr': 0.006339712918660312, 'cilo': 0.996852474592074, 'cihi': 1.003147525407926, 'whishi': 1.0, 'whislo': 0.9871610845295056, 'q1': 0.9936602870813397, 'med': 1.0, 'q3': 1.0}
    },
    'F1':{
      'DT':{'mean': 0.9385246838289353, 'iqr': 0.042624377809931446, 'cilo': 0.9204180926189107, 'cihi': 0.9627421895851644, 'whishi': 0.9633237133237132, 'whislo': 0.8957708957708959, 'q1': 0.9191328937057531, 'med': 0.9415801411020376, 'q3': 0.9617572715156846},
      'MLP':{'mean': 0.9913499439284779, 'iqr': 0.005250092161086961, 'cilo': 0.9973934468826916, 'cihi': 1.0026065531173083, 'whishi': 1.0, 'whislo': 0.9947228455081858, 'q1': 0.994749907838913, 'med': 1.0, 'q3': 1.0}
    }
  }
  return stats[criterion];
}

export const importDataHeatMapDiseases = function(){
  const data = [
    {'name': 'Atelectasis',
      'data': [{'x': 'Atelectasis', 'y': 11559},
      {'x': 'Cardiomegaly', 'y': 370},
      {'x': 'Consolidation', 'y': 1223},
      {'x': 'Edema', 'y': 221},
      {'x': 'Effusion', 'y': 3275},
      {'x': 'Emphysema', 'y': 424},
      {'x': 'Fibrosis', 'y': 220},
      {'x': 'Hernia', 'y': 40},
      {'x': 'Infiltration', 'y': 3264},
      {'x': 'Mass', 'y': 739},
      {'x': 'Nodule', 'y': 590},
      {'x': 'Pleural_Thickening', 'y': 496},
      {'x': 'Pneumonia', 'y': 262},
      {'x': 'Pneumothorax', 'y': 774}]},
    {'name': 'Cardiomegaly',
      'data': [{'x': 'Atelectasis', 'y': 370},
      {'x': 'Cardiomegaly', 'y': 2776},
      {'x': 'Consolidation', 'y': 169},
      {'x': 'Edema', 'y': 127},
      {'x': 'Effusion', 'y': 1063},
      {'x': 'Emphysema', 'y': 44},
      {'x': 'Fibrosis', 'y': 52},
      {'x': 'Hernia', 'y': 7},
      {'x': 'Infiltration', 'y': 587},
      {'x': 'Mass', 'y': 102},
      {'x': 'Nodule', 'y': 108},
      {'x': 'Pleural_Thickening', 'y': 111},
      {'x': 'Pneumonia', 'y': 41},
      {'x': 'Pneumothorax', 'y': 49}]},
    {'name': 'Consolidation',
      'data': [{'x': 'Atelectasis', 'y': 1223},
      {'x': 'Cardiomegaly', 'y': 169},
      {'x': 'Consolidation', 'y': 4667},
      {'x': 'Edema', 'y': 162},
      {'x': 'Effusion', 'y': 1287},
      {'x': 'Emphysema', 'y': 103},
      {'x': 'Fibrosis', 'y': 79},
      {'x': 'Hernia', 'y': 4},
      {'x': 'Infiltration', 'y': 1221},
      {'x': 'Mass', 'y': 610},
      {'x': 'Nodule', 'y': 428},
      {'x': 'Pleural_Thickening', 'y': 251},
      {'x': 'Pneumonia', 'y': 123},
      {'x': 'Pneumothorax', 'y': 223}]},
    {'name': 'Edema',
      'data': [{'x': 'Atelectasis', 'y': 221},
      {'x': 'Cardiomegaly', 'y': 127},
      {'x': 'Consolidation', 'y': 162},
      {'x': 'Edema', 'y': 2303},
      {'x': 'Effusion', 'y': 593},
      {'x': 'Emphysema', 'y': 30},
      {'x': 'Fibrosis', 'y': 9},
      {'x': 'Hernia', 'y': 3},
      {'x': 'Infiltration', 'y': 981},
      {'x': 'Mass', 'y': 129},
      {'x': 'Nodule', 'y': 131},
      {'x': 'Pleural_Thickening', 'y': 64},
      {'x': 'Pneumonia', 'y': 340},
      {'x': 'Pneumothorax', 'y': 33}]},
    {'name': 'Effusion',
      'data': [{'x': 'Atelectasis', 'y': 3275},
      {'x': 'Cardiomegaly', 'y': 1063},
      {'x': 'Consolidation', 'y': 1287},
      {'x': 'Edema', 'y': 593},
      {'x': 'Effusion', 'y': 13317},
      {'x': 'Emphysema', 'y': 359},
      {'x': 'Fibrosis', 'y': 188},
      {'x': 'Hernia', 'y': 21},
      {'x': 'Infiltration', 'y': 4000},
      {'x': 'Mass', 'y': 1254},
      {'x': 'Nodule', 'y': 912},
      {'x': 'Pleural_Thickening', 'y': 849},
      {'x': 'Pneumonia', 'y': 269},
      {'x': 'Pneumothorax', 'y': 996}]},
    {'name': 'Emphysema',
      'data': [{'x': 'Atelectasis', 'y': 424},
      {'x': 'Cardiomegaly', 'y': 44},
      {'x': 'Consolidation', 'y': 103},
      {'x': 'Edema', 'y': 30},
      {'x': 'Effusion', 'y': 359},
      {'x': 'Emphysema', 'y': 2516},
      {'x': 'Fibrosis', 'y': 36},
      {'x': 'Hernia', 'y': 4},
      {'x': 'Infiltration', 'y': 449},
      {'x': 'Mass', 'y': 215},
      {'x': 'Nodule', 'y': 115},
      {'x': 'Pleural_Thickening', 'y': 151},
      {'x': 'Pneumonia', 'y': 23},
      {'x': 'Pneumothorax', 'y': 747}]},
    {'name': 'Fibrosis',
      'data': [{'x': 'Atelectasis', 'y': 220},
      {'x': 'Cardiomegaly', 'y': 52},
      {'x': 'Consolidation', 'y': 79},
      {'x': 'Edema', 'y': 9},
      {'x': 'Effusion', 'y': 188},
      {'x': 'Emphysema', 'y': 36},
      {'x': 'Fibrosis', 'y': 1686},
      {'x': 'Hernia', 'y': 8},
      {'x': 'Infiltration', 'y': 345},
      {'x': 'Mass', 'y': 117},
      {'x': 'Nodule', 'y': 166},
      {'x': 'Pleural_Thickening', 'y': 176},
      {'x': 'Pneumonia', 'y': 11},
      {'x': 'Pneumothorax', 'y': 80}]},
    {'name': 'Hernia',
      'data': [{'x': 'Atelectasis', 'y': 40},
      {'x': 'Cardiomegaly', 'y': 7},
      {'x': 'Consolidation', 'y': 4},
      {'x': 'Edema', 'y': 3},
      {'x': 'Effusion', 'y': 21},
      {'x': 'Emphysema', 'y': 4},
      {'x': 'Fibrosis', 'y': 8},
      {'x': 'Hernia', 'y': 227},
      {'x': 'Infiltration', 'y': 33},
      {'x': 'Mass', 'y': 25},
      {'x': 'Nodule', 'y': 10},
      {'x': 'Pleural_Thickening', 'y': 8},
      {'x': 'Pneumonia', 'y': 3},
      {'x': 'Pneumothorax', 'y': 9}]},
    {'name': 'Infiltration',
      'data': [{'x': 'Atelectasis', 'y': 3264},
      {'x': 'Cardiomegaly', 'y': 587},
      {'x': 'Consolidation', 'y': 1221},
      {'x': 'Edema', 'y': 981},
      {'x': 'Effusion', 'y': 4000},
      {'x': 'Emphysema', 'y': 449},
      {'x': 'Fibrosis', 'y': 345},
      {'x': 'Hernia', 'y': 33},
      {'x': 'Infiltration', 'y': 19894},
      {'x': 'Mass', 'y': 1159},
      {'x': 'Nodule', 'y': 1546},
      {'x': 'Pleural_Thickening', 'y': 750},
      {'x': 'Pneumonia', 'y': 605},
      {'x': 'Pneumothorax', 'y': 946}]},
    {'name': 'Mass',
      'data': [{'x': 'Atelectasis', 'y': 739},
      {'x': 'Cardiomegaly', 'y': 102},
      {'x': 'Consolidation', 'y': 610},
      {'x': 'Edema', 'y': 129},
      {'x': 'Effusion', 'y': 1254},
      {'x': 'Emphysema', 'y': 215},
      {'x': 'Fibrosis', 'y': 117},
      {'x': 'Hernia', 'y': 25},
      {'x': 'Infiltration', 'y': 1159},
      {'x': 'Mass', 'y': 5782},
      {'x': 'Nodule', 'y': 906},
      {'x': 'Pleural_Thickening', 'y': 452},
      {'x': 'Pneumonia', 'y': 71},
      {'x': 'Pneumothorax', 'y': 431}]},
    {'name': 'Nodule',
      'data': [{'x': 'Atelectasis', 'y': 590},
      {'x': 'Cardiomegaly', 'y': 108},
      {'x': 'Consolidation', 'y': 428},
      {'x': 'Edema', 'y': 131},
      {'x': 'Effusion', 'y': 912},
      {'x': 'Emphysema', 'y': 115},
      {'x': 'Fibrosis', 'y': 166},
      {'x': 'Hernia', 'y': 10},
      {'x': 'Infiltration', 'y': 1546},
      {'x': 'Mass', 'y': 906},
      {'x': 'Nodule', 'y': 6331},
      {'x': 'Pleural_Thickening', 'y': 411},
      {'x': 'Pneumonia', 'y': 70},
      {'x': 'Pneumothorax', 'y': 341}]},
    {'name': 'Pleural_Thickening',
      'data': [{'x': 'Atelectasis', 'y': 496},
      {'x': 'Cardiomegaly', 'y': 111},
      {'x': 'Consolidation', 'y': 251},
      {'x': 'Edema', 'y': 64},
      {'x': 'Effusion', 'y': 849},
      {'x': 'Emphysema', 'y': 151},
      {'x': 'Fibrosis', 'y': 176},
      {'x': 'Hernia', 'y': 8},
      {'x': 'Infiltration', 'y': 750},
      {'x': 'Mass', 'y': 452},
      {'x': 'Nodule', 'y': 411},
      {'x': 'Pleural_Thickening', 'y': 3385},
      {'x': 'Pneumonia', 'y': 48},
      {'x': 'Pneumothorax', 'y': 289}]},
    {'name': 'Pneumonia',
      'data': [{'x': 'Atelectasis', 'y': 262},
      {'x': 'Cardiomegaly', 'y': 41},
      {'x': 'Consolidation', 'y': 123},
      {'x': 'Edema', 'y': 340},
      {'x': 'Effusion', 'y': 269},
      {'x': 'Emphysema', 'y': 23},
      {'x': 'Fibrosis', 'y': 11},
      {'x': 'Hernia', 'y': 3},
      {'x': 'Infiltration', 'y': 605},
      {'x': 'Mass', 'y': 71},
      {'x': 'Nodule', 'y': 70},
      {'x': 'Pleural_Thickening', 'y': 48},
      {'x': 'Pneumonia', 'y': 1431},
      {'x': 'Pneumothorax', 'y': 41}]},
    {'name': 'Pneumothorax',
      'data': [{'x': 'Atelectasis', 'y': 774},
      {'x': 'Cardiomegaly', 'y': 49},
      {'x': 'Consolidation', 'y': 223},
      {'x': 'Edema', 'y': 33},
      {'x': 'Effusion', 'y': 996},
      {'x': 'Emphysema', 'y': 747},
      {'x': 'Fibrosis', 'y': 80},
      {'x': 'Hernia', 'y': 9},
      {'x': 'Infiltration', 'y': 946},
      {'x': 'Mass', 'y': 431},
      {'x': 'Nodule', 'y': 341},
      {'x': 'Pleural_Thickening', 'y': 289},
      {'x': 'Pneumonia', 'y': 41},
      {'x': 'Pneumothorax', 'y': 5302}]}];
  return data;
}

export const importMaxAUC = function(){
  return [0, 2, 1, 2, 2, 2, 1, 2, 1, 2, 2, 2, 2, 2];
}

export const importDataColumnsBarDiseases = function(disease){
  const data = {
    'Atelectasis': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [11559,
      370,
      1223,
      221,
      3275,
      424,
      220,
      40,
      3264,
      739,
      590,
      496,
      262,
      774]},
    'Cardiomegaly': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [370,
      2776,
      169,
      127,
      1063,
      44,
      52,
      7,
      587,
      102,
      108,
      111,
      41,
      49]},
    'Consolidation': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [1223,
      169,
      4667,
      162,
      1287,
      103,
      79,
      4,
      1221,
      610,
      428,
      251,
      123,
      223]},
    'Edema': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [221, 127, 162, 2303, 593, 30, 9, 3, 981, 129, 131, 64, 340, 33]},
    'Effusion': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [3275,
      1063,
      1287,
      593,
      13317,
      359,
      188,
      21,
      4000,
      1254,
      912,
      849,
      269,
      996]},
    'Emphysema': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [424, 44, 103, 30, 359, 2516, 36, 4, 449, 215, 115, 151, 23, 747]},
    'Fibrosis': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [220, 52, 79, 9, 188, 36, 1686, 8, 345, 117, 166, 176, 11, 80]},
    'Hernia': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [40, 7, 4, 3, 21, 4, 8, 227, 33, 25, 10, 8, 3, 9]},
    'Infiltration': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [3264,
      587,
      1221,
      981,
      4000,
      449,
      345,
      33,
      19894,
      1159,
      1546,
      750,
      605,
      946]},
    'Mass': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [739,
      102,
      610,
      129,
      1254,
      215,
      117,
      25,
      1159,
      5782,
      906,
      452,
      71,
      431]},
    'Nodule': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [590,
      108,
      428,
      131,
      912,
      115,
      166,
      10,
      1546,
      906,
      6331,
      411,
      70,
      341]},
    'Pleural_Thickening': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [496,
      111,
      251,
      64,
      849,
      151,
      176,
      8,
      750,
      452,
      411,
      3385,
      48,
      289]},
    'Pneumonia': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [262, 41, 123, 340, 269, 23, 11, 3, 605, 71, 70, 48, 1431, 41]},
    'Pneumothorax': {'labels': ['Atelectasis',
      'Cardiomegaly',
      'Consolidation',
      'Edema',
      'Effusion',
      'Emphysema',
      'Fibrosis',
      'Hernia',
      'Infiltration',
      'Mass',
      'Nodule',
      'Pleural_Thickening',
      'Pneumonia',
      'Pneumothorax'],
    'values': [774, 49, 223, 33, 996, 747, 80, 9, 946, 431, 341, 289, 41, 5302]}}
  return data[disease];
}

export const importDataHeatMapAUC = function(){
  const data = [
    {'name': 'Atelectasis',
    'data': [{'x': 'VGG16', 'y': 66.7},
     {'x': 'ResNet50', 'y': 65.3},
     {'x': 'DenseNet121', 'y': 64.3}]},
   {'name': 'Cardiomegaly',
    'data': [{'x': 'VGG16', 'y': 64.1},
     {'x': 'ResNet50', 'y': 72.0},
     {'x': 'DenseNet121', 'y': 77.6}]},
   {'name': 'Consolidation',
    'data': [{'x': 'VGG16', 'y': 60.5},
     {'x': 'ResNet50', 'y': 66.5},
     {'x': 'DenseNet121', 'y': 64.7}]},
   {'name': 'Edema',
    'data': [{'x': 'VGG16', 'y': 79.3},
     {'x': 'ResNet50', 'y': 79.3},
     {'x': 'DenseNet121', 'y': 80.7}]},
   {'name': 'Effusion',
    'data': [{'x': 'VGG16', 'y': 61.0},
     {'x': 'ResNet50', 'y': 68.7},
     {'x': 'DenseNet121', 'y': 69.1}]},
   {'name': 'Emphysema',
    'data': [{'x': 'VGG16', 'y': 56.3},
     {'x': 'ResNet50', 'y': 63.3},
     {'x': 'DenseNet121', 'y': 66.4}]},
   {'name': 'Fibrosis',
    'data': [{'x': 'VGG16', 'y': 62.5},
     {'x': 'ResNet50', 'y': 64.0},
     {'x': 'DenseNet121', 'y': 63.5}]},
   {'name': 'Hernia',
    'data': [{'x': 'VGG16', 'y': 78.9},
     {'x': 'ResNet50', 'y': 55.3},
     {'x': 'DenseNet121', 'y': 91.0}]},
   {'name': 'Infiltration',
    'data': [{'x': 'VGG16', 'y': 58.0},
     {'x': 'ResNet50', 'y': 62.4},
     {'x': 'DenseNet121', 'y': 59.6}]},
   {'name': 'Mass',
    'data': [{'x': 'VGG16', 'y': 54.3},
     {'x': 'ResNet50', 'y': 60.9},
     {'x': 'DenseNet121', 'y': 64.9}]},
   {'name': 'Nodule',
    'data': [{'x': 'VGG16', 'y': 58.4},
     {'x': 'ResNet50', 'y': 61.7},
     {'x': 'DenseNet121', 'y': 63.3}]},
   {'name': 'Pleural_Thickening',
    'data': [{'x': 'VGG16', 'y': 60.8},
     {'x': 'ResNet50', 'y': 59.6},
     {'x': 'DenseNet121', 'y': 65.3}]},
   {'name': 'Pneumonia',
    'data': [{'x': 'VGG16', 'y': 55.7},
     {'x': 'ResNet50', 'y': 49.5},
     {'x': 'DenseNet121', 'y': 66.5}]},
   {'name': 'Pneumothorax',
    'data': [{'x': 'VGG16', 'y': 59.0},
     {'x': 'ResNet50', 'y': 65.5},
     {'x': 'DenseNet121', 'y': 70.7}]}]
  return data;
}

export const importDataGraph = function(idx){
  
  const d = [{
    'class_weight': ['balanced', 'None'],
    'criterion': ['gini', 'entropy'],
    'max_depth': [3, 6, 9, 12, 15, 18, 21]
  },
  {
    'activation': ['tanh', 'relu'],
    'alpha': [0.0001, 0.05],
    'hidden_layer_sizes': ['(100)', '(50, 50)', '(50, 100)', '(100, 50)', '(100, 100)', '(50, 100, 50)'],
    'learning_rate': ['constant', 'adaptive'],
    'solver': ['sgd', 'adam']}
  ];


  const d1 = Object.keys(d[idx]).map((k)=>{
    return d[idx][k].map((v)=>{
      return String(k)+': '+String(v)
    });
  });
  const nodes = d1.flat().map((n,idx)=>{
    return {id:idx, label: n, color:"#41e0c9"};
  });
  // console.log(nodes.length);
  const edges = Array.from({length: nodes.length-1},(_, i)=>{
    return Array.from({length: nodes.length-i-1},(_, j)=>{
      return {from:i, to:j+i+1};
    })
  }).flat()

  const data = {
    nodes: nodes,
    edges: edges
  };
  return data;
}

