import React from "react";
import { HashLink } from "react-router-hash-link";

export const NavigationMenu = function(){
    return (

        <div className="navigation">
            <input type="checkbox" className="navigation__checkbox" id="navi-toggle"></input>

            <label htmlFor="navi-toggle" className="navigation__button">
                <span className="navigation__icon">&nbsp;</span>
            </label>
            <HashLink to="/#header" className="navigation__link navigation__link--1">
                <div className="navigation__link--text">HOME</div></HashLink>
            <HashLink to="/#section-services" className="navigation__link navigation__link--2">
                <div className="navigation__link--text">SERVICES</div></HashLink>
            <HashLink to="/#section-about" className="navigation__link navigation__link--3">
                <div className="navigation__link--text">ABOUT ME</div></HashLink>
            <HashLink to="/#section-portfolio" className="navigation__link navigation__link--4">
                <div className="navigation__link--text">PORTFOLIO</div></HashLink>
            <HashLink to="/#section-skills" className="navigation__link navigation__link--5">
                <div className="navigation__link--text">SKILLS</div></HashLink>
            <HashLink to="/#footer" className="navigation__link navigation__link--6"><div className="navigation__link--text">CONTACT</div></HashLink>
        </div>        
    )
}