import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

export const BoxAnimation = function(props){
    const boxVariant = {
        visible: { opacity: 1, transition:{duration: 1} },
        hidden: { opacity: 0 },
      }

    const control = useAnimation();
    const [ref, inView] = useInView();

    // const [step, setStep] = useState(props.content.props.id.split('step--')[1]*1);

    useEffect(() => {
        if (inView) {
            control.start("visible");

            [...document.querySelectorAll(`.step--${props.step}`)].map((objectStep)=>{
                objectStep.style.backgroundColor='rgb(106, 145, 186)';
                objectStep.style.color='#E7E3E2';

            });

            [...document.querySelectorAll(`.step_title--${props.step}`)].map((objectStep)=>{
                objectStep.style.color='rgb(106, 145, 186)';
                // console.log(objectStep.style);/
                objectStep.style.fontWeight = 700;

            });

            // document.querySelector('').style
            
            
            
        } else {
            control.start("hidden");
            [...document.querySelectorAll(`.step--${props.step}`)].map((objectStep)=>{
                objectStep.style.backgroundColor='#E7E3E2';
                objectStep.style.color='#2F2E2C';
            });            

            [...document.querySelectorAll(`.step_title--${props.step}`)].map((objectStep)=>{
                objectStep.style.color='#2F2E2C';
                objectStep.style.fontWeight = 400;
            });                   
        }
    }, [control, inView]);

    // console.log();
    // const teste = props.content.querySelector('section');
    // console.log();

    return (
        <motion.div 
            ref={ref}
            animate={control}
            variants={boxVariant}
            initial="hidden"
            className="box"
            data-testid="BoxAnimation-test"
        >
            <section id={`step--${props.step}`}>
                {props.content}
            </section>
        </motion.div>
    )
}