import React from "react";

export const Skills = function(props){

    // Function to render the yellow dots of the skill level
    const render_level = function(numberYellow){
        const levelArr = [];
        for (let index = 0; index < 5; index++) {
            if (index < numberYellow){
                levelArr.push(
                    <span key={index} className="section-skills__level--color"></span>
                )                
            }
            else{
                levelArr.push(
                    <span key={index} className="section-skills__level--transparent"></span>
                )
            }
        }

        return levelArr;
    }

    const renderLevelSkillName = function(skillLevel,skillName){
        return(
            <div key={skillName} className="section-skills__list-ul__li">
                <div className="section-skills__level">
                    {render_level(skillLevel)}
                </div>
                <div className="section-skills__skill">{skillName}</div>
            </div>        
        )
    }

    const renderAllLevelSkillNames = function(allSkillLevels){
        return Object.keys(allSkillLevels).map((skillName)=>renderLevelSkillName(allSkillLevels[skillName],skillName));
    }

    return (        
        <div data-testid="skills-test" className="section-skills__content">           
            <div className="section-skills__list-ul__header">
                {props.skills_header}
            </div>
            {renderAllLevelSkillNames(props.skill_level)}
        </div>
    );
}