import axios from "axios";
import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { SendEmail } from "../../components/SendEmail/SendEmail";

export const Footer = function(){

    const [email, setEmail] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    // const [alertType, setAlertType] = useState('SUCCESSED');
    // const [alertType, setAlertType] = useState('FAILED');
    const [alertType, setAlertType] = useState('');


    const alertMessage = function(){

        const message = () => {
            let text;
            switch (alertType) {
                case 'SUCCESSED':
                    text = (
                        <div>
                            <span className="font-medium">Message sent!</span> I will get back to you as soon as possible!
                        </div>
                    )
                    break;
                    case 'FAILED':
                        text = (
                            <div className=" w-full whitespace-normal break-words " >
                                <span className="font-medium">Failed to send the message!</span> Please try again later or reach out to me through <HashLink to={'https://www.linkedin.com/in/fabioyamada'} >Linkedin</HashLink>.
                            </div>
                        )
                        break;
                    default:
                        text = (
                            <div>
                                <span className="font-medium">Message</span>
                            </div>
                        )
                        break;
            }

            return text
        }

        const alert = (
            <div data-alerttype={alertType} data-alert={showAlert} className=" transition-opacity flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg data-[alerttype=SUCCESSED]:bg-green-50 data-[alerttype=FAILED]:bg-red-100 data-[alert=true]:opacity-100 data-[alert=false]:opacity-0" role="alert">
                {/* <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg> */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5">
                    <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd" />
                </svg>
                <span className="sr-only">Info</span>
                {/* <div>
                    <span className="font-medium">Message sent!</span> I will get back to you as soon as possible!
                </div> */}
                {message()}
            </div>
        )
        return alert
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // setBuffering(true);
        
        
        const nameMessage = e.target.querySelector('.footer__get-touch__input--name').value
        const emailMessage = e.target.querySelector('.footer__get-touch__input--email').value
        const messageMessage = e.target.querySelector('.footer__get-touch__input--message').value
        
        const domain_api = process.env.REACT_APP_DOMAIN_API;

        const config = {
            headers: {
                'Content-Type': 'application/json', // Set the content type
                'Access-Control-Allow-Origin': '*', // Set the allowed origin (replace '*' with your domain)
            },
        };

        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              // Add other headers as needed
            },
            body: JSON.stringify(
                {
                    nameMessage:nameMessage,
                    emailMessage: emailMessage,
                    messageMessage: messageMessage
                }
            ),
          };
          
          fetch(`${domain_api}/message/`, requestOptions)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              // Handle the successful response
            //   setBuffering(false);
              setShowAlert(true);
              setAlertType('SUCCESSED');
              setTimeout(() => {
                setShowAlert(false);
              }, 10000);
            })
            .catch(error => {
            //   setBuffering(false);
              setShowAlert(true);
              setAlertType('FAILED');
              setTimeout(() => {
                setShowAlert(false);
              }, 10000);
              // Handle the error
              console.error(error);
            });
      
      };
    return (
        <footer className="footer" id="footer">
            <div className="footer__content ">
                <div>
                    {/* <div className="footer__name relative"><span className=" -translate-x-12">Fábio</span> <span className=" absolute">Yamada</span></div> */}
                    {/* <div className="footer__name"><span className=" absolute -translate-y-6 translate-x-7">Fábio</span> <span className=" text-gray-300  text-6xl">Yamada</span></div> */}
                    <div className="footer__name grid"><span>Fábio</span> <span>Yamada</span></div>
                    
                    <ul className="footer__ul">
                        <li><HashLink to="/#section-services" className="footer__sections">My Services</HashLink></li>
                        <li><HashLink to="/#section-about" className="footer__sections">About Me</HashLink></li>
                        <li><HashLink to="/#section-portfolio" className="footer__sections">Portfolio</HashLink></li>
                        {/* <li><a href="#section-services" className="footer__sections">My Services</a></li>
                        <li><a href="#section-about" className="footer__sections">About Me</a></li>
                    <li><a href="#section-portfolio" className="footer__sections">Portfolio</a></li> */}
                        <HashLink to="https://www.linkedin.com/in/fabioyamada/">
                            <i className="fa fa-linkedin-square" style={{fontSize:"36px",paddingRight:'.5rem'}}></i>
                        </HashLink>

                        <HashLink to="mailto:fabioheiji@gmail.com">
                            <i className="fa fa-envelope-square fa-3x social" style={{fontSize:"36px",paddingRight:'.5rem'}}></i>
                        </HashLink>
                        <HashLink to="https://github.com/fabioheiji">
                            <i className="fa fa-github" style={{fontSize:"36px",}}></i>
                        </HashLink>
                    </ul>
                    <div className="footer__social-media">
                        <div className="footer__social-media--linkdin">&nbsp;</div>
                        <div className="footer__social-media--github">&nbsp;</div>
                    </div>
                </div>

                <div  className=" ml-auto mr-auto">
                    <div className="section-skills__list-ul__header mb-4">
                        Get in touch
                    </div>
                    <div className="footer__get-touch">
                        <SendEmail/>
                    </div>
                    {alertMessage()}
                </div>  
            </div>
            <div className="text-center ml-auto mr-auto pb-4">                
                <div className=" text-xl font-semibold mb-2">
                    About this page
                </div>
                <div>
                    BUILT FROM SCRATCH BY ME (React JS and Django)
                    © 2023 Fábio Heiji Yamada
                </div>
                <div>
                    <a href="https://www.flaticon.com/free-icons/artificial-intelligence" title="artificial intelligence icons">Artificial intelligence icon on tab created by Freepik - Flaticon</a>
                </div>
            </div>

        </footer>        
    )
}