import React, { useState, useEffect, useCallback } from "react";
import { useSpring, animated, config, to, useSpringRef, useChain,useTransition } from 'react-spring';
import { dataPortfolioSimulationRestaurant } from "../../services/createStaticData/createStaticDataSimulation";

const tablePositions = []
for (let yId = 0; yId < 5; yId++) {
    for (let xId = 0; xId < 4; xId++) {
        tablePositions.push([xId*150, yId*150])
    }            
};
const locationPositions = {
    RECEPTION:[[0,0]],
    TABLE_QUEUE:[[0,200]],
    TABLE: tablePositions,
    EXIT:[[800,600]]
};
const colorsState = {
    'ARRIVED':'rgb(0,0,255)',
    'WAITING':'rgb(255, 255, 0)',
    'GET':'rgb(0, 255, 0)',
    'FREE':'rgb(0, 0, 255)',
    'LEAVING':'rgb(0, 0, 255)',
    'MOVING':'rgb(0, 255, 0)',
    'EXIT':'rgb(255, 0, 0)'
}


export const ReactSpringTest = function(){
    const [data,setData]= useState(Object.values(dataPortfolioSimulationRestaurant))

    const elementFramework = function(positionX, positionY,elementOpacity,simulationDelay,simulationDuration,elementColor){
        return ({
            x: positionX,
            y: positionY,
            opacity: elementOpacity,
            delay: simulationDelay,
            config:{duration:simulationDuration},
            backgroundColor:elementColor,
        });
    }

    const [styles, api] = useSpring(() => ({        
        // from: elementFramework(-50,0,0,0,0,'yellow')
        from: { x: 0, y:0, opacity: 0 , backgroundColor:'yellow'},
      }))
    
      useEffect(() => {
    
      }, []);

      const playSimulation = function(){
        const dataEntity = data.filter((d)=>d["Element id"]=='6013066960');
        console.log(dataEntity);
        let x,y;
        dataEntity.map((thread, index)=>{
            [x,y]=locationPositions[thread['Location Name']][thread['Location Id']]
            // console.log(locationPositions[thread['Location Name']][thread['Location Id']]);
            // console.log(index);
            console.log(thread['Status'],thread['Time Stamp'],thread['Duration']);
            api.start(elementFramework(x,y,1,thread['Time Stamp']*10,thread['Duration']*10,colorsState[thread['Status']]));
            
        })

      };

      const pauseSimulation = function(){
        api.pause();
      };
      const resumeSimulation = function(){
        api.resume();
      };
    
      return (       
        <React.Fragment>
            <animated.button onClick={playSimulation}>Play</animated.button> 
            <animated.button onClick={pauseSimulation}>Pause</animated.button> 
            <animated.button onClick={resumeSimulation}>Resume</animated.button> 
            <animated.div
              style={{
                width: 80,
                height: 80,
                backgroundColor: '#46e891',
                borderRadius: 16,
                ...styles,
              }}
            />
        </React.Fragment>
      )

    
}