import React from "react";

export const Card = function(props){
    return (
        <div data-testid="card-test" className="card">
            <div className="card__back">
                <div className="section-services__title-back">
                    {props.title_back}
                </div>
                <div className="section-services__description">
                    {props.description}
                </div>
            </div>
            <div className={"card__front section-services--"+(props.title_back).replaceAll(' ','')}>
                <div className="section-services__cover">
                    <div className="section-services__cover--text">
                    {props.cover_text}
                    </div>
                </div>
            </div>
        </div>
    );
}