import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export const HeatMap = function(props){
    const [series, setSeries] = useState(
        [
            {
                name: "vgood",
                data: [
                    {x: 'acc', y: 0},
                    {x: 'good',y: 0},
                    {x: 'unacc',y: 0},
                    {x: 'vgood',y: 13}
                ]
            },            
            {
                name: "unacc",
                data: [
                    {x: 'acc', y: 1},
                    {x: 'good',y: 0},
                    {x: 'unacc',y: 259},
                    {x: 'vgood',y: 0}
                ]
            },
            {
                name: "good",
                data: [
                    {x: 'acc', y: 1},
                    {x: 'good',y: 13},
                    {x: 'unacc',y: 0},
                    {x: 'vgood',y: 0}
                ]
            },                             
            {
                name: "acc",
                data: [
                    {x: 'acc', y: 59},
                    {x: 'good',y: 0},
                    {x: 'unacc',y: 0},
                    {x: 'vgood',y: 0}
                ]
            },       
        ]
    );
    const [option, setOption] = useState(
        {
            plotOptions: {
                heatmap: {
                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 5,
                        color: '#859bb1',
                        name: 'low',
                    },
                    {
                        from: 6,
                        to: 150,
                        color: '#4f76a0',
                        name: 'medium',
                    },
                    {
                        from: 151,
                        to: 1000,
                        color: '#092a4d',
                        name: 'high',
                    }
                    ]
                }
                }
            }
        }
    );

    const renderBoxPlot = function(option){
        
        return (
            <Chart
                options={option}
                series={series}
                width="100%"
                height='400'
                type='heatmap'
            />
        )
    }
    return (
        <div style={{minWidth:'70%', justifySelf:'center'}} >
            {renderBoxPlot(option)}
        </div>
    );
}