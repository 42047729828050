import React, { useEffect, useState } from "react";


export const ServiceBox = function(props){
    return (
        <div className="section-services__box">
            <div className="section-services__image--container">
                <img className="section-services__image"  src={props.image} alt="" />
            </div>
            <div className="section-services__text">
                <div className="section-services__subtitle">
                    {props.title_back}
                </div>
                <div className="section-services__description">
                    {props.description}
                </div>
            </div>
        </div>
    )
}