import React from "react";

import { useEffect, useState } from "react";


export const SideBarButton = function(){

    const [widthSide, setWidthSide] = useState()

    const openSideBar = function(e){
        // const widthSideMenu = document.querySelector('.portfolio__content').style.left;
        const widthSideMenu = document.querySelector('.portfolio__sidebar').style.left;

        // Change the button graphic
        e.target.closest('.btn__hamburger__grid').classList.toggle("change");

        document.querySelector('.portfolio__sidebar').classList.toggle("portfolio__sidebar--open");
        document.querySelector('.portfolio__sidebar').classList.toggle("portfolio__sidebar--close");
        
        
        if (![...e.target.closest('.btn__hamburger__grid').classList].includes("change")){
            document.querySelector('.portfolio__sidebar').style.left = "-25rem";
            document.querySelector('.portfolio__sidebar--titles').style.left = "-25rem";
        }
        else{
            document.querySelector('.portfolio__sidebar').style.left = "0";
            document.querySelector('.portfolio__sidebar--titles').style.left = "0";
        }

        setWidthSide(document.querySelector('.portfolio__sidebar').style.left);
    }    
    return(
        <div data-testid="SideBarButton-test" className="btn__hamburger">
            <div className="btn__hamburger__flex">
                <div className="btn__hamburger__grid" onClick={openSideBar}>
                    <div className="btn__hamburger__grid--bar1"></div>
                    <div className="btn__hamburger__grid--bar2"></div>
                    <div className="btn__hamburger__grid--bar3"></div>
                </div>
            </div>
        </div>    
    );
}