import React, {useState} from "react";
import Chart from "react-apexcharts";

export const HeatMapFlexAUC = function(props){
    const [series, setSeries] = useState(
        props.data
    );
    const [option, setOption] = useState(
        {
            colors: ['#19457e']
        }
    );

    const renderHeatMap = function(option){
        
        return (
            <Chart
                options={option}
                series={series}
                width="70%"
                height='400'
                type='heatmap'
            />
        )
    }
    return (
        <React.Fragment>
        <h1 
        className='titleGraph'
        style={{
            height:"10%",
            textAlign:"initial"
        }}>
            {props?.title}
        </h1>
        <div className="graphicBarSize" >
            {renderHeatMap(option)}
        </div>
        </React.Fragment>
    );


}