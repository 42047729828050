import React from "react";
import { SideBarButton } from "./SideBarButton";
import { HashLink } from "react-router-hash-link";

export const SideBarContent = function(props){
    const romanNumbers = {
        1:"I",
        2:"II",
        3:"III",
        4:"IV",
        5:"V",
        6:"VI",
        7:"VII",
        8:"VIII",
        9:"IX",
        10:"X",
    }
    const renderTitles = function(){
        const titles = props.titles.map((t, index)=><div className={`portfolio__sidebar--titles--item step_title--${index}`} key={index} onClick={moveView}>{t}</div>)
        
        return titles;
    }

    const moveView = function(e){
        const index = parseInt(e.target.className.split(' ')[1].slice(-1));
        document.getElementById(`step--${index}`).scrollIntoView({behavior: 'smooth'});
    
        // if the sidebar is open, then after click on the shortcut number, the sidebar colapse
        if ([...document.querySelector(".btn__hamburger__grid").classList].includes("change")){
            document.querySelector(".btn__hamburger__grid").click();
        }

    }

    const renderSteps = function(){
        return props.titles.map((_,index)=>{
            return (
                <div key={index} className="portfolio__sidebar--timeline--item">
                    {/* <a className={`portfolio__sidebar--timeline--item--step step--${index}`} href={`#step--${index}`}>{romanNumbers[index+1]}</a> */}
                    <div className={`portfolio__sidebar--timeline--item--step step--${index}`} onClick={moveView} >{romanNumbers[index+1]}</div>
                    <div className={`portfolio__sidebar--timeline--item--bar`}>&nbsp;</div>
                </div>
            )
        });
    }
    return (
        <div className="portfolio__sidebar portfolio__sidebar--close">
            <div className="portfolio__sidebar--header">
                <HashLink to="/#header" style={{textDecoration:'none'}}><div className="portfolio__sidebar--titles--item">HOME</div></HashLink>
                <SideBarButton/>
            </div>
            <div className="portfolio__sidebar--items">
                <div className='portfolio__sidebar--titles'>
                    {renderTitles()}
                </div>
                <div className="portfolio__sidebar--timeline">
                    {renderSteps()}            
                </div>
            </div>            
        </div>
    );
}