import React, { useEffect, useRef, useState } from "react";
import { GraphChart } from "../../components/Graphics/GraphChart/GraphChart";
import { importDataGraph } from "../../services/createStaticData/createStaticData";
import { SideBarContent } from "../../components/SideBar/SideBarContent";
import { BoxAnimation } from "../../components/BoxAnimation/BoxAnimation";
import { TextBlock } from "../../components/TextBlock/TextBlock";
import { Link } from "react-router-dom";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools"

import { HashLink } from "react-router-hash-link";
import { Reference } from "../../components/Reference/Reference";

export const PortfolioLlama2 = function(props){
    return (
        <div className="portfolio">
            <div className="z-10 relative">
                <SideBarContent
                    titles = {[
                        'Introduction',
                        'Step 1: Install the required libraries',
                        'Step 2: Load the Llama 2 model',
                        'Step 3: Function to extract text from PDF files',
                        'Step 4: Generate key takeaways from text',
                    ]}
                />
                <div className={'portfolio__content--empty lg:p-20 md:p-10 sm:p-5'} style={{width:'85vw'}}>
                    
                    <BoxAnimation content={
                        <section>
                        <TextBlock title={true} text={'How to use LLama 2 to summarize PDF: using to extract the key takeaways of academic articles'} />
                        <TextBlock text={
                            <Reference name='Fábio Heiji Yamada' date='August 20, 2023'/>
                        }/>

                        <TextBlock subtitle={true} text={`Introduction`}/>
                        <TextBlock text={
                            <div>
                                <div>
                                    How about a  <strong>free software that can read your articles and extract the information you want</strong>? Sounds awesome, right? Well, that's what this <strong>tutorial</strong> is all about. You will learn how to use <strong>Llama 2 with Langchain </strong> to achieve this amazing feat. 
                                </div>
                                <div>
                                    Llama 2 is a family of state-of-the-art open-access large language models released by Meta, and it can do wonders for various natural language processing tasks, such as text generation, summarization, dialogue, and question answering. In this blog post, I will show you how to use Llama 2 with Python to build a simple application that can extract the key takeaways from any PDF academic article. Let’s get started! 🚀
                                </div>
                            </div>
                        } />
                      </section>
                    } step={0}/>
                    
                    <BoxAnimation content={
                        <section>
                            <TextBlock subtitle={true} text={`Step 1:  Install the required libraries`}/>
                            <TextBlock text={
                                <div>
                                    <div>
                                        To use Llama 2 in Python, we need to install some libraries that will help us interact with the model and process the PDF files. The main libraries we will use are:
                                    </div>
                                    <ul className="list-disc">
                                        <li>
                                            <strong>langchain</strong>: This is a library that provides a unified API for various natural language processing tasks, such as text generation, summarization, translation, and embedding. It integrates with several state-of-the-art models and frameworks, such as Llama 2, Jina, Hugging Face, and Chroma. It also supports both Python and JavaScript languages.
                                        </li>
                                        <li>
                                            <strong>pypdf</strong>: This is a library that can read and manipulate PDF files in Python. It can extract text, images, metadata, annotations, and bookmarks from PDF documents. It can also merge, split, rotate, crop, encrypt, decrypt, and watermark PDF files.
                                        </li>
                                        <li>
                                            <strong>llama-cpp-python</strong>: This is a library that provides a Python binding for llama.cpp, a C++ library that implements several large language models (LLMs), such as Llama 2, GPT-3, and BERT. It supports multiple backends for faster processing, such as OpenBLAS, cuBLAS, CLBlast, and Metal. It also integrates with langchain for easy access to LLMs.
                                        </li>
                                        <li>
                                            <strong>sentence_transformers</strong>: This is a library that provides an easy way to use pretrained transformer models for generating sentence and text embeddings. It can compute embeddings for sentences, paragraphs, or documents in various languages. It can also perform semantic similarity search, clustering, classification, and other downstream tasks. 
                                        </li>
                                        <li>
                                            <strong>chromadb</strong>: This is a library that provides an AI-native open-source embedding database. It can store and query high-dimensional vectors efficiently and scalably. It supports various features such as filtering, density estimation, nearest neighbor search, and more. It also integrates with langchain and LlamaIndex for easy embedding generation and retrieval. 
                                        </li>

                                        {/* <li>
                                            <strong>Scholarcy</strong>: This is a library that can generate flashcards and summaries from academic articles. We will use it to extract the key facts, figures, and references from the PDF articles.                                        
                                        </li> */}
                                    </ul>
                                    
                                    <div className=" mb-4 ">
                                        To install these libraries, we can use the pip command in our terminal or command prompt:
                                    </div>
                                    <AceEditor
                                        readOnly={true}
                                        width="100%"
                                        height="3rem"
                                        mode="python"
                                        theme="monokai"
                                        value={`pip install langchain pypdf llama-cpp-python sentence_transformers chromadb`}
                                        name="1"
                                        // highlightActiveLine={true}
                                        fontSize="16px"
                                        editorProps={{ $blockScrolling: true }}
                                        setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true
                                        }}
                                    />

                                    <div className=" mt-12 mb-4">
                                    Then, make the following imports at the beginning of the code (don’t worry, each import will be explained later):
                                    </div>

                                    <AceEditor
                                        readOnly={true}
                                        width="100%"
                                        height="33rem"
                                        mode="python"
                                        theme="monokai"
                                        value={`from langchain.document_loaders import PyPDFLoader #This class can load text from PDF files
from langchain.embeddings import HuggingFaceEmbeddings #This classe can generate embeddings for text using Hugging Face models
from langchain.vectorstores import Chroma #This class can store and query high-dimensional vectors efficiently and scalably
from langchain.chains import ConversationalRetrievalChain #This class can perform conversational retrieval using Llama 2 and a vector store
from langchain.memory import ConversationSummaryBufferMemory #These classes can store and retrieve information from previous conversations
from langchain.llms import LlamaCpp #This class can interact with Llama 2 models using C++ bindings
from langchain.callbacks.manager import CallbackManager #This class can manage callbacks for various events during the execution of the code
from langchain.callbacks.streaming_stdout import StreamingStdOutCallbackHandler #This class can print messages to the standard output stream during the execution of the code
from langchain.text_splitter import RecursiveCharacterTextSplitter #This class can split text into smaller chunks based on different criteria
from langchain.chains.conversational_retrieval.prompts import CONDENSE_QUESTION_PROMPT #This constant is a template for generating prompts that condense a question into a shorter form
from langchain.chains.question_answering import load_qa_chain #This function can load a pre-defined chain for question answering using Llama 2 models

import os
import pickle #Import the pickle module to serialize and deserialize Python objects`}
                                        name="1"
                                        // highlightActiveLine={true}
                                        fontSize="16px"
                                        editorProps={{ $blockScrolling: true }}
                                        setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true
                                        }}
                                    />
                                </div>                                                        
                            } />
                      </section>
                    } step={1}/>

                    <BoxAnimation content={
                        <section className=" mt-16 ">
                            <TextBlock subtitle={true} text={`Step 2: Load the Llama 2 model`}/>
                            <TextBlock text={
                                <div>
                                    <div>
                                    First, we need to download the appropriate model. There are other sources available, but I used this repository from Hugging Face: <Link to={'https://huggingface.co/TheBloke/Llama-2-7B-Chat-GGML/tree/main'}>link to the repo</Link>.
                                    </div>
                                    <div>
                                        In this case, we'll use the <strong>7 billion parameters</strong> model <strong>llama-2-7b-chat.ggmlv3.q4_K_M.bin</strong> (<Link to={'https://huggingface.co/TheBloke/Llama-2-7B-Chat-GGML/resolve/main/llama-2-7b-chat.ggmlv3.q4_K_M.bin'}>download the file from this link</Link>), which is a fine-tuned version of Llama 2 for dialogue applications. I <strong>highly</strong> recommend playing with other models available in the repository - just check if your computer will have power enough to run them.
                                        Then, store the download file (<span className="italic font-bold">.bin extension</span>) into your project root and create the instance of LlamaCpp using the code bellow.
                                    </div>

                                    <AceEditor
                                        readOnly={true}
                                        width="100%"
                                        height="36rem"
                                        mode="python"
                                        theme="monokai"
                                        value={`n_gpu_layers = 1  # # Set the number of GPU layers to 1 (this is a parameter for llama.cpp)
n_batch = 512  # Should be between 1 and n_ctx, consider the amount of RAM of your machine.
callback_manager = CallbackManager([StreamingStdOutCallbackHandler()]) # Create an instance of CallbackManager with a list of callback handlers as an argument (in this case, only one handler that prints messages to stdout)

# Create an instance of LlamaCpp
llm = LlamaCpp(  
    model_path="./llama-2-7b-chat.ggmlv3.q4_K_M.bin", # The path of the Llama 2 model file
    n_gpu_layers=n_gpu_layers,
    n_batch=n_batch,
    f16_kv=True,  # A flag to indicate whether to use 16-bit floating point numbers for key and value matrices (this can improve performance and memory usage). MUST set to True, otherwise you will run into problem after a couple of calls.
    callback_manager=callback_manager,
    verbose=True, # A flag to indicate whether to print verbose messages
    max_tokens=2000, # The maximum number of tokens that the model can generate
    n_ctx=2000, # The maximum number of tokens that the model can process as context
    temperature=0 # The temperature parameter that controls how creative or diverse the model's output is (a lower temperature means more coherence and consistency)`}
                                        name="1"
                                        // highlightActiveLine={true}
                                        fontSize="16px"
                                        editorProps={{ $blockScrolling: true }}
                                        setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true
                                        }}
                                    />
                                </div>
                            }/>
                        </section>
                    } step={2}/>

                    <BoxAnimation content={
                        <section className=" mt-16 ">
                            <TextBlock subtitle={true} text={`Step 3: Define a function to extract text from PDF files`}/>
                            <TextBlock text={
                                <div>
                                    <div>
                                    To extract text from PDF files, we will use <strong>PyPDFLoader</strong> from <strong>langchain</strong>. It takes a PDF file path as an input and returns a string of text as an output. The for-loop will iterate over all the pages of the PDF file and concatenate their text content to a variable called document.
                                    </div>
                                    <AceEditor
                                        readOnly={true}
                                        width="100%"
                                        height="25rem"
                                        mode="python"
                                        theme="monokai"
                                        value={`path ='./name_of_folder_with_PDFs'
document = [] # empty list for storing the text of the PDF articles for file_name in

# Loop through all the files in the path directory
for file_name in os.listdir(path):
    # Check if the file name ends with ‘pdf’
    if(file_name.endswith('pdf')):    
        pdf_path = os.path.join(path,file_name) #get the full path of the PDF file
        loader = PyPDFLoader(pdf_path) #Create an instance of PyPDFLoader with the PDF file path as an argument
        document.extend(loader.load()) # Load the text from the PDF file and append it to the document list`}
                                        name="1"
                                        // highlightActiveLine={true}
                                        fontSize="16px"
                                        editorProps={{ $blockScrolling: true }}
                                        setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true
                                        }}
                                    />
                                    <div className=" mt-16">
                                        Them, we need to split this document into smaller chunks, generate embeddings for each chunk using a Hugging Face model, and store the embeddings in a Chroma database.
                                    </div>
                                    <AceEditor
                                        readOnly={true}
                                        width="100%"
                                        height="30rem"
                                        mode="python"
                                        theme="monokai"
                                        value={`# Create an instance of RecursiveCharacterTextSplitter
document_splitter = RecursiveCharacterTextSplitter(
\tchunk_size = 500, # Set the maximum number of characters for each chunk. This parameter can be adjusted according to your context.
\tchunk_overlap  = 100, # The number of characters that each chunk can overlap with the previous and next chunks
\tlength_function = len, # The function that calculates the length of a chunk (in this case, the built-in len function)
\tis_separator_regex = False, # A flag to indicate whether the chunk separator is a regular expression or not (in this case, it is not) 
)
          
document_chunks=document_splitter.split_documents(document) # Split the document list into smaller chunks using the document splitter instance
embeddings = HuggingFaceEmbeddings(model_name='sentence-transformers/all-MiniLM-L6-v2',cache_folder='./emb_model') # Create an instance of HuggingFaceEmbeddings with two arguments: the name of the Hugging Face model and the cache folder for storing the model files
vectordb=Chroma.from_documents(document_chunks,embedding=embeddings, persist_directory='./data') # Create an instance of Chroma from the document chunks using the embeddings instance and a persist directory for storing the vector data
vectordb.persist() # Persist the vector data to the disk`}
                                        name="1"
                                        // highlightActiveLine={true}
                                        fontSize="16px"
                                        editorProps={{ $blockScrolling: true }}
                                        setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true
                                        }}
                                    />

                                </div>
                            }/>
                        </section>
                    } step={3}/>

                    <BoxAnimation content={
                        <section className=" mt-16 ">
                            <TextBlock subtitle={true} text={`Step 4: Define a function to generate key takeaways from text using Llama 2`}/>
                            <TextBlock text={
                                <div>
                                    <div>
                                    To generate the <strong>key takeaways</strong> from text using Llama 2, create a list of results and answers for each PDF file in a given path, using Llama 2 and langchain. The code below uses several classes and methods from the langchain library, which provides a unified API for various natural language processing tasks.
                                    </div>
                                    <div>
                                        It extracts the main points from PDF articles. It splits the text into chunks, creates embeddings for them, and saves them in a database. Then it asks Llama 2 to answer questions about each article and stores the answers in a list. It also handles any errors that might occur. The resulting text is then saved in the file answers.txt.               
                                    </div>
                                    
                                    <AceEditor
                                        readOnly={true}
                                        width="100%"
                                        height="128rem"
                                        mode="python"
                                        theme="monokai"
                                        value={`result = [] # Initialize an empty list for storing the results
answer = [] # Initialize an empty list for storing just the final answers
total = len(os.listdir(path)) # Get the total number of files in the path directory 

# Loop through all the files in the path directory 
for idx,file_name in enumerate(os.listdir(path)):
    # Create an instance of ConversationSummaryBufferMemory
    memory = ConversationSummaryBufferMemory(
        llm=llm,
        output_key='answer', # The key that stores the answer from the Llama 2
        memory_key='chat_history', # The key that stores the chat history from previous conversations
        return_messages=True # A flag to indicate whether to return messages from the memory or not
    )
    
    # Create a retriever instance from the vectordb instance   
    retriever = vectordb.as_retriever(
        search_type="similarity", # The type of search to perform (in this case, similarity search based on cosine distance)
        search_kwargs={"k": 4, "include_metadata": True} # A dictionary of keyword arguments for the search method (in this case, k is the number of results to return and include_metadata is a flag to indicate whether to include metadata from the source documents or not)
    ) 

    # Create an instance of ConversationalRetrievalChain from the LlamaCpp
    pdf_qa = ConversationalRetrievalChain.from_llm(
        memory=memory,
        llm=llm,
        chain_type="stuff", # The type of chain to create (in this case, a dummy value that is not used)
        retriever=retriever,
        return_source_documents=True, # A flag to indicate whether to return the source documents from the retriever or not
        get_chat_history=lambda h : h, # A function that returns the chat history from the memory (in this case, an identity function that returns its argument)
        verbose=False # A flag to indicate whether to print verbose messages or not
    )

    try:
        print(f'text id: {idx}/{total}')
        print(f'file name: {file_name}')

        # Format a question query with the file name as an argument
        question_query = "What are the main takeaways from {}?".format(file_name)

        # Format a query with the question query as an argument
        query = "{} Give Please ONLY return a short interpretation, and nothing more. Don't explain the meaning of each metric, just give the interpretation about the value. The model was trained on historical data and used to predict future values. Based on these metrics, provide insights into the model's overall performance. Additionally, highlight any potential areas of improvement or concerns that might be addressed in future iterations of the model. Your interpretation should be comprehensive and supported by the data from the forecast error metrics. Please include any relevant statistical analysis to enhance the understanding of the results.".format(question_query)
        result_ith = pdf_qa({"question": query}) # Call the pdf_qa instance with a dictionary that contains the question as an argument and get a result
        result.append(result_ith) # Append the result to the result list
        answer.append({file_name:result_ith['answer']}) # Append a dictionary that contains the file name and the answer to the answer list
    except Exception as error:
        # Handle the exception by appending a dictionary that contains the file name and an empty string to the answer list
        answer.append({file_name:''})
        print("An exception occurred:", error)

# Save the answers into the answers.txt file
with open('answers.txt','w+') as file:
    # Loop through all the results in the result list with their indices
    for idx,a in enumerate(result):
        file.write(str(idx)+'\\n')
        file.write('Article title: ')
        file.write(a['source_documents'][0].metadata['source']+'\\n')
        file.write(a['answer']+'\\n')
        file.write('\\n')`}
                                        name="1"
                                        // highlightActiveLine={true}
                                        fontSize="16px"
                                        editorProps={{ $blockScrolling: true }}
                                        setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: true
                                        }}
                                    />

                                </div>
                            }/>
                        </section>
                    } step={4}/>
                </div>
            </div>
        </div>
    )
}