import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export const ColumnChart = function(props){

    let dataSeries = props.labels.map((label,idx)=>{
        return {x:label, y: props.values[idx]}
    })

    let xyAxisAnnotations = props.labels.map((label,idx)=>{
        return {
            x: label,
            y: props.values[idx],
            label: {text:props.values[idx], borderColor:'transparent', style:{ background:'transparent', fontWeight:700}},
            marker:{fillColor: "transparent",strokeColor: "transparent",}
        }
    })
    // const [xyAxisAnnotations, setXyAxisAnnotations] = useState(
    //     props.labels.map((label,idx)=>{
    //         return {
    //             x: label,
    //             y: props.values[idx],
    //             label: {text:props.values[idx], borderColor:'transparent', style:{ background:'transparent', fontWeight:700}},
    //             marker:{fillColor: "transparent",strokeColor: "transparent",}
    //         }
    //     })
    // )

    const [series, setSeries] = useState(
        [
            {                  
                data: dataSeries,                
            },
        ]
    )
    const [option, setOption] = useState({
        chart:{
            type: 'bar',
            height: 700,
            // width: 1001
        },
        title: {
            text: `${props.title}`,
            align: 'Center'
        },
        plotOptions: {
            bar: {         
            //   columnWidth: '50rem'
            },
        },
        stroke: {
            colors: ['#2F2E2C']
        },
        annotations: {
            points: xyAxisAnnotations
        },
        dataLabels:{enabled: false,}
    });

    const [chartResponse, setChartResponse] = useState({
        'width':window.innerWidth > 600 ? 600 : window.innerWidth,
        'fontSize':'10px'
    });

    useEffect(()=>{
        dataSeries = props.labels.map((label,idx)=>{
            return {x:label, y: props.values[idx]}
        });

        setSeries(
            [
                {                  
                    data: dataSeries,                
                },
            ]            
        );

        xyAxisAnnotations = props.labels.map((label,idx)=>{
            return {
                x: label,
                y: props.values[idx],
                label: {text:props.values[idx], borderColor:'transparent', style:{ background:'transparent', fontWeight:700}},
                marker:{fillColor: "transparent",strokeColor: "transparent",}
            }
        })

        setOption(
            {
                chart:{
                    type: 'bar',
                    height: 700,
                    // width: 1001
                },
                title: {
                    text: `${props.title}`,
                    align: 'initial;'
                },
                plotOptions: {
                    bar: {         
                    //   columnWidth: '50rem'
                    },
                },
                stroke: {
                    colors: ['#2F2E2C']
                },
                annotations: {
                    points: xyAxisAnnotations
                },
                dataLabels:{enabled: false,}
            }            
        )

        
        
    },[props.values]);
    
    useEffect(()=>{
        const handleResize = () => {
            let width;
            if (window.innerWidth > 750) {
                width = 600;
            }
            else{
                width = 600 - (750-window.innerWidth);
            }
            setChartResponse(
                {
                    'width':width,
                    'fontSize':'10px'
                }
            );
        };
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }; 
    },[window.innerWidth]);

    const renderColumnChart = function(option){
        // console.log(option)
        if (option['chart']){
            return (
                <Chart
                    options={option}
                    series={series}
                    width="600"
                    height='400'
                    type='bar'
                />
            )
        }
        return (
            <div>Failed!</div>
        )
    }
    
    return (
        // <div className="overflow-auto" style={{display:"block", position:'relative',height:400, width: `${chartResponse['width']}px`}}>
        <div className="overflow-auto" id="test">
            {renderColumnChart(option)}
        </div>
    );
}

