import { Link } from "react-router-dom"

export const  RenderImagePortfolio = function(props){
    const renderTextImg = function(e) {
        e.target.closest('.img_portfolio').children[1].classList.toggle('opacity-0');
        e.target.closest('.img_portfolio').children[0].classList.toggle('scale-125');
    }
    const hideTextImg = function(e) {
        e.target.closest('.img_portfolio').children[1].classList.toggle('opacity-0');
        e.target.closest('.img_portfolio').children[0].classList.toggle('scale-125');
    }   
    return (
        <Link className="img_portfolio section-portfolio__children relative" to={`/${props.endPoint}`}>
            <img className="section-portfolio__image" id={props.endPoint} srcSet={props.portfolioImage} alt={props.endPoint} ></img>
            <div className=" p-4 flex items-center content-center w-full h-full text-4xl absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity ease-in duration-150 opacity-0 bg-white bg-opacity-75" onMouseEnter={renderTextImg} onMouseLeave={hideTextImg} style={{color:'#2F2E2C'}}>
                {props.textHover}
            </div>
        </Link>
    )
}
