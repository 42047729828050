import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export const BoxPlot = function(props){
    const [series, setSeries] = useState(
        [
            {                  
                data: [
                    {
                        x: 'DT',            
                        y: ['whislo','q1', 'med', 'q3','whishi'].map((i)=>props.data['DT'][i].toFixed(3))
                    },
                    {
                    x: 'MLP',              
                    y: ['whislo','q1', 'med', 'q3','whishi'].map((i)=>props.data['MLP'][i].toFixed(3))
                    },
                ]
            }
        ],
    )
    const [option, setOption] = useState(
        {
            chart:{
                type: 'boxPlot',
                height: 500,
                // width: 1001
                toolbar: {
                    show: true, // Display the top menu
                    offsetX: 0, // Adjust the horizontal positioning
                    offsetY: -30, // Adjust the vertical positioning                    
                }
            },
            title: {
                text: `${props.title} Comparison`,
                align: 'left'
            },
            plotOptions: {
                bar: {         
                //   columnWidth: '50rem'
                },
                boxPlot: {
                  colors: {
                    upper: 'rgb(106, 145, 186)',
                    lower: '#F7F2EF'
                    // lower: '#E7E3E2'
                  }
                }
            },
            stroke: {
                colors: ['#2F2E2C']
            }
        }
        );

    useEffect(()=>{

    },[]);

    const renderBoxPlot = function(option){
        // console.log(option)
        if (option['chart']){
            return (
                <Chart
                    options={option}
                    series={series}
                    // width="70%"
                    height='400'
                    type='boxPlot'
                />
            )
        }
        return (
            <div>Failed!</div>
        )
    }
    
    return (
        // <div style={{minWidth:'30rem'}} >
        <div >
            {renderBoxPlot(option)}
        </div>
    );
}

